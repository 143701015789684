<template>
    <li class="listitem">
        <div class="center">
            <p>{{ where }}</p>
            <p v-if="what !== ''" class="subtitle">{{ modifyType(type,what) }}</p>
            <p v-if="datetime !== ''" class="meta">{{ datetime }}</p>
        </div>
        <div v-bind:class="(isPositive(amount))?'topUp right':'right'">
			<p>{{ text_amount }}<i :class="icon" class="pl-10 h-color"></i></p>
		</div>
    </li>
</template>

<script>
export default {
    name: "cHistoryItem",
    data: function () {
		return {
			text_amount: ""
      };
    },
	//props: ['title','icon', 'right', 'subtitle', 'meta'],
	props: ['icon','what', 'where', 'type', 'amount','datetime'],
	methods: {
		modifyType: function(value,what){
            /* if(what == 'APP' && value == 'balance'){
		  		value = this.T('Optankning');
		    }
            else{
              value= what;
		    }*/
            return what;
		},
		isPositive: function(value){
			if(value > 0){
				return true;
			}else{
				return false;
			}
		},
	},
	mounted: function () {
		//var ia=parseInt(this.amount);
		var ia= this.amount;
		if(this.type == 125){
			this.text_amount = "Stk. " + ia;
		}else{
			this.text_amount = "Kr. " + ia;
		}
	},
}
</script>

<style>

</style>