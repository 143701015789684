<template>
    <pBase ref="base" v-bind:state="state" v-bind:modal="modal">
        <cFrame v-bind:title="T('Connect bracelet')" class="pullup">
            <cPg class="tc pb-15">{{ T('Indtast oplysningerne, som står bag på dit armbånd.') }}</cPg>
            <cBracelet v-on:click="logSerial" class="add"></cBracelet>
            <!--<cBracelet v-on:click="logSerial" v-bind:serial="serial" v-bind:control="control" class="add"></cBracelet>-->

            <cError v-if="error_msg" :class="'error-text'">{{error_msg}}</cError>

            <cInput v-bind:label="T('Serienummer')" maxlength="20" itype="text" v-bind:placeholder="T('Serienummer')" v-model="serial" v-bind:help="T('Serial help')" v-bind:iconhelp="T('Serial help icon')"></cInput>
            <cInput v-bind:label="T('Kontrolkode')" maxlength="20" itype="text" v-bind:placeholder="T('Kontrolkode')" v-model="control" v-bind:help="T('Control help')" v-bind:iconhelp="T('Control help icon')"></cInput>
            <cInput v-bind:label="T('Navn')" maxlength="20" itype="text" v-bind:placeholder="T('Navn - fx. Alexanders armbånd')" v-model="name" v-bind:help="T('Valgfrit navn til dit armbånd')" v-bind:iconhelp="T('Name help icon')"></cInput>
            <cActions>
                <cButton v-bind:title="T('Tilknyt')" class="primary" v-bind:class="{'disabled': formCheck()}" v-on:click="appendBracelet()"></cButton>
                <cButton v-bind:title="T('Fortryd')" v-on:click="gotoPage(10)"></cButton>
            </cActions>
        </cFrame>
    </pBase>
</template>

<script>
import { useMainStore } from '../stores/MainStore';

import cFrame from "../components/Frame.vue"
import cPg from "../components/Pg.vue"
import cError from "../components/Error.vue"
import cBracelet from "../components/Bracelet.vue"
import cActions from "../components/Actions.vue"
import cButton from "../components/Button.vue"
import cInput from "../components/Input.vue"
import pBase from "../pages/Base.vue"
import settings from "../ts/settings"

export default {
    name: "pAppendBracelet",
    components: {
        cFrame,
        cPg,
        cBracelet,
        cError,
        cInput,
        cActions,
        cButton,
        pBase
    },
    setup() {
        const main = useMainStore();

        return { main };
    },
    data: function () {
        return {
            serial: '',
            control: '',
            name: '',
            show_actions:false,
            confirm_dirty: true, // enable checking for changed / dirty input fields
            state: "normal",
            modal: "pNone",
            error_msg: "",
        }
    },
    methods: {
        appendBracelet: function () {

            this.$refs.base.set_state("modal","mLoading");

            var alias = this.name;
            if (alias == "") {
                alias = this.T("Mit armbånd");
            }
            var d = {serial_number: this.serial,
                    security_code: this.control,
                    alias: alias};

            console.log(d);

            this.myConn.post("https://api.onlinepos.dk/api/festival_app/JoinUserBracelet", {"festival-id":settings.settings.festival_id, "session-token":this.main.sessionToken}, d, function (r) {
                console.log("OK");
                console.log(r);
                this.$refs.base.set_state("normal");
                if (r.success) {
                    console.log("SUCCESS");
                    this.main.gotoPage(10);
                } else {
                    this.error_msg=this.T("Fejl, armbånd kunne ikke tilknyttes.") + " [" + r.message + "]";
                    this.$refs.base.set_state("normal");
                }
            }.bind(this), function (e) {
                this.error_msg=this.T("Kommunikationsfejl");
                this.$refs.base.set_state("normal");
            }.bind(this));

        },
        logSerial: function () {
            console.log(this.serial);
        },
        formCheck() {
            let c = true
            c = c && this.serial.length > 0
            c = c && this.control.length > 0

            return !c
        }
    },
}
</script>

<style>

</style>