<template>
    <div :class="['select-box', size]" >
        <div v-if="help" class="label" v-bind:class="{ isEmpty: empty }">{{ help }}</div>
		<!-- @change="getSelected = $event.target.value" v-bind:value="getSelected" -->
        <select	v-bind:value="selected"
                @focus="resetScroll($event)"
                @blur="resetScroll($event)">
            <option v-for="i in optionsList" v-bind:value="i.value" :key="i.value">
            {{ i.text }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: "cSelect",
    data: function () {
		return {
			empty: false,
			optionsList: [],
		};
    },
	created: function () {
		console.log(this.help, "INITIAL SELECTED", this.selected, this.option_value);

		this.optionsList.push({value: "", text: this.help});

		this.options.forEach(function (e) {
			this.optionsList.push({value: e[this.option_value], text: e[this.option_text]});
		}.bind(this));

		console.log(this.optionsList);
	},
	props: ['options', 'help', 'selected', 'option_value', 'option_text','size'],
	methods: {
		resetScroll: function (e) { 

			var body = document.body;
			if(this.IS_IPHONE){
			
				if(body.classList.contains("fixed") == true ){
					body.classList.remove("fixed");
				}else{
					body.classList.add("fixed");
				}
			}
		}
	},
}
</script>

<style>

</style>