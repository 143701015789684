<template>
<pBase ref="base" v-bind:state="state" v-bind:modal="modal">
    <cFrame v-bind:title="T('FAQ')" class="pullup">
        <div v-for="item in faqitems" :key="item[0]">
            <cPg class="intro">{{ item[0] }}</cPg>
            <cPg>{{ T('Answer') }}: {{ item[1] }}</cPg>
            <br>
        </div>
    </cFrame>
</pBase>
</template>

<script>
import cFrame from "../components/Frame.vue"
import cPg from "../components/Pg.vue"
import pBase from "../pages/Base.vue"

export default {
    name: "pFaq",
    components: {
        pBase,
        cFrame,
        cPg
    },
    data: function (){
        return {
            confirm_dirty: true, // enable checking for changed / dirty input fields
            state: "normal",
            modal: "pNone",
            faqitems: []
        }
	},
	mounted: function () {
		this.faqitems = this.INFO('FAQ tekst');
	},
}
</script>

<style>

</style>