<!-- eslint-disable vue/require-v-for-key -->
<template>
<pBase ref="base" v-bind:state="state" v-bind:modal="modal">
    <cFrame v-bind:title="T('Detaljer')" class="pullup">
        <cPg class="heading grey">{{ T('Reference') }}</cPg>

		<div class="row">
			<div class="col-xs-12">
				<cPg class="text-small grey">{{ T('Date') }}</cPg>
				<cPg>{{ formatDate(order.datetime) }}</cPg>
			</div>
		</div>

        <div class="row mt-5 ">
            <div v-if="order.order_id !== '0'" class="col-xs-6">
                <cPg class="text-small grey">{{ T('Ordre ID') }}</cPg>
                <cPg>{{ order.order_id }}</cPg>
            </div>
            <div v-else class="col-xs-6">
                <cPg class="text-small grey">{{ T('Beløb') }}</cPg>
                <cPg>{{ amount }}</cPg>
            </div>
            <div class="col-xs-6">
                <cPg class="text-small grey">{{ T('Type') }}</cPg>
                <cPg>{{ order.type }},{{ order.what }}</cPg>
            </div>
        </div>
        <cPg class="text-small grey ">{{ T('Sted') }}</cPg>
        <cPg>{{ order.where }}</cPg>
        <hr class="mt-15 mb-15">
        <div v-if="product_lines.length > 0">
            <cPg class="heading grey">{{ T('Produkter') }}</cPg>
            <div v-for="(line, index) in product_lines.slice().reverse()" v-bind:key="index" class="mt-5">
                <cPg>{{ line.product_name }} <span class="grey">x {{ line.amount }}</span> <span class="pull-right ">Kr. {{ line.total_price }}</span></cPg>
            </div>
            <hr class="mt-15 mb-15">
        </div>
        <div v-if="payment_lines.length > 0">
            <cPg class="heading grey">{{ T('Betalt med') }}</cPg>
            <div v-for="(line, index) in payment_lines.slice().reverse()" v-bind:key="index"  class="mt-5">
                <cPg>{{ line.payment_type }} <span class="pull-right">Kr. {{ line.amount }}</span></cPg>
            </div>
        </div>
		<cActions class="tc">
			<cButton v-bind:title="T('Back')" v-on:click="gotoPage(13)"></cButton>
        </cActions>
    </cFrame>
</pBase>
</template>

<script>
import settings from '../ts/settings'
import { useMainStore } from '../stores/MainStore';

import cFrame from "../components/Frame.vue"
import cPg from "../components/Pg.vue"
import cActions from "../components/Actions.vue"
import cButton from "../components/Button.vue"
import pBase from "../pages/Base.vue"

export default {
    name: "pHistoryDetail",
    components: {
        pBase,
        cFrame,
        cPg,
		cActions,
		cButton
	},
	setup() {
		const main = useMainStore();

		return { main };
	},
    data: function (){
		return {
			payment_lines: [],
			product_lines: [],
			amount: "",
			confirm_dirty: false, // enable checking for changed / dirty input fields
            state: "normal",
            modal: "pNone",
            cname: "",
			error_msg: "",
			order: this.main.order,
		}
	},
	mounted: function() {
		console.log("MOUNTED");
		console.log(this.main.order);

		this.main.backF(function () {
			this.gotoPage(13);
			this.main.backF(this.main.back);			
		}.bind(this));

		
		this.$refs.base.set_state("modal","mLoading");
		var d={order_id: this.order.order_id,
			NFC: this.main.active_bracelet_nfc};

		console.log(d);
		this.myConn.get("https://api.onlinepos.dk/api/festival_app/TransactionInfo", {"festival-id":settings.settings.festival_id, "session-token":this.main.sessionToken}, d, function (r) {
			this.amount = this.order.amount;
			console.log(r);
			this.$refs.base.set_state("normal");
			if(r.receipt_payment_lines){
				this.payment_lines=r.receipt_payment_lines;
			}
			if(r.receipt_product_lines){
				this.product_lines=r.receipt_product_lines;
			}
			if (r.success) {
				//
			}
            else {
				this.error_msg=this.T("Fejl");
			}
		}.bind(this), function (e) {
			console.log(e);
			this.$refs.base.set_state("normal");
			this.error_msg=this.T("Kommunikationsfejl");
		}.bind(this));
	},
	methods: {
		modifyType: function(value,what){
		/* if(what == 'APP' && value == 'balance'){
		  			value = this.T('Optankning');
		   }
		   if(what !== 'APP'){
		  		value = what;
		   }*/
            return what;
		},
		addZero: function(i) {
            if (i < 10) {
                i = "0" + i;
            }
            return i;
		},
		formatDate: function(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear(),
                time = this.addZero(d.getHours()) + ":" + this.addZero(d.getMinutes());

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [day, month, year].join('-')+ " " + time;
        },
	},
}
</script>

<style>

</style>