<template>
    <pBase ref="base" v-bind:state="state" v-bind:modal="modal">
		<cFrame :title="T('Delete user')" class="pullup">
			<cError v-if="error_msg" :class="'error-text'">{{error_msg}}</cError>

			<cPg class="tc pb-15">{{ T('Are you sure you what to delete user') }}</cPg>
			<cPg class="tc pb-15">{{ T('Delete info') }}</cPg>

			<cPg class="heading grey">{{ T('User') }}</cPg>
			<div class="row mt-5 ">
				<div class="col-xs-6 mt-5">
					<cPg class="text-small grey">{{ T('Navn') }}</cPg>
					<cPg>{{ user_info.name }}</cPg>
				</div>
				<div class="col-xs-6 mt-5">
					<cPg class="text-small grey">{{ T('Email') }}</cPg>
					<cPg>{{ user_info.email }}</cPg>
				</div>
			</div>

			<cActions>
				<cButton v-bind:title="T('Delete user')" class="primary" style="background-color: red;" v-on:click="yesDelete()"></cButton>
				<cButton v-bind:title="T('Fortryd')" v-on:click="noDelete()"></cButton>
			</cActions>
		</cFrame>
	</pBase>
</template>

<script>
import config from '../ts/settings';
import { useMainStore } from '../stores/MainStore';
import pBase from "./Base.vue"
import cFrame from "../components/Frame.vue"
import cActions from "../components/Actions.vue"
import cButton from "../components/Button.vue"
import cPg from "../components/Pg.vue"
import cError from "../components/Error.vue"

export default {
    name: "pConfirmDelete",
    components: {
        pBase,
        cFrame,
        cActions,
        cButton,
        cPg,
        cError
    },
    setup() {
		const main = useMainStore();

		return { main };
	},
    data: function () {
		return {
			user_info: this.main.simple_user_info,
			error_msg: "",
			state: "normal",
			modal: "pNone",
		}
	},
	mounted: function() {
		this.$refs.base.set_state("normal");
	},
	methods: {
		yesDelete: function() {
			this.$refs.base.set_state("modal", "mLoading");
			this.myConn.post("https://api.onlinepos.dk/api/festival_app/DeleteUser", {"session-token":this.main.sessionToken, "festival-id":config.settings.festival_id}, {}, function (r) {
                this.$refs.base.set_state("normal");
				if (r.success) {
					console.log("success");
					console.log(r);
					this.main.setToken("");
					this.main.setSimpleUserInfo({});
					this.main.$emit("gotoPage", 1);
				} else {
					console.log(r);
					this.error_msg = this.T("Error deleting user.") + " ["+r.message+"]";
				}
			}.bind(this), function (r) {
                this.$refs.base.set_state("normal");
				console.log("error");
				console.log(r);
				this.error_msg = this.T("Error deleting user.");
			}.bind(this));
		},
		noDelete: function() {
			this.main.setSimpleUserInfo({});
			this.gotoPage(12);
		}
	},
}
</script>
