import { createApp } from 'vue'
import mitt from 'mitt'
import App from './App.vue'
// import router from './router';
import { IonicVue } from '@ionic/vue';
import { createPinia } from 'pinia';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
// import '@ionic/vue/css/structure.css'; //if add it remove scroll
import '@ionic/vue/css/typography.css';


// import '@ionic/vue/css/padding.css';
// import '@ionic/vue/css/float-elements.css';
// import '@ionic/vue/css/text-alignment.css';
/* Optional CSS utils that can be commented out */
// import '@ionic/vue/css/text-transformation.css';
// import '@ionic/vue/css/flex-utils.css';

import '@ionic/vue/css/display.css';
/* Theme variables */

// import './theme/variables.css';
import './css/index.css';
import './css/flexboxgrid.css';
import './scss/main.scss';

import { MyLog } from './ts/MyLog';
import { MyConn } from './ts/MyConn';
import config from './ts/settings';
import translations from './ts/translations';
import { useMainStore } from './stores/MainStore';
import mPrivacy from './components/Privacy.vue';
import mLoading from './components/Loading.vue';
import mDirty from './components/Dirty.vue';
import mBalanceInfo from './components/BalanceInfo.vue';

const IS_IPAD = navigator.userAgent.match(/iPad/i) != null;
const IS_IPHONE = !IS_IPAD && ((navigator.userAgent.match(/iPhone/i) != null) || (navigator.userAgent.match(/iPod/i) != null));
const IS_IOS = IS_IPAD || IS_IPHONE;
const IS_ANDROID = !IS_IOS && navigator.userAgent.match(/android/i) != null;
const IS_MOBILE = IS_IOS || IS_ANDROID;
const IS_WEB = !(IS_IOS || IS_ANDROID || IS_IPAD);
const IS_UIWEBVIEW = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
const IS_SAFARI_OR_UIWEBVIEW = /(iPhone|iPod|iPad).*AppleWebKit/i.test(navigator.userAgent);

const settings = config.settings

const pinia = createPinia();

const emitter = mitt();

const app = createApp(App)
  .use(pinia)
  .use(IonicVue);
  //.use(router);

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.myLog = new MyLog(0, 0);
app.config.globalProperties.IS_IPAD = IS_IPAD
app.config.globalProperties.IS_IPHONE = IS_IPHONE
app.config.globalProperties.IS_IOS = IS_IOS
app.config.globalProperties.IS_ANDROID = IS_ANDROID
app.config.globalProperties.IS_MOBILE = IS_MOBILE
app.config.globalProperties.IS_WEB = IS_WEB
app.config.globalProperties.IS_UIWEBVIEW = IS_UIWEBVIEW
app.config.globalProperties.IS_SAFARI_OR_UIWEBVIEW = IS_SAFARI_OR_UIWEBVIEW
app.config.globalProperties.myConn = new MyConn({
  heartBeatInterval:settings.heartbeat_interval_s,
  networkTimeout:settings.network_timeout_ms,
  log:app.config.globalProperties.myLog,
});

app.config.globalProperties.myConn.start();

app.component('mPrivacy', mPrivacy);
app.component("mLoading", mLoading);
app.component("mDirty", mDirty);
app.component("mBalanceInfo", mBalanceInfo);

app.directive('scroll', {
  mounted: function (el, binding) {
      const f = function (evt) {
        if (binding.value(evt, el)) {
          window.removeEventListener('scroll', f)
          window.removeEventListener('touchmove', f)
        }
      }
      window.addEventListener('scroll', f)
      window.addEventListener('touchmove',f)
  }
})
  
app.directive("mobile", {
  beforeMount: function(el, binding, vnode) {
      el.handler = function (event) {
          if(event.handled === false) return
          event.handled = true;			
          event.stopPropagation();
          event.preventDefault();
          binding.value(event);
      }
      
      if (typeof binding.value === "function") {
          el.addEventListener('click', el.handler);
          el.addEventListener('touchstart', el.handler);
      } else {
          console.log("ERROR, USE FUNCTION IN MOBILE DIRECTIVE");
      }
      
  },
  unmounted: function(el, binding, vnode) {
    el.removeEventListener('click', el.handler);
    el.removeEventListener('touchstart', el.handler);
  }
});

app.mixin({
  setup() {
    const main = useMainStore();
    main.pageDirtyF(false);

    //const router = useRouter();

    return { main, /*router*/ };
  },
  data() {
    return {
      confirm_dirty: false,
      show_modal_dirty: false,
      current_language: ""
    }
  },
  created: function() {
    if (localStorage.current_language !== undefined) {
      this.current_language = localStorage.current_language;
    }
    else {
      this.current_language = settings.default_language;
    }
  },
  mounted: function(){
    const body = document.body;
    const wIh = window.innerHeight;

    if (wIh == 635 || wIh == 559 || wIh == 553){
        body.classList.add("safari-action-bar");
    }else{
      body.classList.remove("safari-action-bar");
    }

    if (body.classList.contains("scrolled") ) {
        body.classList.remove("scrolled");
    }
  },
  methods: {
		gotoPage: function (page) {
			//console.log("GotoPage", page, this.confirm_dirty, this.main.pageDirty);

			if (this.confirm_dirty && this.main.pageDirty) {
				console.log("form dirty and confirm_dirty=true, ask for confirmation");
				this.main.goingToPageF(page);
				//this.show_modal_dirty=true;
				this.$refs.base.set_state("modal","mDirty");
			} else {
				console.log("form not dirty or confirm_dirty=false");
        this.main.gotoPage(page);
			}
		},
		T: function (key) {
			if (this.current_language in translations) {
				const trans_lang_dict = translations[this.current_language];

				if (key in trans_lang_dict) {
					return(trans_lang_dict[key]);
				} 
        else {
					return("M:" + this.current_language + ":" + key);
				}
			} 
      else {
				console.log("Language " + this.current_language + " not defined in settings");
				return("E:"+key);
			}
		},
    INFO: function (key) {
      if (this.current_language in settings.translations) {
				const trans_lang_dict = settings.translations[this.current_language];

				if (key in trans_lang_dict) {
					return(trans_lang_dict[key]);
				} 
        else {
					return("M:" + this.current_language + ":" + key);
				}
			} 
      else {
				console.log("Language " + this.current_language + " not defined in settings");
				return("E:"+key);
			}
    },
		changeLanguage: function(new_language) {
      new_language = new_language.target.value;
			this.current_language = new_language;
			localStorage.current_language = new_language;
			this.update();
		},
    update: function () {
      console.log("update");

      const p = this.main.page;
      
      this.main.gotoPage(1);

      setTimeout(() => {
        this.main.gotoPage(p);
      }, 1);

      this.emitter.emit("updateMenu", this.current_language);
      //this.$forceUpdate();
    },
	}
})

//router.isReady().then(() => {
  app.mount('#app');
//});