<template>
    <div class="actions">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "cActions"
}
</script>

<style>

</style>