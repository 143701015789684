<template>
    <cFrame v-bind:title="T('What does my balance consist of')" class="pullup">
        <cUl class="list history mt-15">
            <li class="listitem-info">
                <div class="center">
                    <p>{{ T('Saldo') }}</p>
                </div>
                <div class="right-no-font">
                    <p>{{ outputAmount(main.active_bracelet_amount) }}</p>
                </div>
            </li>
            <li class="listitem-info border">
                <div class="center">
                    <p>{{ T('Unit') }}</p>
                    <p class="meta">{{ T('Unit first') }}</p>
                    <p class="meta">{{ T('No refund') }}</p>
                </div>
                <div class="right-no-font">
                    <p>{{ outputAmount(main.active_bracelet_amount2) }}</p>
                </div>
            </li>
            <li class="listitem-info">
                <div class="center">
                    <p class="fb">{{ T('Saldo total') }}</p>
                </div>
                <div class="right">
                    <p class="">{{ getTotalAmount(main.active_bracelet_amount, main.active_bracelet_amount2) }}</p>
                </div>
            </li>
        </cUl>
        <cActions>
            <cButton v-bind:title="T('Tilbage')" class="tc" v-on:click="close"></cButton>
        </cActions>
    </cFrame>
</template>

<script>
    import { useMainStore } from '../stores/MainStore';
    import cActions from "./Actions.vue"
    import cFrame from "./Frame.vue"
    import cButton from "./Button.vue"
    import cUl from "./Ul.vue"

    export default {
        name: "mBalanceInfo",
        components: {
            cActions,
            cFrame,
            cButton,
            cUl,
        },
        setup() {
            const main = useMainStore();

            return { main};
        },
        methods: {
            close() {
                this.emitter.emit('close')
            },
            outputAmount(amount) {
                if(amount !== undefined){
                    return amount.replace('.', ',');
                }
                return 0;
            },
            getTotalAmount(amount1, amount2) {
                if (isNaN(amount1)) {
                    amount1 = 0;
                }

                if (isNaN(amount2)) {
                    amount2 = 0;
                }
                let total = Number(amount1) + Number(amount2);
                return this.outputAmount(total.toFixed(2));
            },
        }
    }
</script>