<template>
    <pBase ref="base" v-bind:state="state" v-bind:modal="modal">
        <cFrame v-bind:title="'Betaling'" :class="'pullup payment'" v-bind:full="ready" >
            <cError v-if="error_msg" :class="'error-text'">
                {{ T('Der er opstået en fejl og betalingen kunne ikke sættes igang.') }}<br>[{{error_msg}}]<br><br>
            <!-- 	  <cButton title="Forced continue (debug)" class="primary" v-mobile:click="function() { forceReady(); }"></cButton>-->
            </cError>
            <iframe v-else-if="ready" name="payment" width="100%" height="100%" id="payment" v-bind:src="url"></iframe>
            <div v-else>
                Vent venligst<br><br><br>
            </div>
        </cFrame>
    </pBase>
</template>

<script>
import config from '../ts/settings';
import { useMainStore } from '../stores/MainStore';

import cFrame from "../components/Frame.vue"
import cError from "../components/Error.vue"
import pBase from "../pages/Base.vue"

const settings = config.settings

export default {
    name: "pPayment",
    components: {
        pBase,
        cFrame,
        cError,
    },
    setup() {
        const main = useMainStore();

        return { main };
    },
    data: function () {
        return {
            merchant: '90247984', // '90247598',
            amount: this.main.pay.amount * 100 , //  '100',
            //order_id: '3',
            currency: '208',
            url: settings.pay_url,
            ready:false,
            confirm_dirty: false, // enable checking for changed / dirty input fields
            state: "normal",
            modal: "pNone",
            error_msg: '',
        }
    },
	mounted: function() {

		window.addEventListener('message',function(message){
			console.log("DIBS EVENT");
			console.log(message);
			window.scroll(0,0);
		});

		var d = {serial_number: this.main.active_bracelet,
                amount: this.main.pay.amount * 100,
                auto_refill: this.main.pay.refill > 0,
                refill_amount: this.main.pay.refill * 100};
		
		this.myConn.post("https://api.onlinepos.dk/api/festival_app/PaymentStarted", {"festival-id":settings.festival_id, "session-token":this.main.sessionToken}, d, function (r) {
			if (r.success) {
				console.log("Success");
				//console.log(r); 
                
				this.url+="?amount="+d.amount+"&refill="+d.refill_amount+"&order_id="+r.order_id;

				//console.log(this.url);

				this.main.active_orderF({order_id:r.order_id.toString(), serial_number: this.main.active_bracelet, datetime: Date.now()});
				this.ready=true;
			} else {
				console.log(r);
				this.error_msg=r.message;
			}
		}.bind(this), function (e) {
			console.log("ERR",e);
			this.error_msg=this.T("Fejl under kommunikation med server.");
		}.bind(this));
	},
	methods: {
		forceReady: function () {
			this.error_msg = "";
			this.url += "?amount="+this.amount+"&order=4";
			console.log(this.url);
			this.ready = true;
		}
	},
}
</script>

<style>

</style>