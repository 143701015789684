<!-- eslint-disable -->
<template>
<pBase ref="base" v-bind:state="state" v-bind:modal="modal">
    <cFrame :title="T('Opret bruger')" class="pullup mb-50">

        <cError v-if="error_msg" :class="'error-text'">{{error_msg}}</cError>

        <cLanguage v-on:change="changeLanguage($event)" v-bind:language="current_language"></cLanguage>

        <cInput v-bind:label="T('Full name')" itype="text" :placeholder="T('Full name')" v-model="name" help="" iconhelp=""></cInput>

        <cGroup>
            <cInput :label="T('Email')"         itype="email" :placeholder="T('Email')"         v-on:input="checkMails" v-bind:err="email_error" v-model="email" :help="T('Du skal bruge din email som login.')" :iconhelp="T('Din email.')"></cInput>
            <cInput :label="T('Confirm email')" itype="email" :placeholder="T('Confirm email')" v-on:input="checkMails" v-bind:err="email_error" v-model="email2" :help="T('Du skal bruge din email som login.')" :iconhelp="T('Din email.')"></cInput>
        </cGroup>

        <cSelect v-bind:options="countries"
                option_value="prefix" option_text="label"
                v-bind:help="T('Vælg landekode')"
                v-bind:size="'one-third'"
                v-bind:selected="telpre"
                v-on:input="telpre = $event.target.value">
        </cSelect>

        <cInput :label="T('Telefonnummer')" v-bind:size="'two-third'" itype="tel" :placeholder="T('Telefonnr.')" v-model="tel" :help="T('Dit telefonnummer')" :iconhelp="T('Dit telefonnummer.')"></cInput>

        <cGroup>
            <cInput :label="T('Password with min')" itype="password"  v-on:input="checkPassword" v-bind:err="password_error"  :placeholder="T('Password with min')" v-model="password" :help="T('Ønsket password')"></cInput>
            <cInput :label="T('Bekræft password')" itype="password"  v-on:input="checkPassword" v-bind:err="password_error"  :placeholder="T('Bekræft password')" v-model="password2" :help="T('Ønsket password')"></cInput>
        </cGroup>

        <h1 class="tc mt-30">{{ T('Tilbagebetaling') }}</h1>

		<cGroup>
            <cSelect v-bind:options="refundOption"
                    option_value="code" option_text="name"
                    v-bind:help="T('Refund type')"
                    v-bind:selected="cname" 
					v-on:input="cname = $event.target.value">
            </cSelect>
        </cGroup>

        <!-- <cPg v-if="require_konto && cname == 'DK'" class="mt-15 mb-15">{{ T('Reg. nr. og konto nr. skal udfyldes.') }}</cPg>
        <cPg v-if="require_iban && cname != 'DK'" class="mt-15 mb-15">{{ T('IBAN og Swift skal udfyldes.') }}</cPg> -->

        <cInput v-if="cname == 'DK'" :label="T('Reg. nr.')" itype="number" v-on:input="checkKontonr" :placeholder="T('Reg. nr.')" v-model="regnr" v-bind:size="'one-third'" :help="T('Reg. nr. på din konto')" :iconhelp="T('Reg. nr. på din konto')"></cInput>
        <cInput v-if="cname == 'DK'" :label="T('Konto nr.')" itype="number" v-on:input="checkKontonr" :placeholder="T('Konto nr.')" v-model="kontonr" v-bind:size="'two-third'" :help="T('Konto nr. på din konto')" :iconhelp="T('Konto nr. på din konto')"></cInput>

		<h3 v-if="cname == 'DK' && doublecheck_konto" class="fb mt-5 mb-10">{{ T("Confirm payment info") }}</h3>

        <cInput v-if="cname == 'DK' && doublecheck_konto" :label="T('Bekræft reg. nr.')" itype="number" v-on:input="checkKontonr" :placeholder="T('Bekræft reg. nr.')" v-model="regnr2" v-bind:size="'one-third'" :help="T('Reg. nr. på din konto')" :iconhelp="T('Reg. nr. på din konto')"></cInput>
        <cInput v-if="cname == 'DK' && doublecheck_konto" :label="T('Bekræft konto nr.')" itype="number" v-on:input="checkKontonr" :placeholder="T('Bekræft konto nr.')" v-model="kontonr2" v-bind:size="'two-third'" :help="T('Konto nr. på din konto')" :iconhelp="T('Konto nr. på din konto')"></cInput>

        <cInput v-if="cname !== 'DK' && cname !== ''" :label="T('IBAN no.')" itype="text" :placeholder="T('IBAN no.')" v-model="iban" :iconhelp="T('IBAN no. for your bank account')"></cInput>
        <cInput v-if="cname !== 'DK' && cname !== ''" :label="T('SWIFT')" itype="text" :placeholder="T('SWIFT')" v-model="swift" :iconhelp="T('SWIFT help')"></cInput>

        <cActions>
            <cPg class="mt-15 mb-15" v-on:click="showModalPolicy()">{{ T('Se') }} <span class="link primary">{{T('Terms and conditions')}}</span></cPg>

            <cError v-if="error_msg" :class="'error-text'">{{error_msg}}</cError>

            <cButton :title="T('Opret bruger')"  v-bind:class="{ 'disabled': formNotComplete, 'primary': true }" v-on:click="formComplete() ? create_user() : false"></cButton>
            <cButton :title="T('Allerede oprettet? Log ind her')" v-on:click="gotoPage(1)"></cButton>
        </cActions>
    </cFrame>
</pBase>
</template>

<script>
/* eslint-disable */
import config from '../ts/settings';
import { useMainStore } from '../stores/MainStore';

import pBase from "./Base.vue"
import cFrame from "../components/Frame.vue"
import cError from "../components/Error.vue"
import cLanguage from "../components/Language.vue"
import cInput from "../components/Input.vue"
import cActions from "../components/Actions.vue"
import cButton from "../components/Button.vue"
import cPg from "../components/Pg.vue"
import cGroup from "../components/Group.vue"
import cSelect from "../components/Select.vue"

const settings = config.settings

export default {
    name: "pCreateUser",
    components: {
        pBase,
        cFrame,
        cActions,
        cButton,
        cInput,
        cPg,
        cGroup,
        cSelect,
        cLanguage,
        cError
    },
	setup() {
		const main = useMainStore();

		return { main };
	},
    data: function () {
		return {
			name: '',
			email: '',
			email2: '',
			tel: '',
			telpre: '',
			password: '',
			password2: '',
			cname: '',
			regnr: '',
			kontonr: '',
			regnr2: '',
			kontonr2: '',
			iban: '',
			swift: '',
			countries: settings.countries,
			confirm_dirty: true, // enable checking for changed / dirty input fields
			state: "normal",
			modal: "pNone",
			email_error: false,
			password_error: false,
			konto_error:false,
			error_msg: "",
			doublecheck_konto: settings.dblcheck_konto, 
			require_konto: settings.require_konto,
			require_iban: settings.require_iban,
			refundOption: [],
		}
    },
	created: function () {
		let o1 = {
			code: 'DK',
			name: this.T('dk_account')
		};
		let o2 = {
			code: 'IBAN',
			name: this.T('en_account')
		};
		this.refundOption.push(o1);
		this.refundOption.push(o2);
	},
    methods: {
		// changeLanguage: function(new_language) {
		// 	this.current_language=new_language;
		// 	localStorage.current_language=new_language;
		// 	this.update();
		// },
		
        create_user: function () {
            console.log("create user...");

            this.$refs.base.set_state("modal","mLoading");

			var d = {
				"name":this.name.trim(), "mobile":this.tel.trim(), "mobile_countrycode":this.telpre,
                "email": this.email.trim(), "password": this.password.trim(), "country":this.cname,
                "bank_regnr":this.regnr, "bank_account":this.kontonr, "bank_iban":this.iban, "bank_swift":this.swift,
                "address": "", "zip": "", "city": "",
                "language":this.current_language
			};

			console.log(d);

			this.myConn.post("https://api.onlinepos.dk/api/festival_app/CreateUser", {"festival-id":settings.festival_id}, d, function (r) {
                this.$refs.base.set_state("normal");
				if (r.success) {
					console.log("success");
					console.log(r);
					this.main.setToken(r.session_token);
					this.main.gotoPage(10);
				} else {
					this.error_msg=this.T("Fejl, bruger kunne ikke oprettes, tjek at alle felter er udfyldt og prøv igen." + " ["+r.message+"]");
				}

			}.bind(this), function (r) {
                this.$refs.base.set_state("normal");
				console.log("error");
				console.log(r);
				this.main.setToken("");
				this.error_msg=this.T("Fejl, tjek at alle fejler er udfyldte og prøv igen.");
			}.bind(this));

        },
        showModalPolicy: function () {
            this.$refs.base.set_state("modal","mPrivacy");
        },

		checkMails: function () {
			this.email  = this.email.replace(/\s/g,'');
			this.email2 = this.email2.replace(/\s/g,'');
			this.email_error = this.email != this.email2;
			this.email_error = this.email_error || !this.email.includes("@");
			this.email_error = this.email_error || !this.email.includes(".");
			this.email_error = this.email_error || this.email.length<6;
			if (this.email_error) {
				this.error_msg=this.T("Email felterne skal indeholde en korrekt email og være identiske.");
			} else {
				this.error_msg="";
			}
		},
		checkPassword: function () {
			this.password  = this.password.replace(/\s/g,'');
			this.password2 = this.password2.replace(/\s/g,'');
			this.password_error = this.password != this.password2;
			//this.password_error = false;
			this.password_error = this.password_error || (this.password.length!=0 && this.password.length<8);
			if (this.password_error) {
				this.error_msg=this.T("Passwords skal være min. 6 tegn og de skal være identiske.");
			} else {
				this.error_msg="";
			}
		},
		checkKontonr: function () {
			console.log("checkKontonr");
			this.konto_error = false;
			if (this.doublecheck_konto) {
				this.konto_error = this.regnr != this.regnr2;
				this.konto_error = this.konto_error || (this.kontonr != this.kontonr2);
				console.log(this.konto_error);
				if (this.konto_error) {
					this.error_msg=this.T("Kontooplysninger skal være identiske.");
				} else {
					this.error_msg="";
				}
				console.log(this.error_msg);
			}
		},
		formError: function () {
			console.log("formError");
			if (this.name.length > 0 && this.name.length <= 4) {
				return this.T("Navn er for kort");
			}
			if (this.email.length > 0 && this.email.length <= 5) {
				return this.T("Email er for kort");
			}
			if (this.password.length > 0 && this.password.length <= 7) {
				return this.T("Adgangskode er for kort");
			}
			if (this.tel.length > 0 && this.tel.length <= 7) {
				return this.T("Telefonnr er for kort");
			}
			if (this.tel.length > 0 && !this.tel.match(/^\d+$/)) {
				return this.T("Telefonnr er ikke et nummer");
			}
			if (this.telpre.length == 0 && this.tel.length > 2) {
				return this.T("Husk at vælge landekode");
			}
			if (this.regnr.length > 0 && this.regnr.length != 4 ) {
				return this.T("Registreringsnr. skal være 4 tegn");
			}
			if (this.kontonr.length > 0 && this.kontonr.length > 10) {
				return this.T("Kontonr. må max være 10 tegn");
			}
			if (this.cname=="DK") {
				if (this.require_konto) {
					if (this.regnr.length > 0 && this.regnr.length <= 3) {
						return this.T("Regnr skal udfyldes");
					}
					if (this.kontonr.length > 0 && this.kontonr.length <= 5) {
						return this.T("Kontonr skal udfyldes");
					}
					if (!this.regnr.length.match(/^\d+$/)) {
						return this.T("Regnr skal vaere tal");
					}
					if (!this.kontonr.length.match(/^\d+$/)) {
						return this.T("Kontonr skal vaere tal");
					}
				}
				if (this.doublecheck_konto) {
					if (this.regnr != this.regnr2 || this.kontonr != this.kontonr2) {
						return this.T("Kontooplysninger skal være identiske.");
					}
				}
			} else {
				if (this.require_iban) {
					if (this.iban.length>0 && this.iban.length<=2) {
						return this.T("Iban skal udfyldes");
					}
					if (this.swift.length>0 && this.swift.length<=2) {
						return this.T("Swift skal udfyldes");
					}
				}
			}			
			return "";
		},
		formComplete: function () {
			var c=true;
			c=c && this.name.length>5;
			c=c && this.email.length>5;
			c=c && this.password.length>5;
			c=c && !this.password_error;
			c=c && !this.email_error;
			c=c && !this.konto_error;
			c=c && this.tel.length>7;
			c=c && this.telpre.length>0;
			c=c && this.cname.length>0;
			if (this.cname=="DK") {
				if (this.require_konto) {
					if (this.regnr.length<=3) {
						c=false;
					}
					if (this.kontonr.length<=5) {
						c=false;
					}
					if (this.kontonr.length>10) {
						c=false;
					}
				}
			} else {
				if (this.require_iban) {
					if (this.iban.length<=2) {
						c=false;
					}
					if (this.swift.length<=2) {
						c=false;
					}
				}
			}
			return c;
		},
		buttonClass: function () {
			return "disabled";
		}
    },
	computed: {
		formNotComplete: function () {
			var e = this.formError();
			this.error_msg = e;
			return !this.formComplete();
		}
	},
}
</script>

<style>

</style>