<template>
<cFrame title="Loading" class="pullup loadingscreen">
    <i class="fas fa-spinner"></i>
    <p>Loading...</p>
</cFrame>
</template>

<script>
import cFrame from "./Frame.vue"

export default {
    name: "mLoading",
    components: {
        cFrame
	},
}
</script>

<style>

</style>