<!-- eslint-disable vue/no-unused-vars -->
<template>
<pBase ref="base" v-bind:state="state" v-bind:modal="modal">
    <cFrame v-bind:title="T('Historik')" class="pullup">
        <cSelect v-if="userBracelets.length > 0" v-bind:options="userBracelets" option_value="name" option_text="name" v-bind:selected="cname" v-on:input="cname = $event.target.value" v-bind:help="T('Vælg armbånd')"></cSelect>

        <div v-if="userBracelets.length > 0">
            <div v-for="bracelet in userBracelets" :key="bracelet.serial_number">
				<div v-if="bracelet.name == cname">

					<cBracelet v-bind:amount="bracelet.balance" v-bind:amount2="bracelet.giftcard"></cBracelet>

					<cPg class="link fb tc pt-10 pb-10 mb-15" v-on:click="download()">{{ T('Download historik som PDF') }}</cPg>

					<div class="col-xs-12 col-sm-12">

					

					<div class="hist-filters">
						<div class="item-2 pr-5 basis-25">
							<cInput v-on:input="numberOnlyInDay" v-bind:label="'DD'" itype="text" v-bind:maxlength="2" v-model="selectedDay" :placeholder="'DD'"></cInput>
						</div>

						<div class="item-1 pr-5 center">
							<cPg>-</cPg>
						</div>

						<div class="item-2 pr-10 basis-45">
							<cInput v-on:input="numberOnlyInMonth" v-bind:label="'MM'" itype="text" v-bind:maxlength="2" v-model="selectedMonth" :placeholder="'MM'"><i v-if="selectedDay || selectedMonth" class="fas fa-times icon-search" v-on:click="clearDateFilter"></i></cInput>
						</div>

						<!-- <div class="item-1 pr-10">
							<i v-if="selectedDay || selectedMonth" class="fas fa-times my-pointer" v-on:click="clearDateFilter"></i>
						</div> -->

						<div class="item-5">
							<cInput v-bind:label="T('Search for event')" itype="text" :placeholder="T('Search for event')" v-model="searchInput" help="" iconhelp=""><i v-if="searchInput" class="fas fa-times icon-search" v-on:click="clearFilter"></i></cInput>
						</div>
					</div>

				</div>
							
					<!-- <div class="col-xs-12 col-sm-12">
						<cInput v-bind:label="T('Search for event')" itype="text" :placeholder="T('Search for event')" v-model="searchInput" help="" iconhelp=""></cInput>
						<i v-if="searchInput" class="fas fa-times icon-search" v-on:click="clearFilter"></i>
					</div> -->

					<ion-grid>
						<ion-row>
							<ion-col>
								<div id="all" class="tablink my-tab tc border-selected" @click="onTabClick('all')">{{ T('All') }}</div>
							</ion-col>

							<ion-col>
								<div id="balance" class="tablink my-tab tc" @click="onTabClick('balance')">{{ T('Beløb') }}</div>
							</ion-col>

							<ion-col>
								<div id="giftcard" class="tablink my-tab tc" @click="onTabClick('giftcard')">{{ T('Enheder') }}</div>
							</ion-col>

							<ion-col>
								<div id="voucher" class="tablink my-tab tc" @click="onTabClick('voucher')">{{ T('My Vouchers') }}</div>
							</ion-col>
						</ion-row>
					</ion-grid>

					<cUl class="list history"  v-if="filterEvents(bracelet.history.slice()).length > 0">
						<cHistoryItem v-on:click="show_details(h)" 
										v-for="(h, k) in filterEvents(bracelet.history.slice())"
										:key="h.datetime+h.amount" 
										v-bind:what="h.what" 
										icon="fas fa-angle-right" 
										v-bind:amount="h.amount" 
										v-bind:where="h.where" 
										v-bind:type="h.type" 
										v-bind:datetime="formatDate(h.datetime)">
						</cHistoryItem>
					</cUl>
					<cPg v-else class="mt-30 mb-30">{{ T('Ingen historik fundet for dette armbånd') }}</cPg>

				</div>
            </div>
        </div>

        <div v-else class="mt-30 tc">
            <cPg>{{ T("Opret dit armbånd for at se historik.") }}</cPg>
            <div class=""><cButton v-bind:title="T('Connect bracelet')" class="primary mt-30" v-mobile:click="function() { gotoPage(11); }" ></cButton></div>
        </div>

    </cFrame>
</pBase>
</template>

<script>
import config from '../ts/settings'
import { useMainStore } from  '../stores/MainStore';

import cFrame from "../components/Frame.vue"
import cPg from "../components/Pg.vue"
import cBracelet from "../components/Bracelet.vue"
import cButton from "../components/Button.vue"
import cSelect from "../components/Select.vue"
import cUl from "../components/Ul.vue"
import cHistoryItem from "../components/HistoryItem.vue"
import cInput from "../components/Input.vue"
import pBase from "../pages/Base.vue"
import {
	IonCol,
	IonGrid,
	IonRow,
} from '@ionic/vue';

const settings = config.settings

export default {
    name: "pHistory",
    components: {
        pBase,
        cFrame,
        cSelect,
        cBracelet,
        cPg,
        cUl,
        cHistoryItem,
        cButton,
		cInput,
		IonCol,
		IonGrid,
		IonRow,
    },
	setup() {
		const main = useMainStore();

		return { main };
	},
    data: function (){
		return {
			userBracelets: [],
			confirm_dirty: false, // enable checking for changed / dirty input fields
            state: "normal",
            modal: "pNone",
            cname: "",
			info_msg: "",
			error_msg: "",
			searchInput: "",
			activeTab: "all",
			selectedDay: "",
			selectedMonth: "",
		}
	},
	mounted: function() {
		console.log("MOUNTED");

		this.main.backF(function () {
			this.gotoPage(10);
			this.main.backF(false);			
		}.bind(this));

		
		console.log("EventBus.history_cache_date",this.main.history_cache_date);

		var use_cache=false;
		if (this.main.history_cache_date != null && this.main.history_cache != null &&this.main.history_cache.length > 0) {
			var age=Date.now() - this.main.history_cache_date
			console.log("Age",age);
			if (age>1 && age<30000) {
				use_cache=true;
			}
		}

		if (use_cache) {
			this.userBracelets = this.main.history_cache
			this.cname=this.userBracelets[0].name;
			if (this.main.active_bracelet_nfc != "") {
				for (var i=0;i<this.userBracelets.length;i++) {
					if (this.userBracelets[i].NFC == this.main.active_bracelet_nfc) {
						this.cname=this.userBracelets[i].name;
					}
				}
			}
			
		}
		else {
			this.$refs.base.set_state("modal","mLoading");
		
			this.myConn.get("https://api.onlinepos.dk/api/festival_app/UserBracelets", {"festival-id":settings.festival_id, "session-token":this.main.sessionToken}, {}, function (r) {
				console.log("OK");
				this.$refs.base.set_state("normal");
				if (r.success) {
					this.main.history_cache = r.bracelets;
					this.main.history_cache_date = Date.now();
					if(r.bracelets.length > 0){
						for (var j=0;j<r.bracelets.length;j++) {
							console.log("BRACELET",j);
							for (var k=0;k<r.bracelets[j].history.length;k++) {
								console.log("H:",r.bracelets[j].history[k]);
							}						
						}
						this.userBracelets = r.bracelets
						this.cname = this.userBracelets[0].name;
						if (this.main.active_bracelet_nfc != "") {
							for (var i=0;i<this.userBracelets.length;i++) {
								if (this.userBracelets[i].NFC == this.main.active_bracelet_nfc) {
									this.cname=this.userBracelets[i].name;
								}
							}
						}
					}
				} else {
					this.error_msg=this.T("Fejl");
				}
			}.bind(this), function (e) {
				this.$refs.base.set_state("normal");
				this.error_msg=this.T("Kommunikationsfejl");
			}.bind(this));
		}
	},
	methods: {
		addZero: function(i) {
            if (i < 10) {
                i = "0" + i;
            }
            return i;
		},
		changeOrder: function(i){
			i.sort(function(a,b){
						var c = new Date(a.datetime);
						var d = new Date(b.datetime);
						return d-c;
						});
			return i;
		},
		changeSelected: function(cname){
			this.cname=cname;
		},
        formatDate: function(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear(),
                time = this.addZero(d.getHours()) + ":" + this.addZero(d.getMinutes());

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [day, month, year].join('-')+ " " + time;
        },
		show_details: function (h) {
			console.log(h.order_id);
			//if(h.order_id !== '0'){
				this.main.orderF(h);
				this.gotoPage(18);
			//}
		},
		download: function () {
			console.log("DOWNLOAD");
			this.$refs.base.set_state("modal","mLoading");
			this.myConn.post("https://api.onlinepos.dk/api/festival_app/SendHistoryMail", {"festival-id":settings.festival_id, "session-token":this.main.sessionToken}, {}, function (r) {
				console.log("OK");
				console.log(r);
				this.$refs.base.set_state("normal");
				if (r.success) {
					this.error_msg=this.T("PDF afsendt");
				} else {
					this.error_msg=this.T("Fejl");
				}
			}.bind(this), function (e) {
				console.log("ERROR",e);
				this.$refs.base.set_state("normal");
				this.error_msg=this.T("Kommunikationsfejl");
			}.bind(this));
		},
		clearFilter() {
			this.searchInput = "";
		},
		clearDateFilter() {
			this.selectedDay = "";
			this.selectedMonth = "";
		},
		filterEvents(i) {
			let fullList = this.changeOrder(i);
			if (this.activeTab == "balance") {
				fullList = fullList.filter((h) => h.where.toLowerCase().includes(this.searchInput.toLowerCase()) && h.type == "balance")
			}
			else if (this.activeTab == "voucher") {
				fullList = fullList.filter((h) => h.where.toLowerCase().includes(this.searchInput.toLowerCase()) && h.type == 125)
			}
			else if (this.activeTab == "giftcard") {
				fullList = fullList.filter((h) => h.where.toLowerCase().includes(this.searchInput.toLowerCase()) && h.type == "giftcard")
			}

			if (this.selectedDay != '' && this.selectedMonth != '') {
				let selectedDate = this.selectedDay.toString().padStart(2, '0') + '-' + this.selectedMonth.toString().padStart(2, '0') + '-2023';
				fullList = fullList.filter((h) => this.formatDate(h.datetime).includes(selectedDate));
			}
			else if (this.selectedMonth != '') {
				let selectedDate = '-' + this.selectedMonth.toString().padStart(2, '0') + '-2023';
				fullList = fullList.filter((h) => this.formatDate(h.datetime).includes(selectedDate));
			}

			if (this.searchInput == "") {
				return fullList;
			}
			else {
				return fullList.filter((h) => h.where.toLowerCase().includes(this.searchInput.toLowerCase()))
			}
		},
		onTabClick(tab) {
			let tabLinks = document.getElementsByClassName("tablink");
			for (let i = 0; i < tabLinks.length; i++) {
				tabLinks[i].classList.remove("border-selected");
			}
			let tabClickOn = document.getElementById(tab);
			tabClickOn.classList.add("border-selected");
			this.activeTab = tab;
		},
		numberOnlyInDay() {
			let newAmount = this.selectedDay.replace(/[^0-9]+/g, '')

			if (parseInt(newAmount) > 31) {
				newAmount = 31;
			}

			this.selectedDay = newAmount;
		},
		numberOnlyInMonth() {
			let newAmount = this.selectedMonth.replace(/[^0-9]+/g, '')

			if (parseInt(newAmount) > 12) {
				newAmount = 12;
			}

			this.selectedMonth = newAmount;
		}
	},
}
</script>

<style>
.color-black {
	color: black;
}
</style>