<template>
    <div class=" col-xs-12 col-sm-12 tc mb-10">
        <div class="tickets">
            <i class="ticket-alt"></i>
            <span class="tcontent">
                <cPg :class="'title'">{{ title }}</cPg>
                <h1 :class="'amount mb-0'">{{ outputAmount }} <span v-if="amount">{{ T('stk.') }}</span></h1>
            </span>
        </div>
        <cPg class="link fb mt-5" v-if="transfer" v-on:click="$emit('transfer')">{{ T('Overfør billet') }}</cPg>
    </div>
</template>

<script>
import cPg from "./Pg.vue"

export default {
    name: "cTickets",
    components: {
        cPg
    },
    props: ['title','amount','icon','transfer'],
    computed: {
        outputAmount () {
            if(this.amount !== ''){
                return Math.trunc(this.amount);
                //return value.replace('.', ',')
            }
            else {
                return 0;
            }
        }
    }
}
</script>

<style>

</style>