<template>
<pBase ref="base" v-bind:state="state" v-bind:modal="modal">
    <cFrame v-bind:title="T('Login')" class="pullup">

        <cError v-if="error_msg" :class="'error-text'">{{error_msg}}</cError>

        <cLanguage v-on:change="changeLanguage($event)" v-bind:language="current_language"></cLanguage>

        <!--</div> Why is it here? -->

        <cInput v-bind:label="T('Email')" itype="email" v-bind:placeholder="T('Email')" v-bind:err="error_state" v-model="email" v-bind:help="T('emailhelp')" v-bind:iconhelp="T('emailicon')"></cInput>
        <cInput v-bind:label="T('Password')" itype="password" v-bind:placeholder="T('Password')" v-bind:err="error_state" v-model="password">
            <cLink v-bind:text="T('Glemt password')" v-on:click="gotoPage(2)" class="forgot-link tr"></cLink>
        </cInput>

        <cActions>
            <cButton v-bind:title="T('Login')" class="primary" v-on:click="login()"></cButton>
            <cButton v-bind:title="T('Opret ny bruger')" v-on:click="gotoPage(4)"></cButton>
        </cActions>

        <table>
            <tr>
                <td><img src="https://portal.dibspayment.eu/assets/images/main/easy-logo-blue_150px.svg" width="50"></td>
                <td><div class="visa"></div></td>
                <td><div class="mc"></div></td>
                <td><div class="mp"></div></td>
                <td><div class="dkt"></div></td>
            </tr>
        </table>
    </cFrame>
</pBase>
</template>

<script>
import { useMainStore } from '../stores/MainStore';
import config from '../ts/settings';

import pBase from "./Base.vue"
import cFrame from "../components/Frame.vue"
import cError from "../components/Error.vue"
import cLanguage from "../components/Language.vue"
import cInput from "../components/Input.vue"
import cActions from "../components/Actions.vue"
import cButton from "../components/Button.vue"
import cLink from "../components/Link.vue"

const settings = config.settings

export default {
    name: "pLogin",
    components: {
        pBase,
        cFrame,
        cError,
        cLanguage,
        cInput,
        cActions,
        cButton,
        cLink
    },
    setup() {
        const main = useMainStore();

        return { main };
    },
    data: function () {
        return {
            email: "",    // 'test@test.com',
            password: "", // '1234',
            confirm_dirty: false, // enable checking for changed / dirty input fields
            state: "normal",
            modal: "pNone",
            error_msg: "",
            error_state: false,
        }
    },

    methods: {
        login: function () {
            this.$refs.base.set_state("modal","mLoading");
			this.error_state=false;
			this.myConn.post("https://api.onlinepos.dk/api/festival_app/Authenticate", {"festival-id":settings.festival_id}, {"email":this.email.trim(),"password":this.password.trim()}, (r) => {
				
                this.$refs.base.set_state("normal");
                
				if (r.success) {
					this.main.setToken(r.session_token);
					this.main.gotoPage(10);
					this.myLog.log("Login success");
				} else {
					this.error_msg=this.T("Loginfejl");
					this.error_state=true;
					this.$refs.base.set_state("normal");
					this.main.setToken("");
					this.myLog.log("Login failed");
				}
			}
            , (e) => {
				this.error_msg = this.T("Kommunikationsfejl");
				//this.error_state=true;
				this.$refs.base.set_state("normal");
				this.main.setToken("");
				this.myLog.log("Login comm error");
			});
		},
        showModalPolicy: function () {
            this.$refs.base.set_state("modal","mPrivacy");
        },
        showModalLoading: function () {
			console.log(settings);
            this.$refs.base.set_state("modal","mLoading");
            setTimeout(function () {
                this.$refs.base.set_state("normal");
            }.bind(this), 3000);
        }
    },
}
</script>

<style>

</style>