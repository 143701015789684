
import {
	IonCol,
    IonGrid,
    IonRow,
} from '@ionic/vue';

export default {
    name: "cFillUpButton",
    components: {
        IonCol,
        IonRow,
        IonGrid
    },
    props: [
        'amount'
    ]
}
