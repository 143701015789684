<template>
<cFrame v-bind:title="T('Er du sikker?')" class="pullup">
    <cPg class="tc">{{ T('Er du sikker på at du ønsker at forlade siden uden at gemme?') }}</cPg>
    <cActions class="tc mt-30"> 
        <cButton v-bind:title="T('Ja, jeg er sikker')" class="button primary" v-on:click="closeModalOk()"></cButton>
        <cButton v-bind:title="T('Fortryd')" class="button secondary" v-on:click="closeModalCancel()"></cButton>
    </cActions>
</cFrame>
</template>

<script>
import { useMainStore } from '../stores/MainStore';
import cFrame from "./Frame.vue"
import cPg from "./Pg.vue"
import cActions from "./Actions.vue"
import cButton from "./Button.vue"

export default {
    name: "mDirty",
    components: {
        cFrame,
        cPg,
        cActions,
        cButton
    },
	setup() {
		const main = useMainStore();
		return { main };
	},
    created: function () {
        this.main.viewStateF('modal');
		this.emitter.on('closeDirty', e => {
			console.log("close from eventbus");
			this.closeModal();
		});
	},
	unmounted: function () {
        this.main.viewStateF('page');
	},
	methods: {
		closeModalOk: function () {
			console.log("closing with ok, going to", this.main.goingToPage);
			this.emitter.emit('close');
			this.gotoPage(this.main.goingToPage);
		},
		closeModalCancel: function () {
			console.log("closing with cancel, staying on page");
			this.emitter.emit('close');
		},
	},
}
</script>

<style>

</style>