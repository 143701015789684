// REMOVED
"FAQ tekst"
"Vilkår og betingelser overskrift"
"Vilkår tekst"

const translations = {
    "DA":{
        "Login":"Log ind",
        "Log ud":"Log ud",
        "Password":"Adgangskode",
        "Email":"Emailadresse",
        "Confirm email":"Bekr\xE6ft emailadresse",
        "Telefonnr.":"Telefonnr.",
        "Tilbagebetaling": "Tilbagebetaling",
        "Se": "Se",
        "Terms and conditions": "Handelsbetingelser",
        "Reg. nr.": "Reg.nr.",
        "Reg. nr. på din konto": "Reg.nr. p\xE5 din konto",
        "Konto nr.": "Kontonr.",
        "Konto nr. på din konto": "Kontonr. p\xE5 din konto",
        "IBAN no.": "IBAN no.",
        "Konto til tilbagebetaling": "Konto til tilbagebetaling",
        "Glemt password":"Glemt adgangskode?",
        "Send password reset": "Nulstil adgangskode",
        "Opret ny bruger":"Opret ny bruger",
        "Allerede oprettet? Log ind her": "Allerede oprettet? Log ind her",
        "emailhelp":"Du skal bruge din email som login",
        "emailicon":"Din email.",
        "Indtast oplysningerne, som står bag på dit armbånd.":"Indtast oplysningerne, som st\xE5r bag p\xE5 dit armb\xE5nd.",
        "Klik på armbåndet for at tilføje dit armbånd til appen, så du nemt kan tanke op og holde styr på dine transaktioner.": "Klik p\xE5 armb\xE5ndet for at tilf\xF8je det til appen. S\xE5 du nemt kan tanke op og holde styr p\xE5 dit forbrug.",
        "Klik på armbåndet for at tanke op, administrere vouchers, se historik og afmelde auto optankning.": "Klik p\xE5 armb\xE5ndet for at tanke op, administrere vouchers, se historik og afmelde autooptankning.",
        "Rediger profil": "Redigér profil",
        "FAQ": "FAQ",
        "Gem": "Gem",
        "Opret bruger": "Opret bruger",
        "Braclet": "Armb\xE5nd",
        "Add braclet": "Tilknyt armb\xE5nd",
        "Connect bracelet": "Tilknyt armb\xE5nd",
        "Billetter": "Vouchers",
        "Del billetter": "Del voucher",
        "Historik": "Historik",
        "Land": "Land",
        "Chooes dit land": "V\xE6lg dit land",
        "Serienummer": "Serienummer (typisk 6 tal)",
        "Kontrolkode": "Kontrolkode (typisk 4 bogstaver)",
        "Tilknyt" : "Tilknyt",
        "Navn": "For- og efternavn",
        "Telefonnummer": "Telefonnummer",
        "Bekræft password": "Bekr\xE6ft adgangskode (Min. 8 tegn)",
        "Navn - fx. Alexanders armbånd": "Navn - fx. Alexanders armb\xE5nd",
        "Afmeld auto optankning": "Afmeld autooptankning",
        "Tank op": "Tank op",
        "Vilkår og betingelser": "Vilk\xE5r og betingelser",
        "Se vilkår og betingelser": "Se vilk\xE5r og betingelser",
        "Tilbage": "G\xE5 tilbage",
        "Beløb": "Bel\xF8b",
        "Auto optankning tilmeldt": "Autooptankning tilmeldt",
        "Accepter": "Acceptér",
        "betingelser": "betingelser",
        "Læs betingelser": "L\xE6s betingelserne her",
        "Klik på armbåndet for at tanke op, administrere billetter, se historik og afmelde auto optankning.": "Klik p\xE5 armb\xE5ndet for at tanke op, administrere dine vouchers og se din historik",
        "Jeg ønsker autooptankning, når saldo når 100,-": "Jeg \xF8nsker autooptankning, n\xE5r min saldo n\xE5r 100,-",
        "Beløb til auto optankning": "Bel\xF8b til autooptankning",
        "Er du sikker?": "Er du sikker?",
        "Ja, jeg er sikker": "Ja, jeg er sikker",
        "Fortryd": "Fortryd",
        "Er du sikker på at du ønsker at forlade siden uden at gemme?": "Er du sikker p\xE5, at du \xF8nsker at forlade siden uden at gemme?",
        "Language": "Sprog",
        "Loginfejl":"Tjek den indtastede email og adgangskode",
        "Kommunikationsfejl":"Fejl under kommunikation med serveren",
        "Vælg landekode": "Landekode",
        "Vælg armbånd": "V\xE6lg armb\xE5nd",
        "Beløbet du ønsker at tanke op": "Bel\xF8bet du \xF8nsker at tanke op",
        "Beløbet du ønsker der skal autooptankes": "Bel\xF8bet du \xF8nsker der skal autooptankes",
        "Serial help": "Indtast serienummeret som findes bag p\xE5 dit armb\xE5nd",
        "Control help": "Indtast kontrolkoden som findes bag p\xE5 dit armb\xE5nd",
        "Valgfrit navn til dit armbånd":"Indtast et valgfrit navn til dit armb\xE5nd",
        "Du skal bruge din email som login.": "Du skal bruge din email som login",
        "Du har ingen billetter på dette armbånd": "Du har ingen vouchers p\xE5 dette armb\xE5nd",
        "Billet": "Voucher",
        "balance": "Balance",
        "Optankning": "Optankning",
        "Ingen historik fundet for dette armbånd": "Ingen historik tilg\xE6ngelig",
        "Alder": "Alder",
        "Afventer svar på betaling": "Afventer svar p\xE5 betaling",
        "Min.": "Min.",
        "Der er opstået en fejl og betalingen kunne ikke sættes igang.": "Der er opst\xE5et en fejl og betalingen kunne ikke s\xE6ttes igang. N\xE5r du har fors\xF8gt at gennemf\xF8re en betaling, kan der g\xE5 optil 10 min. f\xF8r du kan pr\xF8ve igen.",
        "Afmeld ejerskab af armbånd":"Afmeld ejerskab af armb\xE5nd",
        "Fjern tilknytning": "Fjern tilknytning",
        "Fjern armbånd": "Fjern armb\xE5nd",
        "Er du sikker på at du vil fjerne tilknytning til armbånd?": "Er du sikker p\xE5, at du vil fjerne dette armb\xE5nd?",
        "Jeg ønsker saldo skal blive på mit armbånd efter jeg fraknytter det.":"Saldoen skal blive p\xE5 mit armb\xE5nd",
        "Kunne ikke framelde autoopfyld.":"Kunne ikke framelde autooptankning.",
        "Ukendt fejl": "Ukendt fejl",
        "Betaling blev godkendt": "Betalingen blev godkendt",
        "Fejl, kunne ikke hente data.": "Date kunne ikke hentes.",
        "Fejl, data kunnne ikke gemmes.": "Data kunne ikke gemmes.",
        "Email felterne skal indeholde en korrekt email og være identiske.":"Emailfelterne skal indeholde et korrekt format og begge felter skal v\xE6re identiske.",
        "Passwords skal være min. 6 tegn og de skal være identiske.": "Adgangskoden skal v\xE6re min. 8 tegn og begge felter skal v\xE6re identiske.",
        "Fejl, måske forkert email?": "Tjek om du har indtastet din email korrekt?",
        "Fejl, bruger kunne ikke oprettes, tjek at alle felter er udfyldt og prøv igen.": "Brugeren kunne ikke oprettes, tjek at alle felter er udfyldt korrekt og pr\xF8v igen.",
        "Fejl, tjek at alle fejler er udfyldte og prøv igen.": "Tjek at alle felter er udfyldt og pr\xF8v igen.",
        "Passwords skal være min. 6 tegn.": "Adgangskoden skal v\xE6re min. 8 tegn.",
        "Opret dit armbånd for at se historik.": "Tilknyt dit armb\xE5nd for at se historik.",
        "Opret dit armbånd for at se billetter.": "Tilknyt dit armb\xE5nd for at se vouchers.",
        "Fejl, armbånd kunne ikke tilknyttes.": "Armb\xE5ndet kunne ikke tilknyttes.",
        "Navn er for kort": "Dit navn er for kort - det skal indeholde b\xE5de for- og efternavn",
        "Email er for kort": "Din email er for kort",
        "Adgangskode er for kort": "Din adgangskoden er for kort - min. 8 tegn",
        "Telefonnr er for kort": "Dit telefonnr. er for kort",
        "Regnr skal udfyldes": "Regnr. skal udfyldes",
        "Kontonr skal udfyldes": "Kontonr. skal udfyldes",
        "Voucher overført": "Voucher(s) overf\xF8rt",
        "Overfør billet?": "Overf\xF8r voucher?",
        "Overfør billet": "Overf\xF8r voucher",
        "Adgang": "Adgang",
        "stk.": "stk.",
        "Se herunder hvor og hvad dit armbånd giver adgang til på festivalspladsen.": "Se herunder hvor og hvad dit armb\xE5nd giver adgang til p\xE5 festivalspladsen.",
        "Antal billetter der kan overføres": "Antal vouchers der kan overf\xF8res",
        "Vælg eget armbånd eller indtast telefonnummer på modtager": "V\xE6lg at overf\xF8re til et af dine egne armb\xE5nd ELLER indtast telefonnr. p\xE5 modtager",
        "Hvem skal der overføres til?": "Hvem skal der overf\xF8res til?",
        "Angiv antal": "Angiv antal",
        "Overfør til telefonnummer": "Overf\xF8r til telefonnummer",
        "Overfør": "Overf\xF8r",
        "Billet(ter) overført": "Voucher(s) overf\xF8rt",
        "Dit telefonnummer": "Dit telefonnummer",
        "Download historik som PDF": "Send PDF med historik",
        "PDF afsendt": "PDF afsendt",
        "Detaljer": "Detaljer",
        "Reference": "Reference",
        "Type": "Type",
        "Sted": "Sted",
        "Produkter": "Produkter",
        "Betalt med": "Betalt med",
        "Ordre ID": "Ordre ID",
        "SWIFT": "Swift",
        "Husk at vælge landekode": "Husk at v\xE6lge landekode",
        "Egne armbånd": "Mine armb\xE5nd",
        "Mit armbånd": "Mit armb\xE5nd",
        "Kontooplysninger skal være identiske.": "Kontooplysninger skal v\xE6re identiske.",
        "Fejl, bruger kunne ikke oprettes, tjek at alle felter er udfyldt og prøv igen. [User creation failed - email already in use]": "Emailadressen er allerede i brug",
        "Bekræft reg. nr.":"Bekr\xE6ft reg.nr.",
        "Bekræft konto nr.": "Bekr\xE6ft kontonr.",
        "Iban skal udfyldes": "Iban skal udfyldes",
        "Swift skal udfyldes": "Swift skal udfyldes",
        "Fejl":"Fejl",
        "Enheder": "Enheder",
        "IBAN og Swift skal udfyldes.": "IBAN og Swift skal udfyldes.",
        "Reg. nr. og konto nr. skal udfyldes.": "Reg. nr. og konto nr. skal udfyldes.",
        "Oplysninger ikke angivet": "Oplysninger ikke angivet",
        "Rekvirer": "Tilbagebetaling",
        "Rekvirerings-tekst": "Opdatér reg.nr. og kontonr. her for tilbagebetaling.",
        "Oplysninger er gemt": "Dine oplysninger er gemt",
        "Delete user": "Slet bruger",
        "Are you sure you what to delete user": "Er du sikker p\xE5, du \xF8nsker at slette din bruger?",
        "User": "Bruger",
        "Error deleting user.": "Der opstod en fejl i fors\xF8get p\xE5 at slette brugeren",
        "Delete info": "Hvis din bruger slettes, kan resterende penge p\xE5 armb\xE5ndet stadigv\xE6k bruges",
        "Telefonnr er ikke et nummer": "Telefonnr. m\xE5 kun indholde tal",
        "Regnr skal vaere tal": "Regnr. skal v\xE6re tal",
        "Kontonr skal vaere tal": "Kontonr. skal v\xE6re tal",
        "Back": "G\xE5 tilbage",
        "Password with min": "Adgangskode (Min. 8 tegn)",
        "Refund type": "V\xE6lg kontotype for tilbagebetaling",
        "Full name": "For- og efternavn",
        "dk_account": "Dansk konto",
        "en_account": "Udenlandsk konto",
        "Search for voucher": "S\xF8g efter voucher",
        "No results found!": "Ingen resultater fundet!",
        "Activate auto fill up": "Tilmeld autooptankning",
        "Fill up optional amount": "Tank op valgfrit bel\xF8b",
        "Cancel": "Afmeld",
        "What does my balance consist of": "Hvad best\xE5r min saldo af?",
        "My Vouchers": "Vouchers",
        "My History": "Forbrug",
        "Fill up": "Tank op",
        "You are registered for automatic fill up": "Du er tilmeldt autooptankning",
        "Saldo": "Saldo",
        "Saldo total": "Saldo total",
        "Unit first": "Dine enheder bliver anvendt som det f\xF8rste.",
        "Unit": "Enheder",
        "No refund": "De kan ikke refunderes.",
        "The bracelet can then be linked to another user profile": "Armb\xE5ndet kan herefter knyttes til en anden brugerprofil",
        "The serial number can be found on the back of the bracelet": "Serienummeret finder du p\xE5 bagsiden af armb\xE5ndet",
        "The balance on the bracelet can then be used by a future recipient of the bracelet": "Saldoen p\xE5 armb\xE5ndet kan herefter anvendes af en kommende modtager af armb\xE5ndet",
        "Serial number": "Serienummer",
        "Add bracelet": "Tilf\xF8j et armb\xE5nd til appen",
        "Since last login": "Siden sidste login - bekr\xE6ft eller opdatér dine betalingsinformationer, s\xE5 vi er sikre p\xE5 at de er korrekte i forbindelse med tilbagebetaling",
        "Confirm info": "Bekr\xE6ft informationer",
        "Confirm": "Bekr\xE6ft",
        "Registration no. must be 4 numbers": "Reg.nr. skal v\xE6re 4 tegn",
        "Search for event": "S\xF8g efter h\xE6ndelse",
        "All": "Alle",
        "Auto fill up is happening when": "Autooptankning sker n\xE5r saldo n\xE5r 100,-",
        "Auto refill requires": "For at du kan tilmelde autooptankning, kr\xE6ver det at du s\xE6tter et éngangsbel\xF8b ind p\xE5 dit armb\xE5nd samtidigt.",
        "Insert amount to fill up": "Indtast det bel\xF8b du \xF8nsker at tanke op p\xE5 dit armb\xE5nd",
        "Activate": "Tilmeld",
        "Amount low then": "Bel\xF8bet skal v\xE6re over ",
        "Amount high then": "Bel\xF8bet skal v\xE6re under ",
        "Date": "Dato",
        "Confirm payment info": "Bekr\xE6ft dine betalingsoplysninger",
        "Unsubscribe auto refill": "Afmeld autooptankning?",
        "Are you sure you want to unsubscribe from auto refill": "Er du sikker p\xE5 at du \xF8nsker at afmelde autooptankning?",
        "Answer": "Svar",
        "Do you want to refill with your registration": "\xD8nsker du at tanke op sammen med din tilmelding?",
    },

    "EN":{
        "Login":"Sign in",
        "Log ud":"Sign out",
        "Password":"Password",
        "Email":"Email",
        "Confirm email":"Confirm email",
        "Navn":"Full name (first and last name)",
        "Telefonnr.":"Phone no.",
        "Tilbagebetaling": "Refund",
        "IBAN no.": "IBAN no.",
        "Konto til tilbagebetaling": "Refund information",
        "Land": "Country",
        "Chooes dit land": "Choose your country",
        "Allerede oprettet? Log ind her": "Already created? Sign in here",
        "Glemt password":"Forgot password?",
        "Opret ny bruger":"Create user",
        "Opret bruger": "Create user",
        "emailhelp":"Please use your email to sign in.",
        "emailicon":"Your email.",
        "Klik på armbåndet for at tilføje dit armbånd til appen, så du nemt kan tanke op og holde styr på dine transaktioner.": "Click on the wristband to add it to the app, so you can easily refill and keep track of your transactions",
        "Rediger profil": "Edit profile",
        "FAQ": "FAQ",
        "Gem": "Save",
        "Historik": "History",
        "Billetter": "Vouchers",
        "Del billetter": "Share Vouchers",
        "Braclet": "Wristband",
        "Fejl, armbånd kunne ikke tilknyttes.": "Wristband not found",
        "Add braclet": "Add Wristband",
        "Connect bracelet": "Add Wristband",
        "Tank op": "Insert money",
        "Auto optankning tilmeldt": "Auto refill",
        "Klik på armbåndet for at tanke op, administrere billetter, se historik og afmelde auto optankning.": "Click on wristband to insert money, manage your vouchers, see history",
        "Afmeld auto optankning": "Cancel auto refill",
        "Accepter": "Accept",
        "betingelser": "terms",
        "Læs betingelser": "Read terms",
        "Jeg ønsker autooptankning, når saldo når 100,-": "I wish auto refill, when my balance reaches below 100,-",
        "Beløb til auto optankning": "Amount for auto refill",
        "Beløb": "Amount",
        "Se vilkår og betingelser": "View terms and conditions",
        "Tilbage": "Go back",
        "Send password reset": "Send password reset",
        "Serienummer": "Serial no.",
        "Kontrolkode": "Control no.",
        "Navn - fx. Alexanders armbånd": "Name - fx. Alexander's wristband",
        "Er du sikker?": "Are you sure?",
        "Ja, jeg er sikker": "Yes, I am sure",
        "Fortryd": "Cancel",
        "Er du sikker på at du ønsker at forlade siden uden at gemme?": "Are you sure you want to leave without saving?",
        "Tilknyt" : "Add",
        "Indtast oplysningerne, som står bag på dit armbånd.": "Type the serial and control numbers from the back of your wristband",
        "Language": "Language",
        "Loginfejl":"Login error, check email and password",
        "Kommunikationsfejl":"Server communication error",
        "Vælg landekode": "Country code",
        "Telefonnummer": "Phone number",
        "Bekræft password": "Confirm password (Min. 8 characters)",
        "Terms and conditions": "Terms of conditions",
        "Vælg armbånd": "Wristband",
        "Beløbet du ønsker at tanke op": "The amount to fill up",
        "Beløbet du ønsker der skal autooptankes": "The amount to automatically fill up",
        "Serial help": "Type in the serial number from the back of your wristband",
        "Control help": "Type in the control code from the back of your wristband",
        "Valgfrit navn til dit armbånd":"Name your wristband",
        "Du skal bruge din email som login.": "Use your email as login",
        "Du har ingen billetter på dette armbånd": "You have no vouchers on this wristband",
        "Billet": "Voucher",
        "balance": "Balance",
        "Optankning": "Refill",
        "Ingen historik fundet for dette armbånd": "No history available",
        "Ukendt fejl": "Unknown error",
        "Betaling blev godkendt": "Payment successful",
        "Opret dit armbånd for at se historik.": "Attach wristband to see history.",
        "Opret dit armbånd for at se billetter.": "Attach wristband to see vouchers.",
        "Navn er for kort": "Your name is too short (first and last name)",
        "Email er for kort": "Your email is too short",
        "Adgangskode er for kort": "Your password is too short (Min. 8 characters)",
        "Telefonnr er for kort": "Your phone no. is too short",
        "Regnr er for kort": "Your Reg. no. is too short",
        "Kontonr er for kort": "Your account no. is too short",
        "Vouchers overført": "Vouchers transferred",
        "Overfør billet?": "Transfer vouchers?",
        "Overfør billet": "Transfer vouchers",
        "Adgang": "Access",
        "stk.": "pcs.",
        "Se herunder hvor og hvad dit armbånd giver adgang til på festivalspladsen.": "See where and what your bracelet gives access to at the festival site",
        "Antal billetter der kan overføres": "Choose no. of vouchers you want to transfer",
        "Vælg eget armbånd eller indtast telefonnummer på modtager": "Choose your bracelet or enter phone no. on the receiver",
        "Hvem skal der overføres til?": "Who do you want to transfer to?",
        "Angiv antal": "Enter amount",
        "Overfør til telefonnummer": "Transfer to phone no.",
        "Overfør": "Transfer",
        "Billet(ter) overført": "Voucher successfully transfered",
        "Dit telefonnummer": "Your phone no.",
        "Download historik som PDF": "Email PDF with history",
        "PDF afsendt": "PDF sent",
        "Detaljer": "Details",
        "Reference": "Reference",
        "Type": "Type",
        "Sted": "Place",
        "Produkter": "Products",
        "Betalt med": "Payed with",
        "Ordre ID": "Order ID",
        "SWIFT": "Swift",
        "Husk at vælge landekode": "Remember to select the country code",
        "Se": "See",
        "Egne armbånd": "My Bracelets",
        "Mit armbånd": "My Bracelet",
        "Fejl, bruger kunne ikke oprettes, tjek at alle felter er udfyldt og prøv igen.": "User could not be created! check all fields are filled and try again",
        "Kontooplysninger skal være identiske.": "Account information must be identical.",
        "Fejl, bruger kunne ikke oprettes, tjek at alle felter er udfyldt og prøv igen. [User creation failed - email already in use]": "Email already in use",
        "Konto nr.": "Account no.",
        "Reg. nr.": "Reg. no.",
        "Bekræft konto nr.": "Confirm account no.",
        "Bekræft reg. nr.": "Confirm reg. no.",
        "Email felterne skal indeholde en korrekt email og være identiske.":"The email fields must contain a correct email and be identical.",
        "Fjern armbånd": "Remove bracelet",
        "Er du sikker på at du vil fjerne tilknytning til armbånd?": "Are you sure you want to remove your bracelet from your user profil?",
        "Jeg ønsker saldo skal blive på mit armbånd efter jeg fraknytter det.":"I want balance to stay on my bracelet?",
        "Der er opstået en fejl og betalingen kunne ikke sættes igang. [Payment on bracelet already in process]": "An error has occurred and the payment could not be started. When you have tried to make a payment, it can take 10 min. before you can try again.",
        "Fjern tilknytning": "Detach bracelet",
        "Iban skal udfyldes": "Iban skal udfyldes",
        "Swift skal udfyldes": "Swift skal udfyldes",
        "Fejl":"Fejl",
        "Enheder": "Units",
        "IBAN og Swift skal udfyldes.": "IBAN og Swift skal udfyldes.",
        "Reg. nr. og konto nr. skal udfyldes.": "Reg. nr. og konto nr. skal udfyldes.",
        "Oplysninger ikke angivet": "Oplysninger ikke angivet",
        "Rekvirer": "Rekvirer",
        "Rekvirerings-tekst": "Opdater regnr. og kontonr. her for tilbagebetaling.",
        "Oplysninger er gemt": "Your information is saved",
        "Delete user": "Delete user",
        "Are you sure you what to delete user": "Are you sure you want to delete your user?",
        "User": "User",
        "Error deleting user.": "Error trying to delete your user!",
        "Delete info": "If you delete your user, the remaining money on the bracelet can still be redeemed",
        "Telefonnr er ikke et nummer": "Phone number must only contain numbers",
        "Regnr skal vaere tal": "Regnr must only contain numbers",
        "Kontonr skal vaere tal": "Kontonr must only contain numbers",
        "Back": "Go back",
        "Password with min": "Password (Min. 8 characters)",
        "Passwords skal være min. 6 tegn.": "Passwords has to be min. 8 characters.",
        "Passwords skal være min. 6 tegn og de skal være identiske.": "Passwords has to be min. 8 characters and be identical.",
        "Refund type": "Choose refund account type",
        "Full name": "First and last name",
        "dk_account": "Danish account",
        "en_account": "Foreign account",
        "Search for voucher": "Search for voucher",
        "No results found!": "No results found!",
        "Activate auto fill up": "Activate auto fill up",
        "Fill up optional amount": "Fill up optional amount",
        "Cancel": "Cancel",
        "What does my balance consist of": "What does my balance consist of?",
        "My Vouchers": "Vouchers",
        "My History": "History",
        "Fill up": "Fill up",
        "You are registered for automatic fill up": "You are registered for automatic fill up",
        "Saldo": "Balance",
        "Saldo total": "Total Balance",
        "Unit first": "Your units are used before your balance.",
        "Unit": "Units",
        "No refund": "They cannot be refunded.",
        "The bracelet can then be linked to another user profile": "The bracelet can then be linked to another user profile",
        "The serial number can be found on the back of the bracelet": "The serial number can be found on the back of the bracelet",
        "The balance on the bracelet can then be used by a future recipient of the bracelet": "The balance on the bracelet can then be used by a future recipient of the bracelet",
        "Serial number": "Serial number",
        "Add bracelet": "Add a bracelet to the app",
        "Since last login": "Since last login - confirm or update your payment information so we are sure they are correct in regards to refund",
        "Confirm info": "Confirm information",
        "Confirm": "Confirm",
        "Registration no. must be 4 numbers": "Registration no. must be 4 numbers",
        "Search for event": "Search for event",
        "All": "All",
        "Auto fill up is happening when": "Auto fill up is happening when balance reach 100,-",
        "Auto refill requires": "In order for you to activate auto fill up, you need to add a one-off amount on your bracelet at the same time.",
        "Insert amount to fill up": "Insert the amount you want to add to your bracelet",
        "Activate": "Activate",
        "Amount low then": "Amount must be higher then ",
        "Amount high then": "Amount must be lower then ",
        "Date": "Date",
        "Confirm payment info": "Confirm payment info",
        "Unsubscribe auto refill": "Unsubscribe auto refill?",
        "Are you sure you want to unsubscribe from auto refill": "Are you sure you want to unsubscribe from auto refill?",
        "Answer": "Answer",
        "Do you want to refill with your registration": "Do you want to refill with your registration?",
    }
}

export default translations;