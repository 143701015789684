<template>
<pBase ref="base" v-bind:state="state" v-bind:modal="modal">
    <cFrame v-bind:title="T('Overfør') + ' ' + ticket_name" class="pullup">
        <!--<cPg class="mt-15 mb-10">{{ T('Antal billetter der kan overføres') }}: <span class="fb">{{ max_mount }} {{ T('stk.') }}</span></cPg>-->
        <cInput v-bind:label="T('Angiv antal') + ' (Max. ' + max_mount + ')'" itype="number" v-bind:pattern="'[0-9]*'" v-bind:placeholder="T('Angiv antal') + ' (Max. ' + max_mount + ')'" v-model="amount" v-bind:help="T('Beløbet du ønsker at overføre')"></cInput>
        <cPg class="mb-10 heading grey">{{ T('Hvem skal der overføres til?') }}</cPg>
        <cPg class="mb-10">{{ T('Vælg eget armbånd eller indtast telefonnummer på modtager') }}</cPg>
        <cPg class="grey mb-10">{{ T('Egne armbånd') }}</cPg>
        <div v-for="bracelet in userBracelets" :key="bracelet.NFC" v-on:click="select_bracelet(bracelet.NFC)" class="mb-5">
            <div class="checkbox-container" v-bind:class="{ checked: bracelet.NFC == selected_bracelet }">
                <i v-if="bracelet.NFC == selected_bracelet" class="fas fa-check-square"></i>
                <i v-else class="fas fa-square"></i>
                <span>{{ bracelet.name }}</span>
            </div>
        </div>

        <div class="mt-10" v-if="!selected_bracelet">

            <cPg class="grey mt-15 mb-10">{{ T('Overfør til telefonnummer') }}</cPg>

            <cSelect v-bind:options="countries"
                    option_value="prefix" option_text="label"
                    v-bind:help="T('Vælg landekode')"
                    v-bind:size="'one-third'"
                    v-bind:selected="telpre"
					v-on:input="telpre = $event.target.value">
            </cSelect>
            <cInput :label="T('Telefonnummer')" v-bind:size="'two-third'" itype="tel" :placeholder="T('Telefonnr.')" v-model="tel" :help="T('Dit telefonnummer')" :iconhelp="T('Dit telefonnummer.')"></cInput>
        </div>

        <div>
            <cError v-if="error_msg" :class="'error-text'">{{error_msg}}</cError>
			<cActions class="tc">
				<cButton v-bind:title="T('Overfør')" v-bind:class="{ 'disabled': !trans_ready, 'primary': true, 'mt-30': true, 'mb-10': true}" v-on:click="trans_ready ? make_transfer() : false"></cButton>
				<cButton v-bind:title="T('Fortryd')" v-on:click="gotoPage(14)" class="mb-10"></cButton>
			</cActions>
        </div>

    </cFrame>
</pBase>
</template>

<script>
import config from '../ts/settings';
import { useMainStore } from '../stores/MainStore';

import cFrame from "../components/Frame.vue"
import cPg from "../components/Pg.vue"
import cError from "../components/Error.vue"
import cButton from "../components/Button.vue"
import cSelect from "../components/Select.vue"
import cInput from "../components/Input.vue"
import pBase from "../pages/Base.vue"
import cActions from "../components/Actions.vue"

const settings = config.settings

export default {
    name: "pTranferVoucher",
    components: {
        pBase,
        cFrame,
        cPg,
        cSelect,
        cInput,
        cError,
        cButton,
		cActions
    },
	setup() {
		const main = useMainStore();

		return { main };
	},
    data: function (){
		return {
			userBracelets: [],
			selected_bracelet: "",
			countries: settings.countries,
			tel: '',
			telpre: '45',
			amount:"",
			max_mount: parseInt(this.main.active_voucher.amount, 10),
			confirm_dirty: false, // enable checking for changed / dirty input fields
			in_progress: false,
            state: "normal",
            modal: "pNone",
            cname: "",
            error_msg: "",
			ticket_name: this.main.active_voucher.name
		}
	},
	mounted: function() {

		this.$refs.base.set_state("modal","mLoading");

		this.myConn.get("https://api.onlinepos.dk/api/festival_app/UserBraceletsNoHistory", {"festival-id":settings.festival_id, "session-token":this.main.sessionToken}, {}, function (r) {
			this.$refs.base.set_state("normal");
			if (r.success) {
				if(r.bracelets.length > 0){
					this.userBracelets = r.bracelets
				}
			} else {
				this.error_msg = this.T("Fejl");
			}
		}.bind(this), function (e) {
			this.error_msg = this.T("Kommunikationsfejl");
		}.bind(this));

	},
	methods: {
		select_bracelet: function (s) {
			console.log(s);
			if (this.selected_bracelet==s) {
				this.selected_bracelet="";
			} else {
				this.selected_bracelet=s;
			}
		},
		make_transfer: function () {
			var d = {
				ticket_id:this.main.active_voucher.id,
                amount:parseInt(this.amount, 10),
                NFC:this.main.active_bracelet_nfc, // (to identify wristband to transfer from – data exists on bracelet objects)
                recipient_phone: "",              // (optional – Should be in the form: ‘+45 12345678’. Country code with ‘+’ and space to separate country code from normal number. No country code: +45 as default  )
                recipient_NFC: "",                // (optional – used for transfers between bracelets belonging to the same user.)
            };
			if (this.selected_bracelet!="") {
				d.recipient_NFC = this.selected_bracelet;
			} else {
				d.recipient_phone = "+" + this.telpre + " " + this.tel;
			}

			this.in_progress=true;
			this.myConn.post("https://api.onlinepos.dk/api/festival_app/TransferVoucher", {"festival-id":settings.festival_id, "session-token":this.main.sessionToken}, d, function (r) {
				this.in_progress=false;
				
				if (r.success) {
					this.main.info_msgF(this.T("Billet(ter) overført"));
					this.gotoPage(14);
				} else {
					this.error_msg = this.T("Fejl") + " [" + r.message + "]";
					this.amount = d.amount;
					this.telpre = "45";
					this.tel = "";
				}
			}.bind(this), function (e) {
				this.error_msg=this.T("Kommunikationsfejl");
			}.bind(this));
		}
	},
	computed: {
		trans_ready: function () {
			if (this.in_progress)
			{ 
				return false; 
			}

			if (((this.telpre.length > 0 && this.tel.length > 0 && this.tel.length >= 7 && this.tel.match(/^\d+$/)) || this.selected_bracelet != "") &&
				(parseInt(this.amount, 10) > 0 && parseInt(this.amount,10) <= this.max_mount)) {
				return true;
			} else {
				return false;
			}
		}
	},
}
</script>

<style>

</style>