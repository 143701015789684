<template>
<pBase ref="base" v-bind:state="state" v-bind:modal="modal">
    <cFrame :title="T('Glemt password')" class="pullup">
        <div v-if="success_msg" :class="'success-text'">
            {{success_msg}}
            <cActions>
                <cButton v-bind:title="T('Login')" v-on:click="gotoPage(1)"></cButton>
            </cActions>
        </div>

        <div v-else>
            <cError v-if="error_msg" :class="'error-text'">{{error_msg}}</cError>
            <cInput label="Email" itype="text" placeholder="Email" v-model="email" :help="T('Du skal bruge din email som login.')" :iconhelp="T('Din email')"></cInput>
            <cActions>
                <cButton v-bind:title="T('Send password reset')" class="button primary" v-on:click="password_reset()"></cButton>
                <cButton v-bind:title="T('Opret ny bruger')" v-on:click="gotoPage(4)"></cButton>
                <cButton v-bind:title="T('Allerede oprettet? Log ind her')" v-on:click="gotoPage(1)"></cButton>
            </cActions>
        </div>
    </cFrame>
</pBase>
</template>

<script>
import settings from '../ts/settings'
import { useMainStore } from '../stores/MainStore'

import pBase from "./Base.vue"
import cFrame from "../components/Frame.vue"
import cError from "../components/Error.vue"
import cInput from "../components/Input.vue"
import cActions from "../components/Actions.vue"
import cButton from "../components/Button.vue"

export default {
    name: "pForgotPassword",
    components: {
        pBase,
        cFrame,
        cActions,
        cButton,
        cError,
        cInput,
    },
    setup() {
        const main = useMainStore();

        return { main };
    },
    data: function () {
        return {
            email: '',
            state: "normal",
            modal: "pNone",
            error_msg: '',
            success_msg: '',
        }
    },
    methods: {
        password_reset: function () {
            console.log("pw reset mail send...");

            this.$refs.base.set_state("modal","mLoading");
			var d = {email:this.email.trim()};

			this.myConn.post("https://api.onlinepos.dk/api/festival_app/PasswordResetRequest", {"festival-id":settings.settings.festival_id}, d, function (r) {
				this.$refs.base.set_state("normal");
				if (r.success) {
					console.log("success");
					console.log(r);
					this.success_msg = "Email afsendt.";
					this.error_msg = "";
				} else {
					this.error_msg=this.T("Fejl, måske forkert email?");
				}
			}.bind(this), function (r) {
				this.$refs.base.set_state("normal");
				console.log("error");
				console.log(r);
				this.error_msg=this.T("Kommunikationsfejl");
			}.bind(this));

        },
    },
}
</script>

<style>

</style>