<template>
    <p>Page not found<br><br></p>
</template>

<script>
export default {
    name: "pNotFound"
}
</script>

<style>

</style>