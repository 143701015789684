<!-- eslint-disable -->
<template>
    <pBase ref="base" v-bind:state="state" v-bind:modal="modal">
        <cFrame :title="T('Confirm info')" class="pullup">
            <cPg class="tc mb-30">{{ T('Since last login') }}</cPg>
            <cError v-if="errorMsg" :class="'error-text'">{{ errorMsg }}</cError>

            <cSelect v-bind:options="countries" option_value="prefix"
                v-bind:size="'one-third'" option_text="label"
                v-bind:selected="telpre" v-on:input="telpre = $event.target.value"
                v-bind:help="T('Vælg landekode')">
            </cSelect>
            <cInput :label="T('Telefonnummer')" class="mb-30"
                v-bind:size="'two-third'" itype="tel"
                :placeholder="T('Telefonnr.')" v-model="phoneNumber"
                :help="T('Dit telefonnummer')" :iconhelp="T('Dit telefonnummer.')">
            </cInput>

            <cSelect v-bind:options="refundOption"
                option_value="code" option_text="name"
                v-bind:help="T('Refund type')"
                v-bind:selected="refundType"
                v-on:input="refundType = $event.target.value">
            </cSelect>

            <cInput v-if="refundType == 'DK'"
                :label="T('Reg. nr.')" itype="number"
                v-on:input="checkKontonr" :placeholder="T('Reg. nr.')"
                v-model="regnr" v-bind:size="'one-third'"
                :help="T('Reg. nr. på din konto')" :iconhelp="T('Reg. nr. på din konto')">
            </cInput>
            <cInput v-if="refundType == 'DK'"
                :label="T('Konto nr.')" itype="number"
                v-on:input="checkKontonr" :placeholder="T('Konto nr.')"
                v-model="kontonr" v-bind:size="'two-third'"
                :help="T('Konto nr. på din konto')" :iconhelp="T('Konto nr. på din konto')">
            </cInput>

            <cInput v-if="refundType !== 'DK' && refundType !== ''"
                :label="T('IBAN no.')" itype="text"
                :placeholder="T('IBAN no.')" v-model="iban">
            </cInput>
            <cInput v-if="refundType !== 'DK' && refundType !== ''"
                :label="T('SWIFT')" itype="text"
                :placeholder="T('SWIFT')" v-model="swift">
            </cInput>

            <cActions class="mt-30">
                <cButton class="primary" v-bind:class="{ 'disabled': formNotComplete }" :title="T('Confirm')" v-on:click="formComplete() ? saveUserData() : false"></cButton>
            </cActions>
        </cFrame>
    </pBase>
</template>

<script>
/* eslint-disable */
import config from '../ts/settings';
import { useMainStore } from "../stores/MainStore";
import cFrame from "../components/Frame.vue";
import pBase from "./Base.vue"
import cError from "../components/Error.vue";
import cInput from "../components/Input.vue";
import cPg from "../components/Pg.vue";
import cSelect from "../components/Select.vue";
import cActions from "../components/Actions.vue";
import cButton from "../components/Button.vue";

const settings = config.settings;

export default {
    name: "pUpdateBankInfo",
    components: {
        pBase,
        cFrame,
        cError,
        cInput,
        cPg,
        cSelect,
        cActions,
        cButton
    },
    setup() {
        const main = useMainStore();

        return { main }
    },
    data: function() {
        return {
            state: "normal",
            modal: "pNone",
            countries: settings.countries,
            refundOption: [],
            errorMsg: '',
            telpre: '',
            phoneNumber: '',
            refundType: '',
            regnr: '',
            kontonr: '',
            iban: '',
            swift: '',
            kontoError: false,
            doublecheck_konto: false,
            require_konto: settings.require_konto,
			require_iban: settings.require_iban,
        }
    },
    created: function () {
        let o1 = {
			code: 'DK',
			name: this.T('dk_account')
		};
		let o2 = {
			code: 'IBAN',
			name: this.T('en_account')
		};
		this.refundOption.push(o1);
		this.refundOption.push(o2);
    },
    mounted: function() {
        this.$refs.base.set_state("modal", "mLoading");

        this.myConn.get("https://api.onlinepos.dk/api/festival_app/UserProfile", {"session-token":this.main.sessionToken, "festival-id":settings.festival_id}, {}, function (r) {
			console.log("Data loaded");

            this.$refs.base.set_state("normal");

			if (r.success) {
				console.log("success");

				this.telpre = r.mobile_countrycode;
				this.phoneNumber = r.mobile;
				this.kontonr = r.bank_account;
				this.regnr = r.bank_regnr;
				this.iban = r.bank_iban;
				this.swift = r.bank_swift;
				this.refundType = r.country;
				this.language = r.language;

				if (this.bank_swift==undefined) { this.bank_swift=""; }
				
				this.main.pageDirtyF(false);

				this.emitter.on('pageDirty', function (e) {
					console.log("DIRTY", e);
				});
			} 
            else {
				this.errorMsg=this.T("Fejl, kunne ikke hente data.");
			}
		}.bind(this), function (e) {
			this.errorMsg = this.T("Fejl, kunne ikke hente data.");
			console.log("fail");
		}.bind(this));
    },
    methods: {
        checkKontonr: function () {
			this.kontoError = false;
			if (this.doublecheck_konto) {
				// this.kontoError = this.regnr != this.regnr2;
				// this.kontoError = this.kontoError || (this.kontonr != this.kontonr2);
				
				// if (this.kontoError) {
				// 	this.errorMsg = this.T("Kontooplysninger skal være identiske.");
				// } else {
				// 	this.errorMsg = "";
				// }
			}
		},
        formError () {
            if (this.phoneNumber.length > 0 && this.phoneNumber.length <= 7) {
				return this.T("Telefonnr er for kort");
			}
			if (this.phoneNumber.length > 0 && !this.phoneNumber.match(/^\d+$/)) {
				return this.T("Telefonnr er ikke et nummer");
			}
			if (this.telpre.length == 0) {
				return this.T("Husk at vælge landekode");
			}
			if (this.regnr.length > 0 && this.regnr.length != 4 ) {
				return this.T("Registration no. must be 4 numbers");
			}
			if (this.kontonr.length > 0 && this.kontonr.length > 10) {
				return this.T("Kontonr. må max være 10 tegn");
			}
        },
        formComplete() {
            let c = true
            c = c && this.phoneNumber.length > 7
            c = c && this.telpre > 0

            if (this.refundType == "DK") {
                if (this.require_konto) {
                    if (this.regnr.length <= 3) {
                        c = false
                    }
                    if (this.kontonr.length <= 5 || this.kontonr.length > 10) {
                        c = false;
                    }
                }
            }
            else {
                if (this.require_iban) {
                    if (this.iban.length <= 2) {
                        c = false
                    }
                    if (this.swift.length <= 2) {
                        c = false
                    }
                }
            }

            return c
        },
        saveUserData() {
            this.$refs.base.set_state("modal", "mLoading")
            var d = {"bank_account":this.kontonr, "bank_regnr":this.regnr, "bank_iban":this.iban, "bank_swift":this.swift, "mobile":this.phoneNumber, "mobile_countrycode":this.telpre, "country":this.refundType };

			this.myConn.post("https://api.onlinepos.dk/api/festival_app/UpdateProfile", {"session-token":this.main.sessionToken, "festival-id":settings.festival_id}, d, function (r) {
                this.$refs.base.set_state("normal");
				if (r.success) {
					console.log("success");
					this.errorMsg = "";
					this.saved = true;
					this.main.pageDirtyF(false);
                    this.main.gotoPage(10);
				} else {
					this.errorMsg = this.T("Fejl, data kunnne ikke gemmes.") + " ["+r.message+"]";
				}
			}.bind(this), function (r) {
                this.$refs.base.set_state("normal");
				console.log("error");
				this.errorMsg = this.T("Fejl, data kunnne ikke gemmes.");
			}.bind(this));
        }
    },
    computed: {
        formNotComplete: function () {
            var e = this.formError();
            this.errorMsg = e;
            return !this.formComplete();
        }
    }
}
</script>