<template>
    <button class="button">{{ title }}</button>
</template>

<script>
export default {
    name: "cButton",
    props: ['title'],
}
</script>

<style>

</style>