<template>
<pBase ref="base" v-bind:state="state" v-bind:modal="modal">
    <cFrame v-bind:title="T('Fjern armbånd')" class="pullup">
        <cPg class="tc">{{ T('Er du sikker på at du vil fjerne tilknytning til armbånd?') }}</cPg>
		<cPg class="tc grey text-small pb-15">{{ T('The bracelet can then be linked to another user profile') }}</cPg>

        <cBracelet v-bind:serial="serial" v-bind:control="T('Serial number')"></cBracelet>
		<cPg class="tc grey text-small">{{ T('The serial number can be found on the back of the bracelet') }}</cPg>

        <cError v-if="error_msg" :class="'error-text'">{{error_msg}}</cError>

        <cCheckbox class="mt-30" v-on:change="change_include" v-bind:cvalue="'include_balance'" v-bind:cname="'include_balance'" ><span>{{ T('Jeg ønsker saldo skal blive på mit armbånd efter jeg fraknytter det.') }}</span></cCheckbox>
		<cPg class="ml-26 grey text-small">{{ T('The balance on the bracelet can then be used by a future recipient of the bracelet') }}</cPg>
        <cActions>
            <cButton v-bind:title="T('Fjern armbånd')" class="primary" v-on:click="removeBracelet()"></cButton>
            <cButton v-bind:title="T('Fortryd')" v-on:click="gotoPage(10)"></cButton>
        </cActions>

    </cFrame>
</pBase>
</template>

<script>
/* eslint-disable */
import settings from '../ts/settings';
import { useMainStore } from '../stores/MainStore';

import cFrame from "../components/Frame.vue"
import cPg from "../components/Pg.vue"
import cError from "../components/Error.vue"
import cBracelet from "../components/Bracelet.vue"
import cActions from "../components/Actions.vue"
import cButton from "../components/Button.vue"
import cCheckbox from "../components/Checkbox.vue"
import pBase from "../pages/Base.vue"

export default {
    name: "pRemoveBracelet",
    components: {
        cFrame,
        cPg,
        cBracelet,
        cError,
        cCheckbox,
        cActions,
        cButton,
        pBase
	},
	setup() {
		const main = useMainStore();

		return { main };
	},
    data: function () {
        return {
            serial: '',
            confirm_dirty: true,
            include_balance: false,
            state: "normal",
            modal: "pNone",
            error_msg: "",
        }
    },
	methods: {
		removeBracelet: function () {
			this.$refs.base.set_state("modal","mLoading");

			var d = {serial_number: this.serial, include_balance: this.include_balance};

			this.myConn.post("https://api.onlinepos.dk/api/festival_app/ReleaseUserBracelet", {"festival-id":settings.settings.festival_id, "session-token":this.main.sessionToken}, d, function (r) {
				console.log("OK");
				this.$refs.base.set_state("normal");
				if (r.success) {
					console.log("SUCCESS");
					this.main.gotoPage(10);
					this.main.active_bracelet_amountF(0);
					this.main.active_bracelet_amount2F(0);
				} else {
					this.error_msg=this.T("Fejl, armbånd kunne ikke fjernes.");
					this.$refs.base.set_state("normal");
				}
			}.bind(this), function (e) {
				this.error_msg=this.T("Kommunikationsfejl");
				this.$refs.base.set_state("normal");
			}.bind(this));

		},
		change_include: function(v){
			this.include_balance = include_balance;
		},
	},
	mounted: function() {
		console.log("MOUNTED");
		this.serial = this.main.active_bracelet;
	},
}
</script>

<style>

</style>