<!-- eslint-disable -->
<template>
	<pBase ref="base" v-bind:state="state" v-bind:modal="modal">
        <cFrame v-for="(bc, index) in userBracelets" :key="bc.serial_number"  v-bind:title="bc.name" v-bind:class="{ 'pullup': index === 0 }">
            <cPg class="tc pb-10">{{ T('Klik på armbåndet for at tanke op, administrere billetter, se historik og afmelde auto optankning.') }}</cPg>
            <cPg  class="tc pb-10 fb" v-if="order_msg_serial == bc.serial_number && order_msg">{{ order_msg }}</cPg>
            <cError v-if="error_msg" :class="'error-text'">{{error_msg}}</cError>


            <cBracelet v-if="allow_remove_bracelet" v-bind:amount="bc.balance" v-bind:amount2="bc.giftcard"  v-on:click="toggleActions(index)" :auto="bc.auto_refill">
				<i class="fas fa-times delete" v-show="show_actions[index]" v-mobile:click="function() { gotoPage(20); }"></i>
			</cBracelet>

            <cBracelet v-else v-bind:amount="bc.balance"  v-bind:amount2="bc.giftcard" v-on:click="toggleActions(index)" :auto="bc.auto_refill">
				<i class="fas fa-times" v-show="show_actions[index]"></i>
			</cBracelet>

			<div class="tc" v-show="show_actions[index]">
				<cButton v-bind:title="T('What does my balance consist of')" id="open-balance-dialog" @click="showBalanceInfo"></cButton>
			</div>

			<hr v-show="show_actions[index]">

			<ion-grid v-show="show_actions[index]">
				<ion-row>
					<ion-col>
						<cUl v-if="bc.auto_refill" class="list history mt-15">
							<li class="listitem-button">
								<div class="center">
									<p>{{ T('Auto optankning tilmeldt') }}</p>
									<p class="meta">Der optankes {{ autoFillAmount(bc.auto_refill_amount) }}.-</p>
								</div>
								<div class="right my-pointer" @click="showConfirmAutoCancel">
									<p>{{ T('Cancel') }}</p>
								</div>
							</li>
						</cUl>
						<ion-button v-else class="clear-button" expand="block" @click="goToAutoFillUp()">{{ T('Activate auto fill up') }}</ion-button>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col>
						<ion-button class="img-button" expand="block" @click="goToFillUp('')">{{ T('Fill up optional amount') }}</ion-button>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col>
						<cFillUpButton v-bind:amount="500" @click="goToFillUp('500')"/>
					</ion-col>

					<ion-col>
						<cFillUpButton v-bind:amount="1000" @click="goToFillUp('1000')"/>
					</ion-col>

					<ion-col>
						<cFillUpButton v-bind:amount="2000" @click="goToFillUp('2000')"/>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col size="6">
						<ion-button class="color-secondary" expand="block" @click="gotoPage(14)">
							<ion-icon slot="start" :icon="ticket"></ion-icon>
							{{ T('My Vouchers') }}
						</ion-button>
					</ion-col>
					<ion-col size="6">
						<ion-button class="color-secondary" expand="block" @click="gotoPage(13)">
							<ion-icon slot="start" :icon="podium"></ion-icon>
							{{ T('Historik') }}
						</ion-button>
					</ion-col>
				</ion-row>
			</ion-grid>

            <!-- <cActions v-show="show_actions[index]">
                <cButton v-bind:title="T('Tank op')" class="primary mb-0"    v-mobile:click="function() { gotoPage(15); }"></cButton>
                <cButton v-bind:title="T('Billetter')" class="primary mb-0"  v-mobile:click="function() { gotoPage(14); }"></cButton>
                <cButton v-bind:title="T('Historik')" class="primary "       v-mobile:click="function() { gotoPage(13); }"></cButton>
                <cButton v-if="bc.auto_refill" v-bind:title="T('Afmeld auto optankning')" class="" v-mobile:click="function() { removeAutoRefill(); }"></cButton>
            </cActions> -->
        </cFrame>

        <cFrame v-if="allow_add" v-bind:class="(userBracelets.length > 0)? 'mt-15':'pullup'">
			<div class="tc my-pointer mb-15" @click="gotoPage(11)">
				<cPg><i class="fas fa-plus-circle add-bracelet pr-5"></i><span class="va-s">{{ T('Add bracelet') }}</span></cPg>
			</div>
        </cFrame>
    </pBase>
</template>

<script>
/* eslint-disable */
import config from '../ts/settings';
import { useMainStore } from '../stores/MainStore';
import {
    IonModal,
    IonTitle,
    IonItem,
    IonList,
    IonLabel,
	IonButton,
	IonIcon,
	IonCol, IonGrid, IonRow,
} from '@ionic/vue';
import cFrame from "../components/Frame.vue"
import cPg from "../components/Pg.vue"
import cError from "../components/Error.vue"
import cBracelet from "../components/Bracelet.vue"
import cActions from "../components/Actions.vue"
import cButton from "../components/Button.vue"
import cFillUpButton from "../components/FillUpButton.vue"
import pBase from "../pages/Base.vue"
import cUl from "../components/Ul.vue"
import { ref } from '@vue/reactivity';
import { ticket, podium } from 'ionicons/icons';
//import { Keyboard } from '@capacitor/keyboard';

const settings = config.settings

export default {
    name: "pBracelets",
    components: {
        cFrame,
        cPg,
        cError,
        cBracelet,
        cActions,
        cButton,
        pBase,
		cFillUpButton,
		cUl,
		IonTitle,
		IonItem,
		IonList,
		IonLabel,
		IonModal,
		IonButton,
		IonIcon,
		IonCol,
		IonGrid,
		IonRow,
	},
	setup() {
		const main = useMainStore();

		return { main, ticket, podium };
	},
    data: function () {
      return {
            userBracelets: [], // ["Alexanders armbånd","Martins"],
            show_actions: [], // [false, false],
            confirm_dirty: false, // enable checking for changed / dirty input fields
            state: "normal",
            modal: "pNone",
            error_msg: "",
            order_msg: "",
            order_msg_serial: "",
            allow_add: settings.allow_multiple_bracelets,
            allow_remove_bracelet: settings.allow_remove_bracelet,
       }
	},
	mounted: function() {
		// if (Capacitor.platform !== "web") {
			//Keyboard.hide();
		// }

		this.$refs.base.set_state("modal","mLoading");

		this.getBraceletData();

		if (this.main.active_order.order_id!="0") {

			console.log("Active order found", this.main.active_order.order_id);
			var d = {order_id:this.main.active_order.order_id};

			var alder_minutes = Math.floor((Date.now()-this.main.active_order.datetime)/1000/60);
			console.log("Age min.:",alder_minutes);

			this.myConn.get("https://api.onlinepos.dk/api/festival_app/IsPaymentCompleted", {"festival-id":settings.festival_id, "session-token":this.main.sessionToken}, d, function (r) {
				console.log("OK");
				
				this.order_msg = "";
				this.order_msg_serial = this.main.active_order.serial_number;
				if (r.status=="waiting") {
					this.order_msg=this.T("Afventer svar på betaling") + " (" + this.T("Alder") +" : "+ alder_minutes + this.T("Min.") + ") [ordre id "+this.main.active_order.order_id+"]";
					if (alder_minutes>10) {
						this.main.active_orderF({order_id:"0", serial_number:"", datetime: 0});
						this.order_msg+=this.T("Opgiver at vente på svar fra ordren.");
					}
				}
				if (r.status=="failed") {
					this.order_msg=this.T("Betaling mislykkedes") + " [ordre id " + this.main.active_order.order_id+"]";
					this.main.$emit("active_order", {order_id:"0", serial_number:"", datetime: 0});
				}
				if (r.status=="completed") {
					this.order_msg=this.T("Betaling blev godkendt") + " [ordre id " + this.main.active_order.order_id+"]";
					this.main.active_orderF({order_id:"0", serial_number:"", datetime: 0});
				}
                if (r.status=="success") {
					this.order_msg=this.T("Betaling blev godkendt") + " [ordre id " + this.main.active_order.order_id+"]";
					this.main.active_orderF({order_id:"0", serial_number:"", datetime: 0});
				}
                if (r.status=="timeout") {
					this.order_msg=this.T("Betalings timeout, betaling er måske gennemført") + " [ordre id " + this.main.active_order.order_id+"]";
					this.main.active_orderF({order_id:"0", serial_number:"", datetime: 0});
				}
				if (!r.success && r.message!="") {
					this.order_msg=this.T("Ukendt fejl") + " ["+r.message+"] [ordre id " + this.main.active_order.order_id+"]";
					this.main.active_orderF({order_id:"0", serial_number:"", datetime: 0});

				}

				setTimeout(() => {
					this.order_msg = "";
				}, 3000);
			}.bind(this), function (e) {
				console.log(e);
			}.bind(this));

		}

	},
	methods: {
		// outputAmount(amount) {
        //     if(amount !== undefined){
        //         return amount.replace('.', ',');
        //     }
        //     return 0;
        // },
		// getTotalAmount(amount1, amount2) {
		// 	if (isNaN(amount1)) {
		// 		amount1 = 0;
		// 	}

		// 	if (isNaN(amount2)) {
		// 		amount2 = 0;
		// 	}
		// 	let total = Number(amount1) + Number(amount2);
		// 	return this.outputAmount(total.toFixed(2));
		// },
		toggleActions: function (index) {
			this.main.active_braceletF(this.userBracelets[index].serial_number);
			this.main.active_bracelet_nfcF(this.userBracelets[index].NFC);
			this.main.active_bracelet_amountF(this.userBracelets[index].balance);
			this.main.active_bracelet_amount2F(this.userBracelets[index].giftcard);
			let newValue = !this.show_actions[index];
			this.show_actions = new Array(this.show_actions.length).fill(false);
			this.show_actions[index] = newValue;
			
		},
		getBraceletData: function () {
			this.myConn.get("https://api.onlinepos.dk/api/festival_app/UserBraceletsShort", {"festival-id":settings.festival_id, "session-token":this.main.sessionToken}, {}, function (r) {
				console.log("OK");
				console.log(r);
				this.$refs.base.set_state("normal");
				if (r.success) {
					this.userBracelets=r.bracelets

					if (this.userBracelets.length==0) {
						this.allow_add=true;
					} else {
						this.allow_add=settings.allow_multiple_bracelets;
					}

					if (this.userBracelets.length == 1) {
						this.main.active_braceletF(this.userBracelets[0].serial_number);
						this.main.active_bracelet_amountF(this.userBracelets[0].balance);
						this.main.active_bracelet_nfcF(this.userBracelets[0].NFC);
						this.main.active_bracelet_amountF(this.userBracelets[0].balance);
						this.main.active_bracelet_amount2F(this.userBracelets[0].giftcard);
						this.show_actions[0] = true;
					}

					if (this.userBracelets.length > 1) {
						//console.log(this.main.active_bracelet);
						if (this.main.active_bracelet != "") {
							for (let index = 0; index < this.userBracelets.length; index++) {
								const element = this.userBracelets[index];

								if (element.serial_number == this.main.active_bracelet) {
									this.main.active_braceletF(element.serial_number);
									this.main.active_bracelet_amountF(element.balance);
									this.main.active_bracelet_nfcF(element.NFC);
									this.main.active_bracelet_amountF(element.balance);
									this.main.active_bracelet_amount2F(element.giftcard);
									this.show_actions[index] = true;
								}
							}
						}
						else {
							this.main.active_braceletF(this.userBracelets[0].serial_number);
							this.main.active_bracelet_amountF(this.userBracelets[0].balance);
							this.main.active_bracelet_nfcF(this.userBracelets[0].NFC);
							this.main.active_bracelet_amountF(this.userBracelets[0].balance);
							this.main.active_bracelet_amount2F(this.userBracelets[0].giftcard);
							this.show_actions[0] = true;
						}
					}

					if (r.askConfirm) {
						this.main.gotoPage(60);
					}
				} else {
					this.error_msg=this.T("Fejl");
				}
			}.bind(this), function (e) {
				this.error_msg=this.T("Kommunikationsfejl");
				this.$refs.base.set_state("normal");
			}.bind(this));
		},
		removeAutoRefill: function () {

			var d = {serial_number: this.main.active_bracelet};
			console.log(d);
			this.myConn.post("https://api.onlinepos.dk/api/festival_app/AbortAutopay", {"festival-id":settings.festival_id, "session-token":this.main.sessionToken}, d, function (r) {
				if (!r.success) {
					this.error_msg=this.T("Kunne ikke framelde autoopfyld.") + " [" + r["message"] + "]";
				} else {
					this.getBraceletData();
				}
			}.bind(this), function (e) {
				console.log(e);
			}.bind(this));

		},
		dismiss() {
			document.getElementById('balance-modal').dismiss();
			//this.$refs.balanceModal.dismiss();
			//this.$refs.balanceModal.$el.dismiss();
		},
		goToFillUp(amount) {
			this.main.setFillUpStartAmount(amount);
			this.main.setFillUpShowAuto(false)
			this.main.gotoPage(15)
		},
		showBalanceInfo() {
			this.$refs.base.set_state("modal", "mBalanceInfo");
		},
		showConfirmAutoCancel() {
			this.main.gotoPage(70);
		},
		goToAutoFillUp() {
			this.main.setFillUpStartAmount("");
			this.main.setFillUpShowAuto(true)
			this.main.gotoPage(15)
		},
	
        outputAmount(a) {
            if(a !== undefined){
                return String(a).replace('.', ',');
            }
            return 0;
        },
    
		autoFillAmount(fillAmount) {
			if (fillAmount == 0) return fillAmount;
			
			return this.outputAmount(fillAmount / 100);
		}
	},
	computed: {
		
	},
}
</script>

<style>
	/* ion-modal#balance-modal {
		--width: fit-content;
		--min-width: 250px;
		--height: fit-content;
		--border-radius: 6px;
		--box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  	}
  
	ion-modal#balance-modal h1 {
		margin: 20px 20px 10px 20px;
	}
  
	ion-modal#balance-modal .wrapper {
		margin-bottom: 10px;
	} */

	.my-label-size {
		font-size: 14px !important;
	}
</style>