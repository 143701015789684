// import { createRouter, createWebHistory } from '@ionic/vue-router';
// import { RouteRecordRaw } from 'vue-router';
// import HomePage from '../views/HomePage.vue'
import pLogin from '../pages/Login.vue';
import pForgotPassword from '../pages/ForgotPassword.vue';
import pCreateUser from '../pages/CreateUser.vue';
import pBracelets from '../pages/Bracelets.vue';
import pAppendBracelet from '../pages/AppendBracelet.vue';
import pEditUser from '../pages/EditUser.vue';
import pTickets from '../pages/Tickets.vue';
import pHistory from '../pages/History.vue';
import pHistoryDetail from '../pages/HistoryDetail.vue';
import pFillUp from '../pages/FillUp.vue';
import pPayment from '../pages/Payment.vue';
import pFaq from '../pages/Faq.vue';
import pRemoveBracelet from '../pages/RemoveBracelet.vue';
import pTranferVoucher from '../pages/TranferVoucher.vue';
import pRequire from '../pages/Require.vue';
import pNotFound from '../pages/NotFound.vue';
import pConfirmDelete from '../pages/ConfirmDelete.vue';
import pUpdateBankInfo from '../pages/UpdateBankInfo.vue';
import pConfirmAutoCancel from '../pages/ConfirmAutoCancel.vue';

// const routes: Array<RouteRecordRaw> = [
//   {
//     path: '/',
//     redirect: '/home'
//   },
//   {
//     path: '/home',
//     name: 'Home',
//     component: HomePage
//   },
//   {
//     path: '/1',
//     name: 'Login',
//     component: pLogin
//   },
//   {
//     path: '/2',
//     name: 'Forgot Password',
//     component: pForgotPassword
//   },
//   {
//     path: '/4',
//     name: 'Create user',
//     component: pCreateUser
//   },
//   {
//     path: '/10',
//     name: 'Bracelets',
//     component: pBracelets
//   },
//   {
//     path: '/11',
//     name: 'Append Bracelet',
//     component: pAppendBracelet
//   },
//   {
//     path: '/12',
//     name: 'Edit User',
//     component: pEditUser
//   },
//   {
//     path: '/13',
//     name: 'History',
//     component: pHistory
//   },
//   {
//     path: '/14',
//     name: 'Tickets',
//     component: pTickets
//   },
//   {
//     path: '/15',
//     name: 'Fill Up',
//     component: pFillUp
//   },
//   {
//     path: '/16',
//     name: 'Payment',
//     component: pPayment
//   },
//   {
//     path: '/17',
//     name: 'Faq',
//     component: pFaq
//   },
//   {
//     path: '/18',
//     name: 'History Detail',
//     component: pHistoryDetail
//   },
//   {
//     path: '/20',
//     name: 'Remove Bracelet',
//     component: pRemoveBracelet
//   },
//   {
//     path: '/30',
//     name: 'Tranfer Voucher',
//     component: pTranferVoucher
//   },
//   {
//     path: '/40',
//     name: 'Require',
//     component: pRequire
//   }
// ]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

// export default router
import { useMainStore } from '@/stores/MainStore';
import { h } from 'vue';

const routes = {
  '1':  pLogin,
  '2':  pForgotPassword,
  '4':  pCreateUser,
  //	'5':  pages_login.pPrivacy,
  '10': pBracelets,
  '11': pAppendBracelet,
  '12': pEditUser,
  '14': pTickets,
  '13': pHistory,
  '18': pHistoryDetail,
  '15': pFillUp,
  '16': pPayment,
  '40': pRequire,
  '17': pFaq,
  '20': pRemoveBracelet,
  '30': pTranferVoucher,
  '50': pConfirmDelete,
  '60': pUpdateBankInfo,
  '70': pConfirmAutoCancel,
}

const route_rendere = {
  setup() {
    const main = useMainStore();

    return { main };
  },
	computed: {
		ViewComponent () {
			const p = this.main.page;
			return routes[p] || pNotFound;
		}
	},
	methods: {
	},
	render () {
		return h(this.ViewComponent);
//		return createElement('div', {}, [createElement(this.ViewComponent),createElement(mDirty)]);
//		return createElement(mDirty || this.ViewComponent);
	}
}

export default route_rendere;