<template>
    <div class="group" style="margin:15px 0 20px 0">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "cGroup"
}
</script>

<style>

</style>