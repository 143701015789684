<template>
    <div class="frame" v-bind:class="{ full: full }" >
        <h1 class="tc" v-if="!full">{{ title }}</h1>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "cFrame",
    props: ['title','full'],
}
</script>

<style>

</style>