<template>
    <div class="floatingInput" :class="[{isEmpty: empty}, size, {hasError: err}, inFocus]"> 
        <div class="label" v-bind:class="{ isEmpty: empty }">{{ label }}</div>
        <div class="control" v-bind:class="{ isEmpty: empty }">
            <input v-bind:class="{ isEmpty: empty }"
                    v-bind:placeholder="placeholder"
                    v-bind:type="itype"
                    v-bind:value="modelValue"
                    v-bind:pattern="pattern"
                    v-bind:maxlength="maxlength"
                    @focus="resetScroll($event)"
                    @blur="resetScroll($event)"
                    v-on:input="changeValue($event.target.value);" />
            <div v-if="iconhelp" class="icon-help" v-on:click="toggleHelp()">?</div>
            <div v-if="show_icon_help" class="help" v-bind:class="{ isEmpty: empty }">{{ help }}</div>
            <slot></slot>
            <!--<div v-if="show_icon_help" class="icon-help-text">{{ iconhelp }}</div>-->
        </div>
    </div>
</template>

<script>
import { useMainStore } from '../stores/MainStore';

export default {
    name: "cInput",
	setup() {
		const main = useMainStore();
		return { main };
	},
    data: function () {
		return {
			empty: true,
			show_icon_help: false,
			inFocus: ""
      };
    },
	created: function () {
		this.empty = this.modelValue == "";
	},
	props: ['modelValue','label','placeholder','itype', 'help', 'iconhelp','pattern','size', 'err', 'maxlength'],
	methods: {
		changeValue: function(newVal) {
			this.$emit('update:modelValue', newVal);//TODO: ??
		},
		toggleHelp: function () {
			this.show_icon_help = !this.show_icon_help;
		},
		errorCls: function (err) {
			if (err) {
				return "error";
			} else {
				return "noerror";
			}
		},
		resetScroll: function (e) { 
			this.emitter.emit('closeKeyboard')

			var body = document.body;
			if(this.IS_IPHONE){
			
				if(body.classList.contains("fixed") == true ) {
					body.classList.remove("fixed");
				}
				else{
					body.classList.add("fixed");
				}
			}

			if (e.type == "focus") {
				this.inFocus = "inFocus"
			}
			else {
				this.inFocus = ""
			}
		}
	},
	watch: {
        modelValue: function(newVal, oldVal) {
//			console.log('Prop changed: ', newVal, ' | was: ', oldVal);
			if (newVal == "") {
				this.empty = true;
			} else {
				this.empty = false;
			}
			this.main.pageDirtyF(true);
        }
    },
}
</script>

<style>

</style>