import { defineStore } from "pinia";

export const useMainStore = defineStore('main', {
    state: () => ({
        page:1,
		goingToPage:0,
		viewState:"page", // or "modal"
		sessionToken:"",
		loggedIn:false,
		menuOpen:false,
		connState:"ukendt",
		pingTime:0,
		pageDirty:false,
		pay: {amount:0, refill:0},
		active_bracelet: 0,
		active_bracelet_nfc: "",
		active_bracelet_amount: 0,
		active_bracelet_amount2: 0,
		active_order: {order_id:"0", serial_number:"", datetime: 0},
		active_voucher: "",
		info_msg:"",
		back:false,
		order:{},
		//		active_order: {order_id:"3", serial_number:"1111", datetime: Date.now() - 60*5*1000},
		history_cache:null,
		history_cache_date:null,
		simple_user_info: {},
		gridActive: true,
		fillUpStartAmount: "",
		fillUpShowAuto: false,
    }),

    actions: {
        startUp(){
            this.page = 1;
		
            if (localStorage.sessionToken !== undefined) {
                //console.log("Found sessiontoken", localStorage.sessionToken);
                this.page = 10;
                this.sessionToken = localStorage.sessionToken;
                this.loggedIn = true;
            } else {
                console.log("No sessiontoken");
                this.page = 1;
                this.loggedIn = false;
            }

            if (localStorage._restore_page !== undefined) {
                console.log("Restore page.", localStorage._restore_page);
                this.page = localStorage._restore_page;
                localStorage.removeItem("_restore_page");
            }
        },

        gotoPage(e) {
            this.menuOpen = false;
            this.page = e;
        },

        refreshPage(e) {
            //app.vue.$forceUpdate();
        },

        viewStateF(e) {
            this.viewState = e;
        },

        pageDirtyF(e) {
            this.pageDirty = e;
        },

        goingToPageF(e) {
			this.goingToPage = e;
		},

		menuOpenF(e) {
			this.menuOpen = e;
		},

		connChange(e) {
			this.connState = e;
		},

		pingTimeF(e) {
			this.pingTime = e;
		},

		payF(e) {
			this.pay = e;
		},

		active_braceletF(e) {
			this.active_bracelet = e;
		},

		active_bracelet_nfcF(e) {
			this.active_bracelet_nfc = e;
		},

		active_bracelet_amountF(e) {
			this.active_bracelet_amount = e;
		},

		active_bracelet_amount2F(e){
			this.active_bracelet_amount2 = e;
		},

		active_orderF(e){
			this.active_order = e;
		},

		active_voucherF(e) {
			this.active_voucher = e;
		},

		info_msgF(e) {
			this.info_msg = e;
		},

		orderF(e) {
			this.order = e;
		},

		backF(e) {
			this.back = e;
		},

		goBack() {
			if (this.back != false)
				this.back();
		},

		setGridActive(value) {
			this.gridActive = value;
		},

		// update(e) {
		// 	console.log("update..")
		// 	this.update()
		// },

        setToken(t) {
			localStorage.sessionToken = t;
			this.sessionToken = t;
			if (t == "") {
				this.loggedIn = false;
				localStorage.removeItem("sessionToken");
			} else {
				this.loggedIn = true;
			}
		},

		onConnectionChange: function(state) {
			console.log(state);
		},

		setSimpleUserInfo: function(e){
			console.log(e);
			this.simple_user_info = e;
		},

		setFillUpStartAmount(amount) {
			this.fillUpStartAmount = amount;
		},

		setFillUpShowAuto(showAuto) {
			this.fillUpShowAuto = showAuto;
		}
    }
})