<template>
    <cFrame v-bind:title="T('Unsubscribe auto refill')" class="pullup">
        <cPg class="tc">{{ T('Are you sure you want to unsubscribe from auto refill') }}</cPg>
        <cError v-if="error_msg" :class="'error-text'">{{error_msg}}</cError>
        <cActions class="tc mt-30"> 
            <cButton v-bind:title="T('Cancel')" class="button primary" v-on:click="closeModalOk()"></cButton>
            <cButton v-bind:title="T('Fortryd')" class="button secondary" v-on:click="closeModalCancel()"></cButton>
        </cActions>
    </cFrame>
</template>

<script>
import config from '../ts/settings';
import { useMainStore } from '../stores/MainStore';
import cFrame from "../components/Frame.vue"
import cPg from "../components/Pg.vue"
import cActions from "../components/Actions.vue"
import cButton from "../components/Button.vue"
import cError from "../components/Error.vue"

const settings = config.settings

export default {
    name: "pConfirmAutoCancel",
    components: {
        cFrame,
        cPg,
        cActions,
        cButton,
        cError,
    },
    setup() {
        const main = useMainStore();

        return { main};
    },
    data: function () {
      return {
            error_msg: "",
       }
	},
    methods: {
        closeModalOk: function () {
            var d = {serial_number: this.main.active_bracelet};

            this.myConn.post("https://api.onlinepos.dk/api/festival_app/AbortAutopay", {"festival-id":settings.festival_id, "session-token":this.main.sessionToken}, d, function (r) {
                if (!r.success) {
                    this.error_msg=this.T("Kunne ikke framelde autoopfyld.") + " [" + r["message"] + "]";
                } else {
                    this.gotoPage(10);
                }
            }.bind(this), function (e) {
                console.log(e);
                this.gotoPage(10);
            }.bind(this));
        },
        closeModalCancel: function () {
            this.gotoPage(10);
        }
    }
}
</script>