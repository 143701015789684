<template>
  <div class="select-box">
    <div class="label">{{T('Language')}}</div>
    <select v-model="clang">
        <option v-for="option in languages" v-bind:key="option.id" v-bind:value="option.id">
            {{ option.text }}
        </option>
    </select>
    <!--<div class="help">{{T('Language')}}</div>-->
  </div>
</template>

<script>
import settings from '../ts/settings';

export default {
    name: "cLanguage",
	data: function () {
		return {
			clang: "",
			languages: settings.settings.languages,
		};
    },
	created: function () {
		console.log("INITIAL LANG", this.language);
		this.clang = this.language;
	},
	props: ["language"],
	methods: {
		// changeLanguage: function(e) {
		// 	console.log("Internal changelanguage", this.clang);
		// 	this.$emit('change', this.clang);
		// },
	}
}
</script>

<style>

</style>