<template>
    <p><slot></slot></p>
</template>

<script>
export default {
    name: "cPg",
}
</script>

<style>

</style>