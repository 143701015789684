<template>
  <div class="row" v-if="showStatus" v-bind:class="connStatus">
    <div class="col-xs-8 tc "><i class="fas" v-bind:class="icon"></i> Netværk {{ connStatus }}, svartid {{ pingTime }} ms. {{ humanText() }}</div>
  </div>
</template>

<script>
    import { useMainStore } from '../stores/MainStore';

    export default {
        name: "cConnection",
        props: ["development"],
        setup() {
            const main = useMainStore();

            return { main }
        },
        data: function () {
            return {
                icon: "fa-exclamation-triangle"
            }
        },
        computed: {
            menuOpen: function() {
                return this.main.menuOpen;
            },
            connStatus: function() {
                return this.main.connState;
            },
            pingTime: function() {
                return this.main.pingTime;
            },
            showStatus: function() {
                if (this.main.pingTime > 2000 || this.main.connState != "online") {
                    return true;
                }
                else {
                    return false;
                }
            },
        },
        methods: {
            humanText: function() {
                if (this.main.connState == "online") {
                    this.icon = "fa-network-wired";
                    if (this.main.pingTime > 2000) {
                        return "Netværket er langsomt.";
                    } else {
                        return "Netværket kører godt.";
                    }
                } else {
                    this.icon = "fa-exclamation-triangle";
                    return "Netværket er nede.";
                }
            },
        }
    }
</script>

<style>

</style>