<template>
<pBase ref="base" v-bind:state="state" v-bind:modal="modal">
    <cFrame :title="T('Rediger profil')" class="pullup">

        <cError v-if="error_msg" :class="'error-text'">{{error_msg}}</cError>

        <cInput v-bind:label="T('Navn')" itype="text" placeholder="Navn" v-model="name" help="" iconhelp=""></cInput>

        <cInput v-bind:label="T('Email')"         itype="email" placeholder="Email" v-on:input="checkMails" v-bind:err="email_error" v-model="email" help="Du skal bruge din email som login." iconhelp="Din email."></cInput>
        <cInput v-bind:label="T('Confirm email')" itype="email" placeholder="Email" v-on:input="checkMails" v-bind:err="email_error" v-model="email2" help="Du skal bruge din email som login." iconhelp="Din email."></cInput>

        <cSelect v-bind:cname="T('Landekode')" v-bind:options="countries" option_value="prefix" v-bind:size="'one-third'" option_text="label" v-bind:selected="telpre" v-on:input="telpre = $event.target.value" v-bind:help="T('Vælg landekode')" ></cSelect>

        <cInput v-bind:label="T('Telefonnummer')" itype="tel" placeholder="Telefonnr." v-bind:size="'two-third'" v-model="tel" help="Dit telefonnummer" iconhelp="Dit telefonnummer."></cInput>

        <cInput v-bind:label="T('Password with min')"         itype="password" :placeholder="T('Password with min')" v-on:input="checkPasswords" v-bind:err="password_error" v-model="password" help="Ønsket password"></cInput>
        <cInput v-bind:label="T('Bekræft password')" itype="password" :placeholder="T('Bekræft password')" v-on:input="checkPasswords" v-bind:err="password_error" v-model="password2" help="Ønsket password"></cInput>


        <div v-if="display_konto">
            <cPg class="mt-30 grey">{{ T('Konto til tilbagebetaling') }}</cPg>
            <cPg class="mb-30" v-if="bank_account !== ''">{{ bank_regnr }} {{ bank_account }}</cPg>
            <cPg class="mb-30" v-if="bank_iban !== ''">IBAN: {{ bank_iban }}</cPg>
            <cPg class="mb-30" v-if="bank_swift !== ''">Swift: {{ bank_swift }}</cPg>
            <cPg class="mb-30" v-if="bank_swift == '' && bank_account == ''">{{T('Oplysninger ikke angivet')}}</cPg>
        </div>

        <cActions>
            <cButton v-if="email_error" :title="T('Gem')" class="primary disabled"></cButton>
            <cButton v-else-if="password_error" :title="T('Gem')" class="primary disabled"></cButton>
            <cButton v-else-if="not_dirty" :title="T('Gem')" class="primary disabled"></cButton>
            <cButton v-else :title="T('Gem')" class="primary" v-mobile:click="() => { save_user() }"></cButton>
			<cButton :title="T('Delete user')" class="primary" style="background-color: red;" v-mobile:click="() => { deleteUser() }"></cButton>
        </cActions>
    </cFrame>
</pBase>
</template>

<script>
import config from '../ts/settings';
import { useMainStore } from '../stores/MainStore';

import pBase from "./Base.vue"
import cFrame from "../components/Frame.vue"
import cError from "../components/Error.vue"
import cInput from "../components/Input.vue"
import cActions from "../components/Actions.vue"
import cButton from "../components/Button.vue"
import cPg from "../components/Pg.vue"
import cSelect from "../components/Select.vue"

const settings = config.settings

export default {
    name: "pEditUser",
    components: {
        pBase,
        cFrame,
        cActions,
        cButton,
        cPg,
        cInput,
        cSelect,
        cError
    },
	setup() {
		const main = useMainStore();

		return { main };
	},
    data: function () {
		return {
			name: '',
			email: '',
			email2: '',
			tel: '',
			telpre: '1234',
			password: '',
			password2: '',
			bank_account: '',
			bank_iban: '',
			bank_swift: '',
			bank_regnr: '',
			display_konto: settings.display_konto,
			country: '',
			countries: settings.countries,
			confirm_dirty: true, // enable checking for changed / dirty input fields
			state: "normal",
			modal: "pNone",
			email_error: false,
			password_error: false,
			error_msg: "",
      }
    },
	mounted: function() {
        console.log("get user data...");

        this.$refs.base.set_state("modal","mLoading");

		this.myConn.get("https://api.onlinepos.dk/api/festival_app/UserProfile", {"session-token":this.main.sessionToken, "festival-id":settings.festival_id}, {}, function (r) {
			console.log("Data loaded");
			console.log(r);
            this.$refs.base.set_state("normal");

			if (r.success) {
				console.log("success");

				this.name=r.name;
				this.email=r.email;
				this.email2=r.email;
				this.telpre=r.mobile_countrycode;
				this.tel=r.mobile;
				this.bank_account=r.bank_account;
				this.bank_regnr=r.bank_regnr;
				this.bank_iban=r.bank_iban;
				this.bank_swift=r.bank_swift;
				this.country=r.country;
				this.language=r.language;

				if (this.bank_swift==undefined) { this.bank_swift=""; }
				
				this.main.pageDirtyF(false);

				this.emitter.on('pageDirty', function (e) {
					console.log("DIRTY",e);
				});


			} else {
				this.error_msg=this.T("Fejl, kunne ikke hente data.");
			}
		}.bind(this), function (e) {
			this.error_msg=this.T("Fejl, kunne ikke hente data.");
			console.log("fail");
			console.log(e);
		}.bind(this));
	},
    methods: {
        save_user: function () {
            console.log("save user...");
            this.$refs.base.set_state("modal","mLoading");

			var d={"name":this.name.trim(), "mobile":this.tel, "mobile_countrycode":this.telpre, "email":this.email.trim(),
                "zip":"", "address":"", "city":"", "country": this.country, "language": this.language,
                "bank_account":this.bank_account, "bank_regnr":this.bank_regnr, "bank_iban":this.bank_iban };

			if (this.password!="") {
				d["password"]=this.password.trim();
			}

			console.log(d)

			this.myConn.post("https://api.onlinepos.dk/api/festival_app/UpdateProfile", {"session-token":this.main.sessionToken, "festival-id":settings.festival_id}, d, function (r) {
                this.$refs.base.set_state("normal");
				if (r.success) {
					console.log("success");
					console.log(r);
					this.error_msg="";
					this.main.pageDirtyF(false);
				} else {
					console.log(r);
					this.error_msg=this.T("Fejl, data kunnne ikke gemmes.") + " ["+r.message+"]";
				}
			}.bind(this), function (r) {
                this.$refs.base.set_state("normal");
				console.log("error");
				console.log(r);
				this.error_msg=this.T("Fejl, data kunnne ikke gemmes.");
			}.bind(this));
        },
        showModalPolicy: function () {
            this.$refs.base.set_state("modal","mPrivacy");
        },
		checkMails: function () {
			this.email  = this.email.replace(/\s/g,'');
			this.email2 = this.email2.replace(/\s/g,'');
			this.email_error = this.email != this.email2;
			this.email_error = this.email_error || !this.email.includes("@");
			this.email_error = this.email_error || !this.email.includes(".");
			this.email_error = this.email_error || this.email.length<6;
			if (this.email_error) {
				this.error_msg=this.T("Email felterne skal indeholde en korrekt email og være identiske.");
			} else {
				this.error_msg="";
			}
		},
		checkPasswords: function () {
			this.password  = this.password.replace(/\s/g,'');
			this.password2 = this.password2.replace(/\s/g,'');
			this.password_error = this.password != this.password2;
			this.password_error = this.password_error || (this.password.length!=0 && this.password.length<6);
			if (this.password_error) {
				this.error_msg=this.T("Passwords skal være min. 6 tegn og de skal være identiske.");
			} else {
				this.error_msg="";
			}
		},
		deleteUser: function() {
			var simple_user_info = {
				name: this.name,
				email: this.email
			}
			this.main.setSimpleUserInfo(simple_user_info);
			this.gotoPage(50);
		}
    },
	computed: {
		not_dirty: function () {
			return !this.main.pageDirty;
		}
	},
}
</script>

<style>

</style>