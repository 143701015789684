<!-- eslint-disable -->
<template>
<pBase ref="base" v-bind:state="state" v-bind:modal="modal">
    <cFrame :title="T('Rekvirer')" class="pullup">

        <cPg class="mt-30 grey">{{ T('Rekvirerings-tekst') }}</cPg>
        <br/><br/>

        <cError v-if="error_msg" :class="'error-text'">{{error_msg}}</cError>

        <cPg v-if="require_konto && country == 'DK'" class="mt-15 mb-15">{{ T('Reg. nr. og konto nr. skal udfyldes.') }}</cPg>
        <cPg v-if="require_iban && country != 'DK'" class="mt-15 mb-15">{{ T('IBAN og Swift skal udfyldes.') }}</cPg>

        <cInput v-if="country == 'DK'" :label="T('Reg. nr.')" itype="text" v-on:input="checkKontonr" :placeholder="T('Reg. nr.')" v-model="bank_regnr" v-bind:size="'one-third'" :help="T('Reg. nr. på din konto')" :iconhelp="T('Reg. nr. på din konto')"></cInput>
        <cInput v-if="country == 'DK'" :label="T('Konto nr.')" itype="text" v-on:input="checkKontonr" :placeholder="T('Konto nr.')" v-model="bank_kontonr" v-bind:size="'two-third'" size="two-third" :help="T('Konto nr. på din konto')" :iconhelp="T('Konto nr. på din konto')"></cInput>

        <cInput v-if="country == 'DK' && doublecheck_konto" :label="T('Bekræft reg. nr.')" itype="text" v-on:input="checkKontonr" :placeholder="T('Bekræft reg. nr.')" v-model="bank_regnr2" v-bind:size="'one-third'" :help="T('Reg. nr. på din konto')" :iconhelp="T('Reg. nr. på din konto')"></cInput>
        <cInput v-if="country == 'DK' && doublecheck_konto" :label="T('Bekræft konto nr.')" itype="text" v-on:input="checkKontonr" :placeholder="T('Bekræft konto nr.')" v-model="bank_kontonr2" v-bind:size="'two-third'" size="two-third" :help="T('Konto nr. på din konto')" :iconhelp="T('Konto nr. på din konto')"></cInput>

        <cInput v-if="country !== 'DK'" :label="T('IBAN no.')" itype="text" :placeholder="T('IBAN no.')" v-model="bank_iban" :iconhelp="T('IBAN no. for your bank account')"></cInput>
        <cInput v-if="country !== 'DK'" :label="T('SWIFT')" itype="text" :placeholder="T('SWIFT')" v-model="bank_swift" :iconhelp="T('SWIFT help')"></cInput>

        <cError v-if="error_msg" :class="'error-text'">{{error_msg}}</cError>

        <cActions v-if="!saved">
            <cButton v-if="formNotComplete" :title="T('Gem')" class="primary disabled"></cButton>
            <cButton v-else :title="T('Gem')" class="primary" v-mobile:click="() => { save_user() }"></cButton>
        </cActions>

        <cPg v-if="saved" class="mt-30 grey">{{ T('Oplysninger er gemt') }}</cPg>

    </cFrame>
</pBase>
</template>

<script>
/* eslint-disable */
import config from '../ts/settings';
import { useMainStore } from '../stores/MainStore';

import pBase from "./Base.vue"
import cFrame from "../components/Frame.vue"
import cError from "../components/Error.vue"
import cInput from "../components/Input.vue"
import cActions from "../components/Actions.vue"
import cButton from "../components/Button.vue"
import cPg from "../components/Pg.vue"

const settings = config.settings

export default {
    name: "pRequire",
    components: {
        pBase,
        cFrame,
        cActions,
        cButton,
        cError,
        cInput,
        cPg
    },
	setup() {
		const main = useMainStore();

		return { main };
	},
    data: function () {
		return {
			name: '',
			email: '',
			email2: '',
			tel: '',
			telpre: '1234',
			password: '',
			password2: '',
			bank_kontonr: '',
			bank_kontonr2: '',
			bank_iban: '',
			bank_swift: '',
			bank_regnr: '',
			bank_regnr2: '',
			display_konto: settings.display_konto,
			country: '',
			countries: settings.countries,
			confirm_dirty: true, // enable checking for changed / dirty input fields
			state: "normal",
			modal: "pNone",
			error_msg: "",
			konto_error:false,
			doublecheck_konto: settings.dblcheck_konto, 
			require_konto: settings.require_konto,
			require_iban: settings.require_iban,
			saved:false,
      }
    },
	mounted: function() {
        console.log("get user data...");

//        this.$refs.base.set_state("modal","mLoading");

		this.myConn.get("https://api.onlinepos.dk/api/festival_app/UserProfile", {"session-token":this.main.sessionToken, "festival-id":settings.festival_id}, {}, function (r) {
			console.log("Data loaded");
			//console.log(r);
//            this.$refs.base.set_state("normal");

			if (r.success) {
				console.log("success");

				this.name=r.name;
				this.email=r.email;
				this.email2=r.email;
				this.telpre=r.mobile_countrycode;
				this.tel=r.mobile;
				
				this.bank_kontonr=r.bank_account;
				this.bank_regnr=r.bank_regnr;
				
				this.bank_kontonr2=r.bank_account;
				this.bank_regnr2=r.bank_regnr;
				
				this.bank_iban=r.bank_iban;
				this.bank_swift=r.bank_swift;
				
				this.country=r.country;
				this.language=r.language;

				if (this.bank_swift==undefined) { this.bank_swift=""; }
				
				this.main.pageDirtyF(false);

				this.emitter.on('pageDirty',function (e) {
					console.log("DIRTY",e);
				});


			} else {
				this.error_msg=this.T("Fejl, kunne ikke hente data.");
			}
		}.bind(this), function (e) {
			this.error_msg=this.T("Fejl, kunne ikke hente data.");
			console.log("fail");
			console.log(e);
		}.bind(this));
	},
    methods: {
        save_user: function () {
            console.log("save user...");
            this.$refs.base.set_state("modal","mLoading");
			var d={"bank_account":this.bank_kontonr, "bank_regnr":this.bank_regnr, "bank_iban":this.bank_iban, "bank_swift":this.bank_swift };
			//console.log(d)
			this.myConn.post("https://api.onlinepos.dk/api/festival_app/UpdateProfile", {"session-token":this.main.sessionToken, "festival-id":settings.festival_id}, d, function (r) {
                this.$refs.base.set_state("normal");
				if (r.success) {
					console.log("success");
					//console.log(r);
					this.error_msg="";
					this.saved=true;
					this.main.pageDirtyF(false);
				} else {
					//console.log(r);
					this.error_msg=this.T("Fejl, data kunnne ikke gemmes.") + " ["+r.message+"]";
				}
			}.bind(this), function (r) {
                this.$refs.base.set_state("normal");
				console.log("error");
				//console.log(r);
				this.error_msg=this.T("Fejl, data kunnne ikke gemmes.");
			}.bind(this));
        },
        showModalPolicy: function () {
            this.$refs.base.set_state("modal","mPrivacy");
        },
		checkKontonr: function () {
			console.log("checkKontonr");
			this.konto_error = false;
			if (this.doublecheck_konto) {
				this.konto_error = this.bank_regnr != this.bank_regnr2;
				this.konto_error = this.konto_error || (this.bank_kontonr != this.bank_kontonr2);
				console.log(this.konto_error);
				if (this.konto_error) {
					this.error_msg=this.T("Kontooplysninger skal være identiske.");
				} else {
					this.error_msg="";
				}
				console.log(this.error_msg);
			}
		},
		formError: function () {
			console.log("formError");
			if (this.bank_regnr.length>0 && this.bank_regnr.length!=4) {
				return this.T("Registreringsnr. skal være 4 tegn");
			}
			if (this.bank_kontonr.length>0 && this.bank_kontonr.length>10) {
				return this.T("Kontonr. må max være 10 tegn");
			}
			if (this.country=="DK") {
				if (this.require_konto) {
					if (this.bank_regnr.length>0 && this.bank_regnr.length<=3) {
						return this.T("Regnr skal udfyldes");
					}
					if (this.bank_kontonr.length>0 && this.bank_kontonr.length<=5) {
						return this.T("Kontonr skal udfyldes");
					}
				}
				if (this.doublecheck_konto) {
					if (this.bank_regnr != this.bank_regnr2 || this.bank_kontonr != this.bank_kontonr2) {
						return this.T("Kontooplysninger skal være identiske.");
					}
				}
			} else {
				if (this.require_iban) {
					if (this.bank_iban.length>0 && this.bank_iban.length<=2) {
						return this.T("Iban skal udfyldes");
					}
					if (this.bank_swift.length>0 && this.bank_swift.length<=2) {
						return this.T("Swift skal udfyldes");
					}
				}
			}			
			return "";
		},
		formComplete: function () {
			var c=true;
			if (this.country=="DK") {
				if (this.require_konto) {
					if (this.bank_regnr.length<=3) {
						c=false;
					}
					if (this.bank_kontonr.length<=5) {
						c=false;
					}
					if (this.bank_kontonr.length>10) {
						c=false;
					}
				}
			} else {
				if (this.require_iban) {
					if (this.bank_iban.length<=2) {
						c=false;
					}
					if (this.bank_swift.length<=2) {
						c=false;
					}
				}
			}
			return c;
		},
		buttonClass: function () {
			return "disabled";
		}

		
    },
	computed: {
		not_dirty: function () {
			return !this.main.pageDirty;
		},
		formNotComplete: function () {
			var e = this.formError();
			this.error_msg = e;
			return !this.formComplete();
		}

	},
}
</script>

<style>

</style>