<template>
    <li class="listitem-voucher">
        <div class="center">
            <p class="meta">{{ title }}</p>
            <p>{{ outputAmount }} <span v-if="amount">{{ T('stk.') }}</span></p>
        </div>
        <div class="right" v-if="transfer">
            <p v-on:click="$emit('transfer')">{{ T('Overfør billet') }}</p>
        </div>
    </li>
</template>

<script>
export default {
    name: "cTicketListItem",
    props: ['title','amount','transfer'],
    computed: {
        outputAmount () {
            if(this.amount !== ''){
                return Math.trunc(this.amount);
            }
            else {
                return 0;
            }
        }
    }
}
</script>