const cashless_mode = "PRODUCTION";

const settings = {
	name:"Cashless Smukfest",
	festival_id:"1",
	bundle_id:"com.onlinepos.smukfest",
	allow_multiple_bracelets: true,
	allow_ticket_sharing: false,
	allow_remove_bracelet: true,
	show_saldo2:true,
	amount_min: 100,
	amount_max: 7500,
	dblcheck_konto: true,
	require_konto: true,
	require_iban: false,
	display_konto: false,  // vis konto opl. på rediger profil
	integrate_beautiful_bjarne: false,
	bjarne_user: "",
	bjarne_pw: "",
	network_timeout_ms:7000,  // disable network functions if latency is higher
	heartbeat_interval_s:60,   // how often check server connectivity / dev. reload
	ping_server:"https://api.onlinepos.dk/api/festival_app/Ping", // "http://cashless.use.dk:8080/ping/",
	pay_url:"https://smukcashless.dk/pay.php",
	theme: {
		colors: {
			background: "#F3EFE4",
			headerBackgroundColor: "#F84514",
			primary: "#027C50", //buttons, links etc.
			secondary: "#0C2330", //secondary link color
			bracelet: "#455A64",
			heading: "#757575",
		},
		layout: {
			containerPadding: "20px",
			borderRadius: "15px",
		},
		assets:{
			logo: "img/smuk-logo.png",
			headerBackground: "img/smuk-header.png",
			braceletBackground: "img/smuk-bracele.png",
			addNewBraceletBackground: "img/smuk-b-2.png",
			braceletIcon: "img/bracelet.svg",
			ticketIcon: "img/ticket-alt.svg",
		},
	},
	languages:[{id:"DA", text:"Dansk"},
			{id:"EN", text:"English"},],
	default_language:"DA",
	countries:[{prefix:"45", name:"Danmark", label:"+45 (Danmark)", code:"DK"},
					{prefix:"49", name:"Germany", label:"+49 (Germany)", code:"GE"},
					{prefix:"46", name:"Sweden",  label:"+46 (Sweden)", code:"SW"},
					{prefix:"47", name:"Norway",  label:"+47 (Norway)", code:"NO"},
						{prefix:"00", name:"Other", label:"+00 (Other)", code:"OT"},
			],
	translations: {
		"DA":{
			"Vilkår og betingelser overskrift": "Cashless - vilkår og betingelser 2024",

			"Vilkår tekst": [
				["Udbyder",	"Smukfest <br>Birkevej 20 8660 Skanderborg <br>CVR nr. 77052518 <br>Mail: info@smukfest.dk"],

				["Cashless", "Cashless er en betalingsform, der kan bruges i stedet for kontanter, når du handler på SMUKFEST 2024. Der kan indsættes et beløb på mellem 100 og 7.500 DKK på dit armbånd, evt. via automatisk optankning, hvorefter armbåndet blot kan anvendes som et almindeligt betalingskort. Af hensyn til loven om hvidvaskning af penge, er det er ikke muligt at benytte armbåndet som betalingskort uden at være oprettet som bruger på portalen eller Cashless App, da SMUKFEST til en hver tid skal kunne identificere og følge pengestrømmen. I henhold til samme lov kan der maksimalt stå kr. 7500 på din konto. Du kan let via betalingsportalen se, hvor mange penge der er brugt på dit armbånd, samt tjekke hvordan og hvor pengene er brugt. Betalingsmidlet bør opbevares forsvarligt. Skulle uheldet være ude, og dit betalingsmiddel bliver misbrugt, gælder det, som ved almindelige betalingskort, at betalingsmidlet kan spærres når som helst via betalingsportalen. Indehaveren af betalingsmidlet bærer det fulde ansvar, hvorfor SMUKFEST ikke kan holdes til ansvar for misbrug af betalingsmidlet. Dine indbetalinger sikres af Easy, som er sikkerhedsgodkendt hos NETS."],

				["Optankning og kvittering", "Der kan ikke indbetales penge eller tilmeldes automatisk optankning uden at godkende handelsbetingelserne. Når der er indbetalt penge eller en automatisk optankning gennemføres, modtages der straks en kvittering til den mailadresse, der er tilknyttet betalingsmidlet. Der kan optankes og tilmeldes automatisk optankning med følgende betalingsmidler: Dankort, VisaDankort, Visa, MasterCard. Ved indbetaling eller tilmelding til automatisk optankning accepteres betingelserne for brugen af betalingsmidlet." +
					" OBS: Hvis automatisk optankning tilmeldes på et debitkort gøres der opmærksom på, at pengene reserveres på kortet i op til 30 bank dage. Afmelding af den automatiske optankning kan ske direkte via betalingsportalen. Hvis der skiftes betalingskort undervejs, skal man blot afmelde sig automatisk optankning via portalen og efterfølgende tilmelde sig igen med det nye kort."],

				["Ombytning og spærring af armbånd", "Hvis du ønsker at ombytte eller spærre dit armbånd, skal du henvende dig i Armbåndssupport beliggende i området Sherwood ved festivalpladsen."],

				["Transaktionsgebyr og kontooversigt", "Der opkræves ikke gebyrer for betalingstransaktioner, der udføres gennem det kontantløse system. Du kan til en hver tid tjekke saldo og kontoudtog via betalingsportalen, du skal blot logge ind med de oplyste loginoplysninger. Bemærk, hvis der bruges firmakort til at lave en indbetaling pålægges indbetalingen et gebyr fra NETS/TELLER. Opdaterede priser på dette kan findes via https://nets.eu/dk-da. Indsigelse mod en optankning skal ske inden for 14 dage efter beløbet er trukket på din bankkonto på mail til info@smukfest.dk, i henhold til betalingstjenestelovens særlige indsigelsesfrister § 66."],

				["Udbetaling af indestående", "Det er muligt at få udbetalt evt. indestående beløb. Dette gøres ved en obligatorisk tilmelding til automatisk udbetaling ved oprettelse af din Cashless konto." +
					" Automatisk udbetaling: Det er obligatorisk at tilmelde sig automatisk udbetaling, når du opretter dig på Cashless App eller på Cashless Webportalen. Dette gøres ved at indtaste bankkonto oplysninger: reg. nr. og kontonummer. Disse oplysninger findes på bagsiden af dit betalingskort. Ved tilmelding til automatisk udbetaling vil et eventuelt restindestående automatisk blive udbetalt til dine angivne kontooplysninger i uge 33 2024. Bemærk! Der kan gå op til 5 bank dage fra du kan se pengene er hævet fra din app eller webportalen, til pengene er sat ind på din bankkonto igen. Benytter du en international bankkonto skal du vælge det land banken er i og indtaste Swift code og Iban nummer. Skulle der ved påbegyndelse af kalenderåret 2025 stadig være Cashless konti med indestående beløb, vil der hver måned blive trukket kr. 50 i gebyr pr. konti." +
					" Udbetaling via Webportal eller Cashless App: Hvis den automatiske udbetaling fejler kan du efterfølgende manuelt rekvirere en udbetaling. Dette gøres ved at tilgå Webportalens eller Cashless Appens udbetalingsfunktion. Udfyld formularen med dine bankoplysninger (banknavn, registrering og kontonummer) i forbindelse med udbetaling. Der Åbnes for manuelle udbetalinger i uge 33. Bemærk! Fejler den automatiske udbetaling pga. forkert indtastet kontonummer, opsagt konto eller andet pålægges slutbrugeren det gebyr som banken tager for at returnere udbetalingen. Ved fejl eller spørgsmål vedr. udbetalinger til udenlandske konti bedes du henvende dig til givemebackmymoney@smukmail.dk. Udbetaling af indestående beløb ved manuel rekvirering sker inden for 5 dage."],

				["Gyldighed", "Betalingsmidlet udløber den 18/8-2024, 7 kalenderdage efter festivalens afslutning. Det er muligt at rekvirere udbetalinger 8 måneder fra denne dato. Hvis der er indestående tilbage på dit armbånd efter d. 15. maj 2025, vil disse penge automatisk blive doneret til SMUK fonden."],

				["Fortrydelsesret", "Du kan fortryde køb med betalingsmidlet efter forbrugeraftalelovens § 17. Fortrydelsesfristen er 14 dage, fristen regnes som udgangspunkt fra den dag, købet er foretaget med betalingsmidlet."],

				["Privatlivsmeddelelse for oprettelse af en konto på Smukcashless.dk", "Udstedt af: Skanderborg Festivalklub, Birkevej 20, 8660 Skanderborg CVR: 77052518"],

				["Generelt", "Oplysningerne om køb og bestillinger gemmes for at kunne levere varen og til statistik. Har du en profil, vil dine køb blive knyttet til din profil, og oplysningerne brugt til at målrette information, tilbud og produkter i overensstemmelse med dit samtykke., jf. Databeskyttelsesforordningen art. 6(1)(b).Vi videregiver ikke dine oplysninger til andre, med mindre vi er forpligtet til det efter lovgivningen eller en retlig afgørelse, hvis der er relevant i forhold til lovovertrædelser og efterforskning heraf, eller i andre tilsvarende situationer, hvor væsentlige grunde taler for, at vi videregiver oplysningerne. Vi videregiver aldrig dine oplysninger til brug for markedsføring eller andre kommercielle formål. Når du opretter en konto, opretholder vi denne i 2 år efter oprettelsen eller dit seneste køb. Vi sletter eller anonymiserer data i årets 4. kvartal, uanset hvornår på året du har oprettet din konto eller foretaget dit seneste køb. Du kan dog altid slette din egen konto, hvorefter vi sletter eller anonymiserer alle oplysninger om dig, som vi ikke er retligt forpligtet til at opbevare. Ifølge Databeskyttelsesforordningen har du nogle rettigheder som registreret person. Du kan anmode om indsigt i, om vi behandler oplysninger om dig, samt hvilke oplysninger vi behandler, du kan få berigtiget urigtige oplysninger og suppleret ufyldestgørende oplysninger, du kan anmode om at vi sletter oplysninger eller begrænser behandlingen og du kan gøre indsigelse mod vores behandling af oplysninger om dig. Du har også ret til at klage til Datatilsynet over vores behandling af oplysninger om dig. Du kan finde yderligere informationer herom på www.datatilsynet.dk."],
			],

			"FAQ tekst": [
				["Kan jeg genbruge min Cashless-konto fra tidligere år?", "Ja, det kan du godt. Hvis du ikke kan huske passwordet, nulstiller du det bare, knytter armbåndet til – og vupti. Så er der fest"],

				["Jeg har glemt mit password, hvad gør jeg?", "Tryk på ”Glemt password” i appen eller gå til smukcashless.dk, hvor du kan nulstille dit password."],

				["Hvad gør jeg, hvis jeg har nulstillet min adgangskode men ikke har modtaget en mail?", "Kontroller om mailen er ankommet til en Spam/Rod/Junk-mappe. Hvis mailen ikke er ankommet indenfor en halv time, så kontakt supporten på cashless@smukmail.dk. Du får hurtigst og bedst hjælp, hvis du med det samme giver os dit navn, 14 cifret armbåndsnummer, telefonnummer og den korrekte e-mail."],

				["Hvad gør jeg, hvis jeg har skrevet forkert e-mail ved Cashless-oprettelsen?", "Hvis du stadig er logget ind i Cashless-appen på din telefon, kan du rette din mail under ”Rediger profil”. Hvis ikke du er logget ind, så kontakt supporten på cashless@smukmail.dk, skriv dit navn, 14 cifret armbåndsnummer, telefonnummer og den korrekte e-mail."],

				["Hvad gør jeg, hvis jeg har skrevet mit navn forkert ved Cashless-oprettelsen?", "Du kan ændre dit navn under ”Rediger profil”, når du er logget ind i Cashless-appen."],

				["Kan jeg ændre det kontonummer, jeg har indtastet i forbindelse med oprettelse af min Cashless-konto?", "Nej, det er en del af din egen sikkerhed. Du kan ikke ændre på det kontonummer og registreringsnummer, du har indtastet i forbindelse med oprettelsen. Hvis det er forkert eller fejler, vil pengene blive sat tilbage på din Cashless-konto, og du vil senere have mulighed for at rekvirere en manuel udbetaling jf. handelsbetingelserne."],

				["Jeg er blevet trukket dobbelt i en bar eller restaurant på festivalpladsen, hvad gør jeg?", "Tag et billede af dit kontoudtog i Cashless-appen og send til cashless@smukmail.dk eller henvend dig til de flinke mennesker i Armbåndssupporten i Sherwood."],

				["Hvad gør jeg, hvis jeg er medhjælper og ikke har fået mine bespisningsenheder fra Memba.dk?", "Kontakt din holdformand direkte. Hvis der er sket en fejl, skal din formand kontakte formandsreceptionen i Sherwood eller skrive til memba@smukfest.dk"],

				["Jeg har indbetalt til min Cashless-konto, men pengene fremgår ikke i appen - hvad gør jeg?", "Tag et billede af din bankkonto, hvor man kan se, at beløbet hævet. Kontakt straks Armbåndssupport i Sherwood eller cashless@smukmail.dk"],

				["Hvad gør jeg, hvis jeg forsøger at indbetale, men får beskeden ”Identisk Transaktion”?", "Fejlbeskeden 'Identisk Transaktion' opstår, hvis man to gange i træk indtaster det samme beløb til overførsel. Det er en sikkerhedsforanstaltning fra Nets. Vent 10 minutter med at overføre igen, eller overfør et andet beløb (f.eks. 299 frem for 300 kr)."],

				["Jeg har tilmeldt mig automatisk optankning, og nu er der reserveret et beløb på min bankkonto, hvorfor det?", "Hvis du har tilmeldt dig automatisk optankning med et debetkort, så vil beløbet blive reserveret af din bank i op til 30 dage."],

				["Hvad gør jeg, hvis jeg er tilmeldt automatisk optankning, men der ikke tankes op?", "Automatisk optankning tanker kun op, når der foretages et køb, og din saldo dermed ryger under 50 kr. Hvis det IKKE sker, så tjek at det tilknyttede betalingskort har dækning og ikke er spærret. Forsøg eventuelt at annullere den automatiske optankning i Cashless-appen og lav en ny tilmelding til automatisk optankning. "],

				["Jeg har tilmeldt mig automatisk optankning og fortrudt, hvad gør jeg så?", "Log ind på din Cashless-konto, gå til tank op og frameld dig automatisk optankning."],

				["Hvad gør jeg, hvis jeg mister mit armbånd med penge på?", "Henvend dig i Armbåndssupport, som ligger i Sherwood, så hjælper de dig."],

				["Hvad gør jeg, hvis mit armbånd ikke virker til betaling med Cashless?", "Henvend dig i Armbåndssupport, som ligger i Sherwood, så hjælper de dig."],

				["Hvad gør jeg, hvis jeg gerne vil spærre mit armbånd?", "Henvend dig i Armbåndssupport, som ligger i Sherwood, så hjælper de dig."],

				["Jeg har videresolgt mit armbånd EFTER jeg tilknyttede det i appen og lavede en indbetaling. Hvad gør jeg?", "Hvis du blot har videresolgt dit armbånd og ikke har penge på det, så skal du bare trykke på det lille kryds i højre hjørne ud for dit armbånd i Cashless-appen. Tryk derefter på ”Fjern tilknytning”. Hvis du har solgt dit armbånd med penge på selve armbåndschippen, skal du sætte flueben i ”Jeg ønsker saldo skal blive på mit armbånd efter jeg fraknytter det”. Herefter kan du tilknytte et nyt armbånd, hvis du har sådan ét."],

				["Jeg har fjernet tilknytningen til mit armbånd ved en fejl, hvad gør jeg?", "Tilføj bare det samme armbånd, som du lige har tilknyttet. Et armbånd kan kun være tilknyttet én bruger af gangen."],

				["Hvordan får jeg udbetalt resten af mine penge efter festivalen?", "Hvis du har et restindestående, vil det automatisk blive udbetalt i uge 33 til det kontonummer og registreringsnummer, du indtastede ved oprettelsen af din Cashless-konto. Hvis du tastede forkert eller har skiftet bank midt i det hele, så skal du lige ind og trykke på en knap efter festivalen, ellers går det hele af sig selv."],

				["Hvordan får jeg udbetalt et restindestående til en udenlandsk konto?", "Hvis du har udfyldt dit IBAN nummer og SWIFT-kode, kan vi lave en udbetaling til din udenlandske konto. Hvis ikke, så kan du skrive en mail til givemebackmymoney@smukmail.dk med din mailadresse, dit serienummer på armbånd, telefonnummer samt dit IBAN-nummer og SWIFT-kode."]
			],

		},
		"EN":{
			"Vilkår og betingelser overskrift": "Cashless - Terms and Conditions 2024",

			"Vilkår tekst": [
				["Provider", "Smukfest <br>Birkevej 20 8660 Skanderborg Denmark <br>CVR number (Central Business Register): 77052518 <br>Email: info@smukfest.dk"],

				["Cashless", "Cashless is a payment system that can be used instead of cash when you shop at SMUKFEST 2024. An amount of money between 100 and 7,500 Danish kroner (DKK) can be added to your wristband, e.g. via the automatic top up service, after which the wristband can be used like a regular credit card. Due to the anti money laundering act, it is not possible to use the wristband as a payment method without registering a user account via the portal or Cashless App, since SMUKFEST has to be able to identify and follow the cashflow at any time. According to the same act, the amount on your cashless account can be up to a maximum of 7,500 Danish kroner (DKK). Via the payment portal you can easily keep track of the amount on your wristband and check how and where the money was spent. The wristband should be kept safe. If your cashless account has been misused it can be blocked like a regular credit card via the payment portal at any time. The owner of the account has the full responsibility, for which reason SMUKFEST cannot be held responsible for misuse of the means of payment. Your transfers are protected by Easy which is PCI DSS compliant."],

				["Adding Funds & Receipt", "It is not possible to add funds or register for the automatic top up service without agreeing to terms and conditions. A receipt will be send to the email address associated with the cashless account, as soon as an amount of money has been added or the automatic top up service has been used. You can add funds or register for the automatic top up service via the following means of payment: Dankort, VisaDankort, Visa and MasterCard. When funds are added or a registration has been made to the automatic top up service, you agree to the terms and conditions associated with the means of payment." +
					" NB: If the automatic top up service is registered with a debit card, the amount will be reserved on the card for up to 30 banking days. Cancellation of the automatic top up service can be done directly via the payment portal. If you wish to change payment card, just cancel the automatic top up service via the portal and register with the new card."],

				["Exchanging or Blocking of Wristband", "If you wish to exchange or block your wristband, please go to the wristband support service (Armbåndssupport) in the Sherwood area by the festival site."],

				["Transaction Fee & Account Statement", "There are no fees for payment transactions made via the cashless system. You can check the balance of your account and statement of account at any time via the payment portal, when you use the login information provided. Please notice that there is a transaction fee if you add funds via a business credit card or a card from outside the EU. Objections to automatic top up transactions must be submitted within 14 days of the transaction date via email to info@smukfest.dk."],

				["Claiming Refunds", "It is possible to claim a refund for unspent money. When setting up the Cashless account there is a mandatory registration for automatic refund." +
					" Automatic refund: It is mandatory to register for automatic refund when setting up an account via the Cashless App or the Cashless Web Portal. This is done by entering the bank account details (sort code and account number) that are on the back of your credit card. When you register for automatic refund, the unspent amount you may have leftover will be refunded automatically in week 33 2024 to the account details you have provided. NB! It can take up to 5 banking days from the money is withdrawn from your app or the web portal till it is back on your bank account. If you are using an international bank account, you will have to choose the country of the bank and enter the SWIFT code and IBAN number. If there are still Cashless accounts with funds on them by the beginning of 2025, there will be an automatic fee of 50 Danish kroner (DKK) per month per account." +
					" Refund via the Web Portal or Cashless App: If an error occurs to the automatic refund, you can claim a refund manually afterwards. Go to the Web Portal´s or Cashless App´s refund function and fill in the form with your bank details (name of bank, sort code and account number) for your refund. Manual refunds will open in week 33. NB! If an error occurs to the automatic refund because of an error in the bank details provided, termination of bank account or similar reasons, the user will have to pay the fee charged by the bank for making the refund. For questions or errors regarding refunds to foreign accounts, please contact givemebackmymoney@smukmail.dk."],

				["Validity", "The means of payment will expire on August 18th 2024, 7 calendar days after the termination of the festival. It is possible to request refunds up to 8 months from this date. If you have funds on your wristband after May 15th 2025, this money will automatically be donated to the Smuk Foundation (Smukfonden)."],

				["Right of Cancellation", "You can regret purchases made with the means of payment, according to the The Danish Consumer Protection Act § 17. The right of cancellation is 14 days, beginning from the day the purchase is made with the means of payment."],

				["Privacy Statement for creating an account on Smukcashless.dk", "Issued by: Skanderborg Festivalklub, Birkevej 20, 8660 Skanderborg, Denmark, CVR number (Central Business Register): 77052518"],

				["General Terms", "Information about purchases and orders will be saved, in order to deliver the purchases and for statistics. If you have a profile, your purchases will be added to your profile and the data will be used to target information, offers and products in accordance with your consent, cf. The General Data Protection Regulation, Article 6(1)(b). We will not pass on your data to others, unless we are legally bound to do so according to the legislation or a legal adjudication, if it is relevant in relation to vialation of the law and investigation of it, or in equivalent situations where considerable reasons call for us to pass on the information. We will never pass on your data for the use of marketing or other commercial purposes. When you create an account, we will sustain it for 2 years after the registration or your last purchase. We are deleting or anonymising data in the 4th quarter of the year, no matter what time of the year you created your account or made your last purchase. However, you can always delete your own account, after which we will delete or anonymise all the information about you that we are not legally bound to keep. According to The General Data Protection Regulation you have legal rights as a registrered person. You can request information about whether we are processing data about you, including which data, you can rectify inacurrate information and supplement insufficient information, you can request us to delete information or limit the processing and you can take exception to our processing of your data. For more information, please visit www.datatilsynet.dk."],
			],

			"FAQ tekst": [
				["Can I reuse my Cashless account from previous years?", "Yes, you can. If you can't remember your password, simply reset it, link the wristband, and voila! The party begins.s"],

				["I forgot my password. What do I do?", "Click on ”Forgot password” in the app, or visit smukcashless.dk where you can reset your password."],

				["What do I do if I reset my password but haven’t received an email?", "Check your spam folder, junk mail or similar folders. If the email hasn’t arrived within half an hour, please contact our support team on cashless@smukmail.dk. We will be able to help you faster and better if you give us your name, wristband number (14 digits), phone number and the correct email address right away."],

				["What do I do if I entered a wrong email address when I created my Cashless account?", "If you are still logged in to the Cashless app on your phone, you can edit you email address under ”Edit profile”. If you are not logged in, please contact our support team on cashless@smukmail.dk and send us your name, wristband number (14 digits), phone number and the correct email address."],

				["What do I do if I misspelled my name when I created my Cashless account?", "You can edit your name under ”Edit profile” when you are logged in to the Cashless app."],

				["Can I change the bank account number I entered when I created my Cashless account?", "No, afraid not. This is part of your own security. You can’t change the bank account number and sort code you entered when you created your account. If there is an error in the bank details you entered, the money will return to your Cashless account, and later you will be able to claim a refund manually, cf. terms and conditions."],

				["I was charged double in a bar or restaurant on the festival site. What do I do?", "Take a screenshot of your statement of account in the Cashless app and send it to cashless@smukmail.dk, or visit the friendly people in the wristband support service (Armbåndssupporten) in Sherwood."],

				["What do I do if I am a volunteer and haven’t received my funds for meals from Memba.dk?", "Contact your team leader directly. If an error occured, your team leader has to contact the team leader reception in Sherwood or write to memba@smukfest.dk"],

				["I added funds to my Cashless account but I can’t see the money in my app. What do I do?", "Take a screenshot of your bank account where you can see the transaction, and contact the wristband support service (Armbåndssupport) in Sherwood or on cashless@smukmail.dk right away."],

				["What do I do if I try to add funds to my account but get the message ”Identisk Transaktion”?", "The error message ”Identisk Transaktion” appears when you enter the same amount twice in a row. It is a safety precaution from Nets. Wait 10 minutes before you make a new transfer, or try another amount (e.g. 299 DKK instead of 300 DKK)."],

				["I registered to the automatic top up service and now an amount has been reserved on my bank account. Why?", "If you registered to automatic top up with a debit card, the amount will be reserved by your bank for up to 30 days."],

				["What do I do if I registered to the automatic top up service but the top up is not happening?", "The automatic top up service only adds money to your Cashless account, when you make a purchase and the balance of your account goes below 50 DKK. If this DOESN’T happen, please check that there’s cover for the associated credit card or that it hasn’t been blocked. You could also unsubscribe to automatic top up service in the Cashless app and make a new automatic top up registration."],

				["I registered to the automatic top up service but changed my mind. What do I do?", "Log in to you Cashless account, go to top up and unsubscribe to the automatic top up service."],

				["What do I do if I loose my wristband and there’s money on it?", "Go to the wristband support service (Armbåndssupport) in Sherwood, and they will help you."],

				["What do I do if my wristband is not working and I can’t pay with Cashless?", "Go to the wristband support service (Armbåndssupport) in Sherwood, and they will help you."],

				["What do I do if I want to block my wristband?", "Go to the wristband support service (Armbåndssupport) in Sherwood, and they will help you."],

				["I resold my wristband AFTER I connected it to the app and added funds to it. What do I do?", "If you resold your wristband and don’t have money on it, just press the small cross in the right-hand corner next to your wristband in the Cashless app. Then press ”Disconnect”. If you sold your wristband with money on the chip, tick the box ”I want the balance of my account to remain on my wristband after I disconnect it”. Then you are able connect a new wristband to your app if you have one."],

				["I disconnected my wristband by mistake. What do I do?", "Just connect it again. A wristband can only be connected to one user at a time."],

				["How do I claim a refund?", "If you have unspent money, it will be refunded automatically in week 33 to the bank account number and sort code you entered when you created your Cashless account."],

				["How do I claim a refund to a foreign bank account?", "If you have entered your IBAN number and SWIFT code, we can transfer your unspent money to your foreign account. If not, then please write an email to givemebackmymoney@smukmail.dk containing your email address, wristband serial number, phone number, IBAN number and SWIFT code."]
			],
		}
	}
};

export default {
    settings,
    cashless_mode
};