<template>
    <div class="bracelet tc" >
        <div class="bg"></div>
        <h1 v-if="amount">{{ outputAmount }}</h1>
        <cPg v-if="serial">{{ serial }}</cPg>
        <cPg v-if="control">{{ control }}</cPg>
        <!-- <cPg v-if="amount2 && show_saldo2">{{ T('Enheder') }}: {{ amount2 }}</cPg> -->
        <!-- <cPg v-if="auto === true">{{ T('Auto optankning tilmeldt') }}</cPg> -->
        <i v-if="add === 'true'" class="fas fa-plus-circle"></i>
        <slot></slot>
    </div>
</template>

<script>
import cPg from "./Pg.vue"
import config from '../ts/settings'
const settings = config.settings

export default {
    name: "cBracelet",
    computed: {
        outputAmount() {
            let a1 = this.amount;
            let a2 = this.amount2;

            if (isNaN(a1)) {
                a1 = 0;
            }

            if (isNaN(a2)) {
                a2 = 0;
            }
            let total = Number(a1) + Number(a2);
            return total.toFixed(2).replace('.', ',');
        }
    },
    components: {
        cPg
    },
    data: function () {
		return {
			show_saldo2: settings.show_saldo2
		};
    },
    props: ['amount','add','control','serial','auto','amount2'],
}
</script>

<style>

</style>