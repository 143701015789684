<template>
    <ul>
        <slot></slot>
    </ul>
</template>

<script>
export default {
    name: "cUl",
}
</script>

<style>

</style>