
// import { IonApp, IonRouterOutlet } from '@ionic/vue';
import route_rendere from './router/index';
import { defineComponent } from 'vue';
import { useMainStore } from './stores/MainStore';
import config from './ts/settings';
import cMenu from './components/Menu.vue';
import cMenuIcon from './components/MenuIcon.vue';
import cConnection from './components/Connection.vue';
import { IonMenuButton, IonButtons } from '@ionic/vue';


export default defineComponent({
  name: 'App',
  components: {
    // IonRouterOutlet,
    // IonApp,
    cConnection,
    cMenu,
    // cMenuIcon,
    IonMenuButton,
    IonButtons,
    route_rendere,
  },
  data: function () {
      return {
        development: config.cashless_mode=="DEVELOPMENT",
        handleOutsideClick: (e) => {
          e.stopPropagation()
          const menu = document.getElementById("test");
          if (menu != null && menu != undefined) {
            if (!menu.contains(e.target)) {
              this.closeMenu();
            }
          }
        }
      }
  },
  setup() {
    const main = useMainStore();

    return { main }
  },
  created() {
    this.main.startUp();
  },
  mounted() {
		var body = document.body;
		var wIh = window.innerHeight;
		if (wIh == 635 || wIh == 559 || wIh == 553){
				body.classList.add("safari-action-bar");
		}else{
			body.classList.remove("safari-action-bar");
		}
    if (body.classList.contains("scrolled") ) {
        body.classList.remove("scrolled");
    }
    
    document.addEventListener('click', this.handleOutsideClick)
    document.addEventListener('touchstart', this.handleOutsideClick)
	},
  unmounted() {
    document.removeEventListener('click', this.handleOutsideClick)
    document.removeEventListener('touchstart', this.handleOutsideClick)
  },
  methods: {
		swipeHandler: function (d) {
			if (this.main.$state.back) { 
				this.main.backF(d)
			}
		},
		closeMenu: function () {
			if(this.main.$state.menuOpen){
				this.menu_open = false;
				this.main.menuOpenF(this.menu_open);
			}
		},
		handleScroll: function (evt, el) {
			var body = document.body;
			var wIh = window.innerHeight;
			if (wIh == 635 || wIh == 559 || wIh == 553){
				body.classList.add("safari-action-bar");
			}else{
				body.classList.remove("safari-action-bar");
			}
      if (window.scrollY > 115) {
          body.classList.add("scrolled");

      }else{
        body.classList.remove("scrolled");
      }
		},
    logoClick: function () {
      if (this.main.page == 60 || this.main.page == 10 || this.main.page == 1 || this.main.viewState == "modal") {
        return;
      }

      if (this.main.loggedIn) {
        this.main.gotoPage(10) //Bracelet page
      }
      else {
        this.main.gotoPage(1) //Login page
      }
    }
	},
  computed: {
		menuOpen: function () {
			return this.main.$state.menuOpen;
		}
	},
});
