export class MyLog {
    private _maxLines: number;
    private _timeSize: number;
    private _log: Array<any>;

    constructor (maxLines, timeSize) {
        this._maxLines = maxLines;
        this._timeSize = timeSize;
        this._log = [];
    }

    log(event) {
        console.log(event);
        this._log.push([Date.now, event]);
        if (this._log.length > this._maxLines) {
            this._log = this._log.slice(this._timeSize)
        }
    }

    show(event) {
        for (let i = 0; i < this._log.length; i++) {
            console.log(i, this._log[i][1]);
        }
    }
}