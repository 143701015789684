<template>
    <div class="checkbox-container" 
    v-bind:id="cname" v-bind:name="cname" v-bind:value="cvalue" 
    v-on:click="toggleChecked();" 
    :key="cname" v-bind:class="{ checked: show_checked }">
        <i v-if="show_checked" class="fas fa-check-square"></i>
        <i v-else class="fas fa-square"></i>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "cCheckbox",
    data: function () {
		return {
			show_checked:false
		};
    },
	props: ['cname','cvalue'],
	methods: {
		toggleChecked: function () {
			this.show_checked = !this.show_checked;
			this.$emit('change', this.show_checked);
		}
	},
}
</script>

<style>

</style>