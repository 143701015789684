<template>
<pBase ref="base" v-bind:state="state" v-bind:modal="modal">
    <cFrame v-bind:title="T('Billetter')" class="pullup">
        <cSelect v-if="userBracelets.length > 0" v-bind:options="userBracelets" option_value="name" option_text="name" v-bind:selected="cname" v-on:input="cname = $event.target.value" v-bind:help="T('Vælg armbånd')"></cSelect>

		<div class="row">
			<div class="col-xs-8 col-sm-8">
				<cInput v-bind:label="T('Search for voucher')" itype="text" :placeholder="T('Search for voucher')" v-model="searchInput" help="" iconhelp=""><i v-if="searchInput" class="fas fa-times icon-search" v-on:click="clearFilter"></i></cInput>
			</div>
			<div class="col-xs-4 col-sm-4 tr">
				<svg class="p-5 icon-gray" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{'icon-active': main.$state.gridActive}" v-on:click="sortIconClick('grid')">
					<rect width="24" height="10.6667" fill="currentColor"/>
					<rect y="13.3335" width="24" height="10.6667" fill="currentColor"/>
				</svg>

				<svg class="p-5 icon-gray ml-10" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{'icon-active': !main.$state.gridActive}" v-on:click="sortIconClick('list')">
					<rect width="24" height="4" fill="currentColor"/>
					<rect y="6.6665" width="24" height="4" fill="currentColor"/>
					<rect y="20" width="24" height="4" fill="currentColor"/>
					<rect y="13.3335" width="24" height="4" fill="currentColor"/>
				</svg>
			</div>
		</div>
		
        <div class="fb tc" v-if="info_msg">{{ info_msg }}</div>
        <div v-if="userBracelets.length > 0">
            <div v-for="bracelet in userBracelets" :key="bracelet.NFC">
				<div v-if="bracelet.name == cname">
					<div v-if="main.$state.gridActive">
						<div class="row" v-if="bracelet.tickets.length > 0 || venueManagerTickets.length>0">
							<cTickets v-for="(ticket, index) in filterTickets()" :key="ticket.id" v-bind:title="ticket.name" v-bind:amount="ticket.amount" v-bind:icon="ticket.icon" v-on:transfer="transfer_click(ticket.id, ticket.amount, ticket.name)" v-bind:transfer="allow_ticket_sharing">{{ index }}</cTickets>
							<div class="tc col-xs-12"><cPg v-if="searchInput&&!filterTickets().length" class="mt-30 mb-30">{{ T('No results found!') }}</cPg></div>
							<div v-if="venueManagerTickets.length > 0" class="tc mt-30 col-xs-12">
								<h1 class="tc">{{ T('Adgang') }}</h1>
								<cPg class="tc">{{ T('Se herunder hvor og hvad dit armbånd giver adgang til på festivalspladsen.') }}</cPg>
							</div>
							<cTickets v-for="(ticket, index) in venueManagerTickets" :key="ticket.name" v-bind:title="ticket.name" amount="" icon="" v-bind:transfer="false">{{ index }}</cTickets>
						</div>
						<cPg v-else class="mt-30 mb-30">{{ T('Du har ingen billetter på dette armbånd') }}</cPg>
					</div>
					
					<div v-else>
						<cUl class="list history mt-15"  v-if="bracelet.tickets.length > 0">
							<cTicketListItem v-for="(ticket) in filterTickets()" :key="ticket.id" v-bind:title="ticket.name" v-bind:amount="ticket.amount" v-on:transfer="transfer_click(ticket.id, ticket.amount, ticket.name)" v-bind:transfer="allow_ticket_sharing"></cTicketListItem>
						</cUl>
						<cPg v-else class="mt-30 mb-30">{{ T('Du har ingen billetter på dette armbånd') }}</cPg>
						<div class="tc col-xs-12"><cPg v-if="searchInput&&!filterTickets().length" class="mt-30 mb-30">{{ T('No results found!') }}</cPg></div>
					</div>
					

					<!--<cActions class="tc" v-if="bracelet.tickets.length > 0">
					<cButton v-if="allow_ticket_sharing" v-bind:title="T('Del billetter')" class="primary mt-30" v-on:click="gotoPage(10)"></cButton>
					</cActions>-->
				</div>
            </div>
        </div>
        <div v-else class="mt-30 tc">
            <cPg>{{ T("Opret dit armbånd for at se billetter.") }}</cPg>
            <div class="">
				<cButton v-bind:title="T('Connect bracelet')" class="primary mt-30" v-mobile:click="function() { gotoPage(11); }" ></cButton>
			</div>
        </div>
    </cFrame>
</pBase>
</template>

<script>
import config from '../ts/settings';
import { useMainStore } from '../stores/MainStore';
import { ref } from "vue";
import cFrame from "../components/Frame.vue"
import cSelect from "../components/Select.vue"
import cTickets from "../components/Tickets.vue"
import cPg from "../components/Pg.vue"
import pBase from "../pages/Base.vue"
import cButton from "../components/Button.vue"
import cInput from "../components/Input.vue"
import cUl from "../components/Ul.vue"
import cTicketListItem from "../components/TicketListItem.vue"

const settings = config.settings

export default {
    name: "pTickets",
    components: {
        cFrame,
        cSelect,
        cTickets,
        cPg,
        pBase,
		cButton,
		cInput,
		cUl,
		cTicketListItem
	},
	setup() {
		const main = useMainStore();

		return { main };
	},
    data: function (){
        return {
            userBracelets: [],
            venueManagerTickets: [],
            confirm_dirty: false, // enable checking for changed / dirty input fields
            state: "normal",
            modal: "pNone",
            cname: "",
            error_msg: "",
			searchInput: "",
            info_msg: this.main.info_msg,
            allow_ticket_sharing: settings.allow_ticket_sharing
        }
	},
	mounted: function() {
		console.log("MOUNTED", this.main.info_msg);
		this.$refs.base.set_state("modal","mLoading");
		this.info_msg = this.main.info_msg;
		this.main.info_msgF("");

		this.main.backF(function () {
			this.gotoPage(10);
			this.main.backF(false);			
		}.bind(this));

		this.myConn.get("https://api.onlinepos.dk/api/festival_app/UserBraceletsNoHistory", {"festival-id":settings.festival_id, "session-token":this.main.sessionToken}, {}, function (r) {
			console.log("OK");
			console.log(r);
			console.log(this.main.active_bracelet);
			this.$refs.base.set_state("normal");
			if (r.success) {
				if(r.bracelets.length > 0){
					this.userBracelets = r.bracelets
					this.cname = this.userBracelets[0].name;
					if (this.main.active_bracelet_nfc != "") {
						for (var i=0; i<this.userBracelets.length; i++) {
							if (this.userBracelets[i].NFC == this.main.active_bracelet_nfc) {
								this.cname=this.userBracelets[i].name;
							}
						}
					}					
				}
				console.log(this.userBracelets);
			} else {
				this.error_msg=this.T("Fejl");
			}
		}.bind(this), function (e) {
			this.error_msg=this.T("Kommunikationsfejl");
		}.bind(this));

		if (settings.integrate_beautiful_bjarne) {
			console.log("NFC:", this.main.active_bracelet_nfc);
//			var nfc="04CC361AE75384"; // test
			var nfc = this.main.active_bracelet_nfc;
			this.myConn.get("https://beautifulbjarne.venuemanager.net/api/tokens/value/NFC%20UID/"+nfc+"/accessTypes", {}, {}, function (r) {
				console.log("OK B --");
				console.log(r);
				console.log("-- E OK");
				this.venueManagerTickets = r;
			}.bind(this), function (e) {
				console.log("VenueManager error:",e);
			}.bind(this), {"user": settings.bjarne_user, "password": settings.bjarne_pw});
		}
	},
	methods: {
		changeSelected: function(cname) {
			this.cname=cname;
		},

		transfer_click: function(id,amount,name) {
			console.log("TRANSFER CLICK TICKET ID",id);
			this.main.active_voucherF({id:id,amount:amount,name:name});
			this.gotoPage(30);
		},

		filterTickets: function() {
			let tickets = [];

			for (let index = 0; index < this.userBracelets.length; index++) {
				const element = this.userBracelets[index];
				if (element.name == this.cname) {
					tickets = element.tickets;
				}
			}
			
			if (this.searchInput == "") {
				return tickets;
			}
			else {
				return tickets.filter((ticket) => ticket.name.toLowerCase().includes(this.searchInput.toLowerCase()))
			}
		},

		sortIconClick(icon_type) {
			if (icon_type == "grid" && this.main.$state.gridActive) {
				return;
			}

			if (icon_type == "list" && !this.main.$state.gridActive) {
				return;
			}

			this.main.setGridActive(!this.main.$state.gridActive);
		},

		clearFilter: function() {
			this.searchInput = "";
		}
	},
}
</script>

<style>

</style>