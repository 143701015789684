<template>
    <p class="link">
        {{ text }}
        <slot></slot>
    </p>
</template>

<script>
export default {
    name: "cLink",
    props: ['text'],
}
</script>

<style>

</style>