<template>
    <li v-on:click="$emit('click')" class="menuitem">
        <i :class="icon"></i>
        <p>{{title}}</p>
        <slot></slot>
    </li>
</template>

<script>
export default {
    name: "cMenuItem",
    props: ['title','icon'],
}
</script>

<style>

</style>