<template>
    <div v-if="i_state === 'normal'"><slot></slot></div>
    <div v-else-if="i_state === 'dirty'">d</div>
    <div v-else-if="i_state === 'wait'">w</div>
    <div v-else-if="i_state === 'modal'"><component v-on:close="close" v-bind:is="i_modal"></component></div>
</template>

<script>
import { useMainStore } from '../stores/MainStore';

export default {
    name: "pBase",
    setup() {
        const main = useMainStore();

        return { main };
    },
    data: function () {
        return {
            i_state: this.state,
            i_modal: this.modal
        }
    },
    props:["state","modal"],
    methods: {
        close: function () {
            console.log("got close");
            this.set_state("normal","pNone");
            this.main.viewStateF("page");
        },

        set_state: function(n_state, n_modal) {
            this.i_state = n_state;
            this.i_modal = n_modal;

            if (n_state == "modal") {
                this.main.viewStateF("modal");
                this.emitter.on('close', this.close);
                // this.$on("close", this.close);
            } else {
                this.main.viewStateF("page");
			}
        }
    },
}
</script>

<style>

</style>