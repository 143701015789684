<template>
<cFrame v-bind:title="INFO('Vilkår og betingelser overskrift')" class="pullup">
    <div v-for="(p, index) in pitems" v-bind:key="index" class="paragraph">
        <cPg class="heading" v-html="p[0]"></cPg> <cPg v-html="p[1]"></cPg>
    </div>
    <cActions>
        <cButton v-bind:title="T('Tilbage')" class="tc" v-on:click="close"></cButton>
    </cActions>
</cFrame>
</template>

<script>
import cActions from "./Actions.vue"
import cButton from "./Button.vue"
import cPg from "./Pg.vue"
import cFrame from "./Frame.vue"

export default {
    name: "mPrivacy",
    components: {
        cActions,
        cButton,
        cPg,
        cFrame
    },
    data: function () {
        return {
            pitems:[],
        }
    },
    created: function () {
//        EventBus.$emit('viewState','modal');
//		EventBus.$on('closeModal', e => {
//			console.log("close from eventbus");
//			this.closeModal();
//		});
    },
    unmounted: function () {
//       EventBus.$emit('viewState','page');
    },
    mounted: function () {
        this.pitems = this.INFO('Vilkår tekst');
        console.log(this.pitems);
    },
    methods: {
        close: function () {
            console.log("closing...");
            this.emitter.emit('close');//TODO:
        }
    },
}
</script>

<style>

</style>