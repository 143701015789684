<template>
<pBase ref="base" v-bind:state="state" v-bind:modal="modal">
    <cFrame :title="headline" class="pullup mb-50">
		<cPg v-if="show" class=" tc pb-10">{{ T('Auto fill up is happening when') }}</cPg>
		<cError v-if="errorMsg" :class="'error-text'">{{errorMsg}}</cError>

        <cInput v-if="show" v-on:input="checkAutoFillUpAmount()" v-bind:label="T('Beløb til auto optankning') + ' (min. kr. ' + amount_min + ' & max. kr. ' +  max_amount + ')'" itype="number" v-bind:pattern="'[0-9]*'" v-bind:placeholder="T('Beløb til auto optankning')" v-model="autofill_amount" v-bind:help="T('Beløbet du ønsker der skal autooptankes')   + ' (min. kr. ' + amount_min + ' & max. kr. ' +  max_amount + ')'" v-bind:iconhelp="T('Beløbet du ønsker der skal autooptankes')" class="mt-15 mb-30"></cInput>

		<cPg v-if="show" class="mb-15 color-p tc">{{ T('Do you want to refill with your registration') }}</cPg>
		<cPg v-else class="mb-15 tc">{{ T('Insert amount to fill up') }}</cPg>
		
        <cInput v-if="show" v-on:input="checkFillUpAmountWith0Allowed()" v-bind:label="T('Beløb') + ' (max. kr. ' +  max_amount + ')'" itype="number" v-bind:pattern="'[0-9]*'" v-bind:placeholder="T('Beløb') + ' (max. kr. ' +  max_amount + ')'" v-model="amount" v-bind:help="T('Beløbet du ønsker at tanke op')" v-bind:iconhelp="T('Beløbet du ønsker at tanke op')"></cInput>
        <cInput v-else v-on:input="checkFillUpAmount()" v-bind:label="T('Beløb') + ' (min. kr. ' + amount_min + ' & max. kr. ' +  max_amount + ')'" itype="number" v-bind:pattern="'[0-9]*'" v-bind:placeholder="T('Beløb') + ' (min. kr. ' + amount_min + ' & max. kr. ' +  max_amount + ')'" v-model="amount" v-bind:help="T('Beløbet du ønsker at tanke op')" v-bind:iconhelp="T('Beløbet du ønsker at tanke op')"></cInput>

		<cCheckbox class="mt-15" style="margin-bottom:10px;" v-on:change="terms_change"><span>{{T('Accepter')}} {{T('betingelser')}}</span></cCheckbox>
        <span class="link primary" style="margin-left:27px;" v-on:click="showModalPolicy()">{{T('Læs betingelser')}}</span>

        <cActions class="tc">
            <cButton v-bind:title="continueButtonText" v-bind:class="{ 'disabled': formNotComplete, 'primary': true, 'mt-30': true }" v-on:click="formComplete() ? main.gotoPage(16) : false"></cButton>
			<cButton v-bind:title="T('Fortryd')" v-on:click="gotoPage(10)"></cButton>
        </cActions>
    </cFrame>
</pBase>
</template>

<script>
import config from '../ts/settings';
import { useMainStore } from '../stores/MainStore';
import cFrame from "../components/Frame.vue"
import cActions from "../components/Actions.vue"
import cButton from "../components/Button.vue"
import cCheckbox from "../components/Checkbox.vue"
import pBase from "../pages/Base.vue"
import cInput from "../components/Input.vue"
import cPg from "../components/Pg.vue"
import cError from "../components/Error.vue"

const settings = config.settings

export default {
    name: "pFillUp",
    components: {
        cFrame,
        cCheckbox,
        cActions,
        cButton,
        pBase,
		cInput,
		cPg,
		cError
	},
	setup() {
		const main = useMainStore();

		return { main };
	},
    data: function () {
        return {
            amount: this.main.fillUpStartAmount,
            confirm_dirty: false, // enable checking for changed / dirty input fields
            state: "normal",
            modal: "pNone",
            autofill_amount: "",
            show: this.main.fillUpShowAuto,
            terms_accept: false,
            amount_min: settings.amount_min,
            amount_max: settings.amount_max,
            current_saldo: parseInt(this.main.active_bracelet_amount),
			headline: "",
			continueButtonText: "",
			errorMsg: "",
        }
	},
	mounted: function () {
		this.main.backF(function () {
			this.gotoPage(10);
			this.main.backF(false);
		}.bind(this));

		if (this.show) {
			this.autofill_amount = "";
			this.amount = "0"
			this.headline = this.T('Activate auto fill up')
			this.continueButtonText = this.T('Activate')
		}
		else {
			this.autofill_amount = "0";
			this.headline = this.T('Tank op')
			this.continueButtonText = this.T('Tank op')
		}

		
	},
	methods: {
		show_auto: function(show){
			this.show = show;
			if (show) {
				this.autofill_amount = "";
			}
			else {
				this.autofill_amount = "0";
			}
		},
		terms_change: function(state){
			this.terms_accept=state;
		},
		showModalPolicy: function () {
            this.$refs.base.set_state("modal","mPrivacy");
        },
        showModalLoading: function () {
            this.$refs.base.set_state("modal","mLoading");

            setTimeout(function () {
                this.$refs.base.set_state("normal");
                this.main.viewStateF("page");
            }.bind(this), 3000);
        },
		formComplete: function () {
			if (this.amount.length <= 0) return false;

			let intAmount = parseInt(this.amount)
			let intAutoAmount = parseInt(this.autofill_amount)

			if (isNaN(this.current_saldo)) {
				if (intAmount > this.amount_max) return false;
			} else {
				if (intAmount > (this.amount_max - this.current_saldo)) return false;
			}

			if (this.show) {
				if (intAutoAmount < this.amount_min) return false;
				if (intAutoAmount > this.amount_max) return false;
			}
			else {
				if (intAmount < this.amount_min) return false;
			}

			if (isNaN(intAutoAmount) && !this.show) {
				this.autofill_amount = "0";
			}

			if (!this.terms_accept) return false;

			this.main.payF({amount: parseInt(this.amount), refill: parseInt(this.autofill_amount)});

			return true;
		},
		checkFillUpAmount() {
			if (parseInt(this.amount) < this.amount_min) {
				this.errorMsg = this.T('Amount low then') + this.amount_min;
				return;
			}

			if (isNaN(this.current_saldo)) {
				if (parseInt(this.amount) > this.amount_max) {
					this.errorMsg = this.T('Amount high then') + this.amount_max;
					return;
				}
			} else {
				if (parseInt(this.amount) > (this.amount_max - this.current_saldo)) {
					this.errorMsg = this.T('Amount high then') + (this.amount_max - this.current_saldo);
					return;
				}
			}

			this.errorMsg = "";
		},
		checkFillUpAmountWith0Allowed() {
			if (isNaN(this.current_saldo)) {
				if (parseInt(this.amount) > this.amount_max) {
					this.errorMsg = this.T('Amount high then') + this.amount_max;
					return;
				}
			} else {
				if (parseInt(this.amount) > (this.amount_max - this.current_saldo)) {
					this.errorMsg = this.T('Amount high then') + (this.amount_max - this.current_saldo);
					return;
				}
			}

			this.errorMsg = "";
		},
		checkAutoFillUpAmount() {
			console.log("Hej")
			if (parseInt(this.autofill_amount) < this.amount_min) {
				this.errorMsg = this.T('Amount low then') + this.amount_min;
				return;
			}
			
			if (parseInt(this.autofill_amount) > this.amount_max) {
				this.errorMsg = this.T('Amount high then') + this.amount_max;
				return;
			}

			this.errorMsg = "";
		}
	},
	computed: {
		formNotComplete: function () {
			return !this.formComplete();
		},
		max_amount: function() {
			if (isNaN(this.current_saldo)) {
				return this.amount_max;
			} else {
				return this.amount_max - this.current_saldo;
			}
		}
	},
}
</script>

<style>

</style>