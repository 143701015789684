import config from "./settings";
import { useMainStore } from '../stores/MainStore';

const settings = config.settings;

export class MyConn {
    private _settings: any;
    private _log: any;
    private _network_timeout: any;
    private _state: string;
    private _queue: any[];
    private _interval_id: number;
    private _index: number;
    private _latency: number;
    private _callback: any;
    private _beating: boolean;
    private _starttime: number;
    private mainStore: any;

    constructor(values) {
        this._settings = values;
        this._log = values.log;
        this._network_timeout = values.networkTimeout;
        this._state = "unknown";
        this._queue = [];
        this._interval_id = 0;
        this._index = 0;
        this._latency = 0;
        this.mainStore = useMainStore();
        this._callback = this.mainStore.onConnectionChange;
        this._index = 0;
        this._beating=false;
        this._starttime=0;
    }

    start() {
        this._log.log("starting connman");
        this.heartBeat();
        this._interval_id = window.setInterval(this.execute_beat.bind(this), 1000);
    }

    stop() {
        if (this._interval_id!=0) {
            this._log.log("stopping connman");
            window.clearInterval(this._interval_id)
        }
    }

    execute_beat() {
        //console.log(this._index,this._beating, this._starttime);
        this._index=this._index+1;
        if (this._index % this._settings.heartBeatInterval==0) {
            if (!this._beating) {
                this.heartBeat();
            }
        }
    }

    get(url, headers, data, on_success, on_failure, auth) {

        //console.log("GET", url);
    
        const params = typeof data == 'string' ? data : Object.keys(data).map(
            function(k){ return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) }
        ).join('&');
    
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function(){
            if ( xhr.readyState == 4 ) {
                if ( xhr.status == 200 ) {
                    on_success(JSON.parse(xhr.responseText));
                } else {
                    console.log("F3", xhr.status);
                    on_failure(xhr.status);
                }
            }
        };
    
        xhr.onerror = function () {
            console.log("F1");
            on_failure(xhr.status);
        };
        xhr.ontimeout = function () {
            console.log("F2");
            on_failure(xhr.status);
        };
    
        xhr.open('GET', url+"?"+params);
    
        if (auth != undefined) {
            console.log("GET using basic auth", auth["user"]);
            xhr.setRequestHeader("Authorization", "Basic " + btoa(auth["user"]+":"+auth["password"]));
        }
        
        xhr.timeout = this._network_timeout;
        //xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        for (const k in headers) {
            //console.log(k,headers[k]);
            xhr.setRequestHeader(k, headers[k]);
        }
        xhr.send(params);
    }

    post(url, headers, data, on_success, on_failure) {

        // console.log("POST", url);
        // console.log(headers);
        // console.log(data);
        
        const params = typeof data == 'string' ? data : Object.keys(data).map(
            function(k){ return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) }
        ).join('&');
    
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function(){
            if ( xhr.readyState == 4 ) {
                if ( xhr.status == 200 ) {
                    on_success(JSON.parse(xhr.responseText));
                } else {
                    console.log("F3", xhr.status);
                    on_failure(xhr.status);
                }
            }
        };
    
        xhr.onerror = function () {
            console.log("F1");
            on_failure(xhr.status);
        };

        xhr.ontimeout = function () {
            console.log("F2");
            on_failure(xhr.status);
        };
    
        xhr.open('POST', url, true);
        xhr.timeout = this._network_timeout;
        //xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        for (const k in headers) {
            //console.log(k,headers[k]);
            xhr.setRequestHeader(k,headers[k]);
        }
        xhr.send(params);
    }

    heartBeat() {
        this._beating = true;
        this._index += 1;
        this._starttime = this._index;
        //console.log("hb",this._index);
        const begin_time = Date.now();
        this.get(settings.ping_server, {}, {index:this._index},  (r) => {
            if (r["reload"]=="yes") {
                console.log("Reloading");
                localStorage._restore_page = this.mainStore.page;
                document.location.reload();
            }
            this.mainStore.pingTimeF(Date.now() - begin_time);
            if (this._state != "online") {
                this._state="online";
                this.mainStore.connChange("online");
                this._callback(this._state);
            }
            this._beating=false;
        },  
        (e) => {
            this._log.log("Heartbeat fail");
            this.mainStore.pingTimeF(Date.now() - begin_time);
            if (this._state!="offline") {
                this._state="offline";
                this.mainStore.connChange("offline");
                this._callback(this._state);
            }
            this._beating=false;
        }, undefined);
        return true;
    }

    isOnline() {
        return this._state=="online";
    }
}