<template>
<ion-menu side="start" menu-id="first" content-id="content">
    <ion-header>
      <ion-toolbar color="light">
      </ion-toolbar>
    </ion-header>
    <br>
    <cBracelet v-if="loggedIn" v-bind:amount="main.active_bracelet_amount" v-bind:amount2="main.active_bracelet_amount2" v-mobile:click="function() { clickMenuItem(10); }"></cBracelet>
    <ion-content>
        <ion-list v-if="loggedIn">
            <ion-item button @click="clickMenuItem(10)">
                <i class="bracelet-icon"></i>
                <ion-label>{{T('Braclet')}}</ion-label>
            </ion-item>

            <ion-item button @click="clickMenuItem(14)">
                <i class="fas fa-ticket-alt icon-margin"></i>
                <ion-label>{{T('Billetter')}}</ion-label>
            </ion-item>

            <ion-item button @click="clickMenuItem(13)">
                <i class="fas fa-history icon-margin"></i>
                <ion-label>{{T('Historik')}}</ion-label>
            </ion-item>

            <ion-item button @click="clickMenuItem(40)">
                <i class="fas fa-wallet icon-margin"></i>
                <ion-label>{{T('Rekvirer')}}</ion-label>
            </ion-item>

            <ion-item lines="none">
                <i class="fas fa-globe-europe icon-margin"></i>
                <cLanguage style="margin-bottom: 0;" v-on:change="changeLanguage($event)" v-bind:language="current_language"></cLanguage>
            </ion-item>    
        </ion-list>

        <ion-list v-else>
            <ion-item button @click="clickMenuItem(1)">
                <i class="fas fa-sign-in-alt icon-margin"></i>
                <ion-label>{{T('Login')}}</ion-label>
            </ion-item>

            <ion-item button @click="clickMenuItem(2)">
                <i class="fas fa-unlock-alt icon-margin"></i>
                <ion-label>{{T('Glemt password')}}</ion-label>
            </ion-item>

            <ion-item button @click="clickMenuItem(4)">
                <i class="fas fa-user icon-margin"></i>
                <ion-label>{{T('Opret bruger')}}</ion-label>
            </ion-item>
        </ion-list>
    </ion-content>
    <cUl class="bottom-menu">
        <cMenuItem v-show="loggedIn" v-mobile:click="function() { clickMenuItem(12); }" v-bind:title="T('Rediger profil')" icon="fas fa-cog"></cMenuItem>
        <cMenuItem v-mobile:click="function() { clickMenuItem(17); }" v-bind:title="T('FAQ')" icon="far fa-question-circle"></cMenuItem>
        <cMenuItem v-if="loggedIn" v-mobile:click="function() { doLogout(); }" v-bind:title="T('Log ud')" icon="fas fa-sign-out-alt"></cMenuItem>
        <cMenuItem v-else v-mobile:click="function() { clickMenuItem(1); }" v-bind:title="T('Login')" icon="fas fa-sign-in-alt"></cMenuItem>
    </cUl>
  </ion-menu>
</template>

<script>
import cBracelet from "./Bracelet.vue"
import cUl from "./Ul.vue"
import cMenuItem from "./MenuItem.vue"
import cLanguage from './Language.vue'
import { useMainStore } from '../stores/MainStore'
import { 
  IonContent, 
  IonHeader, 
  IonItem, 
  IonList, 
  IonMenu,
  IonToolbar,
  IonLabel,
  menuController
} from '@ionic/vue';
import { logOutOutline, logInOutline, helpCircleOutline, settingsOutline} from 'ionicons/icons';

export default {
    name: "cMenu",
	setup() {
		const main = useMainStore();

		return { main, logOutOutline, logInOutline, helpCircleOutline, settingsOutline };
	},
    created() {
        this.emitter.on('updateMenu', (value) => {
            this.current_language = value;
        })
    },
    components: {
        cBracelet,
        cUl,
        cMenuItem,
		cLanguage,
        IonContent, 
        IonHeader, 
        IonItem, 
        IonList, 
        IonMenu,
        IonToolbar,
        IonLabel,
    },
	methods: {
		doLogout: function() {
			this.main.setToken("");
			this.main.gotoPage(1);
            menuController.close('first')
		},
        openFirst() {
            menuController.enable(true, 'first');
            menuController.open('first');
        },
        clickMenuItem(pageId) {
            this.main.gotoPage(pageId);
            menuController.close('first')
        }
	},
	computed: {
		loggedIn: function () {
			return this.main.loggedIn;
		}
	},
}
</script>

<style>

</style>